// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.dropdown .dropdown-label {
  color: #363636;
  font-size: 15px;
  font-weight: 500;
  margin-right: 10px;
}
.dropdown .css-b62m3t-container {
  width: 100%;
}
.dropdown .css-b62m3t-container .css-1okebmr-indicatorSeparator {
  width: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/DropDown/DropDown.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,WAAA;EACA,mBAAA;EACA,8BAAA;AACF;AACE;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;AACJ;AAEE;EACE,WAAA;AAAJ;AAEI;EACE,QAAA;AAAN","sourcesContent":[".dropdown {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: nowrap;\n  width: 100%;\n  align-items: center;\n  justify-content: space-between;\n\n  .dropdown-label {\n    color: #363636;\n    font-size: 15px;\n    font-weight: 500;\n    margin-right: 10px;\n  }\n\n  .css-b62m3t-container {\n    width: 100%;\n\n    .css-1okebmr-indicatorSeparator {\n      width: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
