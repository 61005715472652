import React from 'react'
import { EditableDetail, EditableDetailProps } from 'components/EditableDetail';
import { Input } from 'components/Input';

type TextEditableDetailProps = Omit<EditableDetailProps<string>, 'renderInput'>;

export const TextEditableDetail = (props: TextEditableDetailProps) => {
  const {value, ...others} = props;

  return (
    <EditableDetail
      {...others}
      value={value}
      renderInput={({value, onChange})=> {
        return (
          <Input
            onChange={onChange}
            value={value}
          />
        )
      }}
    />
  )
}