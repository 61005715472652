import React, { ReactNode, useState, createContext, useContext } from 'react';
import { Menu } from 'components/Menu';
import './Layout.scss';

interface MemberLayoutProps {
  children?: ReactNode;
}

interface MemberLayoutTypes {
  open: boolean;
  setOpen: (thisOpen: boolean) => void;
}

const MemberLayoutContext = createContext<MemberLayoutTypes>({} as MemberLayoutTypes);

export const MemberLayout = (props: MemberLayoutProps) => {
  const { children } = props;
  const [open, setOpen] = useState<boolean>(false);

  return (
    <MemberLayoutContext.Provider
      value={{
        open,
        setOpen,
      }}
    >
      <div className='member-layout'>
        <div className={`sidebar ${open && 'sidebar-open'}`}>
          <Menu />
        </div>
        <div className={`content ${open && 'content-hide'}`}>
         {children}
        </div>
      </div>
    </MemberLayoutContext.Provider>
  );
};

export const useMemberLayoutContext = () => useContext(MemberLayoutContext);
