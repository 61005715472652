import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  from,
  useMutation,
  useQuery,
  DocumentNode,
  QueryHookOptions,
  QueryResult,
  MutationHookOptions,
  MutationTuple,
  useLazyQuery,
  LazyQueryResultTuple,
  OperationVariables,
} from '@apollo/client';
import { onError } from "@apollo/client/link/error";
import { setContext } from '@apollo/client/link/context';

import fetch from 'cross-fetch';
import jsCookie from 'js-cookie';
import { toast } from 'react-toastify';

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_APOLLO_CLIENT,
  fetch,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Path: ${path}`,
      )
      if (message.includes('Tanggal berlaku polis tidak dapat sebelum tanggal hari ini')) {
        toast.error(message);
      }
    });

  if (networkError) toast.error('something went wrong!');
});

const authLink = setContext((_, { headers }) => {
  const token = jsCookie.get('token');

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

export const client = new ApolloClient({
  link: from([errorLink, authLink.concat(httpLink)]),
  cache: new InMemoryCache(),
});

export interface ApiQueryResult<T, D> extends QueryResult<T, D> {
  isRefetching: boolean;
}

export function query<T, D extends OperationVariables = unknown>(gqlQuery: DocumentNode, variables?: D, options?: QueryHookOptions<T, D>): ApiQueryResult<T, D> {
  const result = useQuery<T, D>(gqlQuery, {
    variables,
    ...options,
  });

  return {
    ...result,
    isRefetching: result.networkStatus === 4,
  };
}

export function lazyQuery<T, D extends OperationVariables = unknown>(gqlQuery: DocumentNode, options?: QueryHookOptions<T, D>): LazyQueryResultTuple<T, D> {
  return useLazyQuery<T, D>(gqlQuery, {
    ...options,
  });
}

export function mutation<T, D>(gqlQuery: DocumentNode, options?: MutationHookOptions<T, D>): MutationTuple<T, D> {
  return useMutation<T, D>(gqlQuery, options);
}
