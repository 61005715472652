import React, { useState } from 'react';
import { Button } from '@lifepal/lifepal-design-system';
import { Modal } from 'components';
import { QAtype } from 'types/user';
import { AgentListModal } from './AgentListModal';
import { AgentConfirmModal } from './AgentConfirmModal';

interface AgentButtonProps {
  qaAgent?: QAtype;
}

export const AgentButton = (props: AgentButtonProps) => {
  const [openList, setOpenList] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const { qaAgent } = props;
  const qaAgentFullname = qaAgent
    ? `${qaAgent.firstName} ${qaAgent.lastName}`
    : '';

  const onClick = () => {
    setOpenList(true);
  };

  const onCloseModal = () => {
    setOpenList(false);
  };

  const onCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const onTakeOrder = () => {
    setOpenConfirm(true);
    setOpenList(false);
  }

  return (
    <>
      <Button
        onClick={onClick}
        className={`text-white px-8 py-2 pointer hover:opacity-90 ${qaAgentFullname ? 'bg-brand-primary-color' : 'bg-error'
          }`}
      >
        <div className="flex gap-2">
          <img src="/assets/icon/assign.svg" />
          <span>QA Agent : {!qaAgentFullname ? '-' : '' + qaAgentFullname}</span>
        </div>
      </Button>
      <AgentListModal
        onClose={onCloseModal}
        onTakeOrder={onTakeOrder}
        open={openList}
      />
      <AgentConfirmModal
        onClose={onCloseConfirm}
        open={openConfirm}
      />
    </>
  );
};
