import React from 'react';
import { DetailBox } from "containers/review/components/Detail/components/DetailBox";
import { DeclarationQuestionTypes, OrderTypes } from '../../types';
import { CopyDeclarationModal } from './CopyDeclarationModal';

interface OrdersProps {
  loading: boolean;
  details: OrderTypes;
}

export const Orders = (props: OrdersProps) => {
  const { loading, details, } = props;

  return (
    <DetailBox
      className='mb-7'
      title='Orders'
      subTitle={details?.humanId}
      loading={loading}
      isAutoExpand={details?.parentOrder?.humanId != null}
    >
      <p className='text-z-14'><b>Parent Order ID</b></p>
      <div className='text-z-14 text-w-400 text-dark-grey'>{details?.parentOrder?.humanId || '-'}</div>
      {
        details?.parentOrder?.humanId &&
        (
          <CopyDeclarationModal />
        )
      }
      <p className='text-z-14 mt-5'><b>No Polis Parent Order</b></p>
      <span className='text-z-14 text-w-400 text-dark-grey'>{details?.parentOrder?.policy?.policyNumber || '-'}</span>
      <p className='text-z-14 mt-5'><b>E-Polis Parent Order</b></p>
      <a target='_blank' rel='noreferrer' className={`text-z-14 text-w-400 ${details?.parentOrder?.policy?.policyFile ? 'text-brand-primary-color' : 'text-dark-grey'}`} href={details?.parentOrder?.policy?.policyFile}>{details?.parentOrder?.humanId || '-'}</a>
    </DetailBox>
  );
};
