// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-layout {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.member-layout {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  position: relative;
  overflow: auto;
}
.member-layout .sidebar {
  display: flex;
  flex-direction: column;
  width: 250px;
  color: white;
  position: relative;
  background: #1d66dd;
}
@media (max-width: 767px) {
  .member-layout .sidebar {
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: 50px;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  }
  .member-layout .sidebar.sidebar-open {
    position: absolute;
    height: 100%;
    overflow: hidden;
  }
}
.member-layout .content {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  flex: 1 1;
}
@media (max-width: 767px) {
  .member-layout .content.content-hide {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Layout/Layout.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,uBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;EACA,cAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;EACA,mBAAA;AACJ;AACI;EARF;IASI,mBAAA;IACA,eAAA;IACA,WAAA;IACA,YAAA;IACA,gBAAA;IACA,MAAA;IACA,OAAA;IACA,QAAA;IACA,aAAA;EAEJ;EAAI;IACE,kBAAA;IACA,YAAA;IACA,gBAAA;EAEN;AACF;AAEE;EACE,aAAA;EACA,sBAAA;EACA,YAAA;EACA,WAAA;EACA,SAAA;AAAJ;AAEI;EACE;IACE,aAAA;EAAN;AACF","sourcesContent":[".login-layout {\n  display: flex;\n  width: 100%;\n  height: 100%;\n  align-items: center;\n  justify-content: center;\n}\n\n.member-layout {\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n  height: 100%;\n  flex-wrap: wrap;\n  position: relative;\n  overflow: auto;\n\n  .sidebar {\n    display: flex;\n    flex-direction: column;\n    width: 250px;\n    color: white;\n    position: relative;\n    background: #1d66dd;\n\n    @media (max-width: 767px) {\n      flex-direction: row;\n      flex-wrap: wrap;\n      width: 100%;\n      height: 50px;\n      position: sticky;\n      top: 0;\n      left: 0;\n      right: 0;\n      z-index: 1000;\n\n      &.sidebar-open {\n        position: absolute;\n        height: 100%;\n        overflow: hidden;\n      }\n    }\n  }\n\n  .content {\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n    width: 100%;\n    flex: 1;\n\n    @media (max-width: 767px) {\n      &.content-hide {\n        display: none;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
