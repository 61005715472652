// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal {
  align-items: center;
  display: flex;
  justify-content: center;
  overflow: auto;
  position: fixed;
  z-index: 9999;
  background-color: rgba(10, 10, 10, 0.86);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.modal .modal-content {
  display: flex;
  flex-direction: column;
  width: 40%;
  max-height: 80%;
  position: relative;
  overflow: scroll;
  border-radius: 4px;
  background-color: #ffffff;
  z-index: 10000;
}
.modal .modal-content .modal-content-header {
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid #f5f5f5;
  font-weight: 700;
  font-size: 22px;
}
.modal .modal-content .modal-content-header.border-none {
  border: none;
}
.modal .modal-content .modal-content-header i {
  font-size: 22px;
  position: absolute;
  top: 0;
  right: 0;
}
.modal .modal-content.large-width {
  width: 75%;
}`, "",{"version":3,"sources":["webpack://./src/components/Modal/Modal.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,aAAA;EACA,uBAAA;EACA,cAAA;EACA,eAAA;EACA,aAAA;EACA,wCAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;EACA,UAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,kBAAA;EACA,yBAAA;EACA,cAAA;AACJ;AACI;EACE,aAAA;EACA,WAAA;EACA,kBAAA;EACA,mBAAA;EACA,uBAAA;EACA,gCAAA;EACA,gBAAA;EACA,eAAA;AACN;AACM;EACE,YAAA;AACR;AAEM;EACE,eAAA;EACA,kBAAA;EACA,MAAA;EACA,QAAA;AAAR;AAII;EACE,UAAA;AAFN","sourcesContent":[".modal {\n  align-items: center;\n  display: flex;\n  justify-content: center;\n  overflow: auto;\n  position: fixed;\n  z-index: 9999;  \n  background-color: rgba(10,10,10,.86);\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n\n  .modal-content {\n    display: flex;\n    flex-direction: column;\n    width: 40%;\n    max-height: 80%;\n    position: relative;\n    overflow: scroll;\n    border-radius: 4px;\n    background-color: #ffffff;\n    z-index: 10000;\n\n    .modal-content-header {\n      display: flex;\n      width: 100%;\n      position: relative;\n      align-items: center;\n      justify-content: center;\n      border-bottom: 2px solid #f5f5f5;\n      font-weight: 700;\n      font-size: 22px;\n\n      &.border-none {\n        border: none;\n      }\n\n      i {\n        font-size: 22px;\n        position: absolute;\n        top: 0;\n        right: 0;\n      }\n    }\n\n    &.large-width {\n      width: 75%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
