import React from 'react';
import { Button } from '@lifepal/lifepal-design-system';
import { ReviewActionType } from 'types/review';

interface DetailActionsProps {
  enableApprove: boolean;
  enableSuspend: boolean;
  setOpen: (value: ReviewActionType) => void;
}

const DetailActions = (props: DetailActionsProps) => {
  const {
    enableApprove,
    enableSuspend,
    setOpen
  } = props;

  return (
    <div className='detail-actions'>
      <Button
        className={`px-8 py-2 text-white ${enableSuspend ? 'bg-red-300 text-white' : 'bg-red-400 cursor-pointer'}`}
        onClick={() => setOpen('reject')}
        disabled={enableSuspend}
      >
        <i className='icon ion-ios-close mr-2.5' />
        Reject
      </Button>
      <Button
        className={`px-8 py-2 ${enableSuspend ? 'border border-red-400 text-red-400 cursor-pointer' : 'bg-gray-300 text-white'}`}
        onClick={() => setOpen('suspend')}
        disabled={!enableSuspend}
      >
        <i className='icon ion-ios-pause mr-2.5' />
        Suspend
      </Button>
      <Button
        onClick={() => setOpen('record')}
        className='pointer record px-8 py-2'
      >
        <i className='icon ion-ios-refresh mr-2.5' />
        Rekaman
      </Button>
      <Button
        disabled={!enableApprove}
        onClick={() => setOpen('approve')}
        className={`text-white px-8 py-2 ml-auto ${enableApprove ? 'bg-brand-primary-color pointer' : 'bg-blue-300'}`}
      >
        Approve
      </Button>
    </div>
  )
}

export default DetailActions;
