import React from 'react';
import { InlineIcon } from '@iconify/react';
import logoutIcon from '@iconify/icons-simple-line-icons/logout';
import menuIcon from '@iconify/icons-simple-line-icons/menu';
import { Navigation } from './Navigation';
import { useAuthContext } from 'providers/AuthProvider';
import { useMemberLayoutContext } from 'components/Layout/MemberLayout';
import { lifepalWhite, orderCreated, orderRefunded } from 'utils/assets';
import './Menu.scss';
import { getQuotationDataCount } from 'services/quotations';

export const Menu = () => {
  const { open, setOpen } = useMemberLayoutContext();
  const { logout, user } = useAuthContext();

  const { data } = getQuotationDataCount();

  const newBizPendingOrderCount = data?.getQaQuotesDataCount?.pendingQaCount || 0;
  const newBizReviseOrderCount = data?.getQaQuotesDataCount?.reviseCount || 0;
  const renewalPendingOrderCount = data?.getQaQuotesDataCount?.renewalPendingQaCount || 0;
  const renewalReviseOrderCount = data?.getQaQuotesDataCount?.renewalReviseCount || 0;

  const initialName = user && (user.full_name && user.full_name.split(' ').slice(0, 2).map((name) => {
    return name.split('')[0];
  })) || 'LP';

  return (
    <div className={`navigation-menu ${open && 'd-flex'}`}>
      <div className='burger-menu ml-2.5 pointer' onClick={() => { setOpen(!open); }}>
        <InlineIcon icon={menuIcon} />
      </div>
      <div className='header p-3'>
        <img src={lifepalWhite} alt='lifepal-white' />
      </div>
      <div className={`info p-3 ${open && 'd-flex'}`}>
        <div className='user'>
          <div className='nickname mr-2.5'>{initialName}</div>
          <div className='user-info'>
            <h5>Quality Assurance</h5>
            <span>{user && user.full_name || '-'}</span>
          </div>
        </div>
        <div onClick={() => { logout(); }} className='logout ml-12 mt-7 pointer'>
          <InlineIcon icon={logoutIcon} />
          <span className='ml-2'>Keluar</span>
        </div>
      </div>
      <Navigation menus={[
        {
          label: 'Order New Business',
          path: null,
          status: null,
          icon: <img src={orderCreated} className='w-[28px] h-[28px]' alt='' />,
          badges: null,
        },
        {
          label: 'Menunggu QA',
          path: '/review',
          status: 'PENDING_QA',
          icon: null,
          badges: newBizPendingOrderCount,
        },
        {
          label: 'Revisi',
          path: '/review',
          status: 'REVISE',
          icon: null,
          badges: newBizReviseOrderCount,
        },
        {
          label: 'Order Renewal',
          path: null,
          status: null,
          icon: <img src={orderRefunded} className='w-[28px] h-[28px]' alt='' />,
          badges: null,
        },
        {
          label: 'Menunggu QA',
          path: '/renewal/review',
          status: 'PENDING_QA',
          icon: null,
          badges: renewalPendingOrderCount,
        },
        {
          label: 'Revisi',
          path: '/renewal/review',
          status: 'REVISE',
          icon: null,
          badges: renewalReviseOrderCount,
        },
      ]} />
    </div>
  );
};
