import React, { FC } from 'react';
import { Button } from '@lifepal/lifepal-design-system';
import { Modal } from 'components';
import { assignQaAgentMutation } from 'services/quotations';
import { useReviewDetailContext } from 'containers/review/Detail';
import { toast } from 'react-toastify';

interface ListAgentModalProps {
  open: boolean;
  onClose: () => void;
}

export const AgentConfirmModal: FC<ListAgentModalProps> = ({ onClose, open,}) => {
  const [ assignQa ] = assignQaAgentMutation();
  const { quotationId, refetchData } = useReviewDetailContext();

  const onClick = async () => {
    try {
      await assignQa({
        variables: {
          quotationId,
        }
      });

      refetchData();
      onClose();
    } catch(err) {
      toast.error('Terjadi kesalahan');
    }
  }

  return (
    <Modal
      open={open}
      onCloseModal={onClose}
      className={{
        header: 'justify-center border-none p-none !text-base !mb-5',
        content: '!overflow-hidden p-4',
      }}
      title="QA agent untuk order ini akan diperbarui menjadi nama Anda. Lanjutkan?"
    >
      <div className='flex flex-row justify-end gap-4 border-t-2 pt-3 border-brand-primary-color border-opacity-10'>
        <Button
          onClick={onClose}
          className={`text-brand-primary-color px-2 font-bold pointer hover:opacity-90`}
        >
          <span className='border-b-[1px] border-brand-primary-color border-dashed py-1.5'>Batalkan</span>
        </Button>
        <Button
          onClick={onClick}
          className={`text-white px-10 py-2 font-bold pointer hover:opacity-90 bg-brand-primary-color !ml-0`}
        >
          <div className="flex gap-2">
            <img src="/assets/icon/assign.svg" />
            Ambil
          </div>
        </Button>
      </div>
    </Modal>
  )
}
