// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input {
  border: none;
  width: 100%;
  color: #363636;
  background: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
}
input:focus {
  outline: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Input/Input.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;EACA,cAAA;EACA,gBAAA;EACA,yBAAA;EACA,kBAAA;AACF;AACE;EACE,aAAA;AACJ","sourcesContent":["input {\n  border: none;\n  width: 100%;\n  color: #363636;\n  background: #fff;\n  border: 1px solid #dbdbdb;\n  border-radius: 4px;\n\n  &:focus {\n    outline: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
