// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-control {
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
}
.form-control input[type=radio] {
  appearance: none;
  background-color: white;
  margin: 0;
  width: 1em;
  height: 1em;
  border: 2px solid #f87171;
  border-radius: 50%;
  display: grid;
  place-content: center;
}
.form-control input[type=radio]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  background-color: #f87171;
}
.form-control input[type=radio]:checked::before {
  transform: scale(1);
}
.form-control input[type=radio]:focus {
  outline-offset: 2px;
}

.form-control:focus-within {
  color: #f87171;
}

.label {
  white-space: nowrap;
  text-align: left;
  color: inherit;
  cursor: pointer;
}

.revert > input[type=radio] {
  background-color: #f87171;
  border: 2px solid white;
}
.revert > input[type=radio]::before {
  background-color: white;
}

.disabled .label {
  color: #ccc;
  cursor: default;
}
.disabled input[type=radio] {
  border: 2px solid #ccc;
}`, "",{"version":3,"sources":["webpack://./src/components/RadioInput/RadioInput.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,aAAA;EACA,+BAAA;EACA,UAAA;AACF;AACE;EAEE,gBAAA;EACA,uBAAA;EACA,SAAA;EACA,UAAA;EACA,WAAA;EACA,yBAAA;EACA,kBAAA;EACA,aAAA;EACA,qBAAA;AACJ;AAEE;EACE,WAAA;EACA,aAAA;EACA,cAAA;EACA,kBAAA;EACA,mBAAA;EACA,uCAAA;EACA,mDAAA;EACA,yBAAA;AAAJ;AAGE;EACE,mBAAA;AADJ;AAIE;EACE,mBAAA;AAFJ;;AAMA;EACE,cAAA;AAHF;;AAMA;EACE,mBAAA;EACA,gBAAA;EACA,cAAA;EACA,eAAA;AAHF;;AAQE;EACE,yBAAA;EACA,uBAAA;AALJ;AAQE;EACE,uBAAA;AANJ;;AAWE;EACE,WAAA;EACA,eAAA;AARJ;AAWE;EACE,sBAAA;AATJ","sourcesContent":[".form-control {\n  line-height: 1.1;\n  display: grid;\n  grid-template-columns: 1em auto;\n  gap: 0.5em;\n  \n  input[type=\"radio\"] {\n    -webkit-appearance: none;\n    appearance: none;\n    background-color: white;\n    margin: 0;\n    width: 1em;\n    height: 1em;\n    border: 2px solid #f87171;\n    border-radius: 50%;\n    display: grid;\n    place-content: center;\n  }\n\n  input[type=\"radio\"]::before {\n    content: \"\";\n    width: 0.65em;\n    height: 0.65em;\n    border-radius: 50%;\n    transform: scale(0);\n    transition: 120ms transform ease-in-out;\n    box-shadow: inset 1em 1em var(--form-control-color);\n    background-color: #f87171;\n  }\n  \n  input[type=\"radio\"]:checked::before {\n    transform: scale(1);\n  }\n  \n  input[type=\"radio\"]:focus {\n    outline-offset: max(2px, 2px);\n  }\n}\n\n.form-control:focus-within {\n  color: #f87171;\n}\n\n.label {\n  white-space: nowrap;\n  text-align: left;\n  color: inherit;\n  cursor: pointer;\n}\n\n\n.revert{\n  > input[type=\"radio\"] {\n    background-color: #f87171;\n    border: 2px solid white;\n  }\n\n  > input[type=\"radio\"]::before {\n    background-color: white;\n  }\n}\n\n.disabled {\n  .label{\n    color: #ccc;\n    cursor: default;\n  }\n\n  input[type=\"radio\"] {\n    border: 2px solid #ccc;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
