import React, { useState } from 'react'
import { EditableDetail, EditableDetailProps } from 'components/EditableDetail';
import { Input } from 'components/Input';
import { DatePicker, DatePickerProps } from 'components/Input/DatePicker';

interface DateEditableDetailProps extends Omit<EditableDetailProps<Date>, 'renderInput'>, Pick<DatePickerProps, 'maxDate' | 'minDate'> {

}

export const DateEditableDetail = (props: DateEditableDetailProps) => {
  const {value, minDate, maxDate, ...others} = props;

  return (
    <EditableDetail
      {...others}
      value={value}
      renderInput={({value, onChange})=> {
        return (
          <DatePicker
            onChange={onChange}
            value={value}
            minDate={minDate}
            maxDate={maxDate}
          />
        )
      }}
    />
  )
}