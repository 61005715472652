// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.status, .canceled, .process, .unpaid, .suspend, .paid, .solved {
  border: none;
  border-radius: 17.5px;
  white-space: pre;
  align-items: center;
  justify-content: center;
  display: flex;
}

.paid, .solved {
  color: #FAAF40;
  background: #FCEFD9;
}

.unpaid, .suspend {
  color: #fff;
  background: #ea4b4b;
}

.process {
  color: #1D66DD;
  background: #D2E0F8;
}

.canceled {
  background: #b6b6b6;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/Status/Status.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,qBAAA;EACA,gBAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;AACF;;AAEA;EAEE,cAAA;EACA,mBAAA;AAAF;;AAGA;EAEE,WAAA;EACA,mBAAA;AADF;;AAQA;EAEE,cAAA;EACA,mBAAA;AANF;;AAaA;EAEE,mBAAA;EACA,WAAA;AAXF","sourcesContent":[".status {\n  border: none;\n  border-radius: 17.5px;\n  white-space: pre;\n  align-items: center;\n  justify-content: center;\n  display: flex;\n}\n\n.paid {\n  @extend .status;\n  color: #FAAF40;\n  background: #FCEFD9;\n}\n\n.unpaid {\n  @extend .status;\n  color: #fff;\n  background: #ea4b4b;\n}\n\n.solved {\n  @extend .paid;\n}\n\n.process {\n  @extend .status;\n  color: #1D66DD;\n  background: #D2E0F8;\n}\n\n.suspend {\n  @extend .unpaid;\n}\n\n.canceled {\n  @extend .status;\n  background: #b6b6b6;\n  color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
