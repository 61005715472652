import axios, { AxiosRequestHeaders } from 'axios';
import qs from 'query-string';
import jsCookie from 'js-cookie';
import { ParamsTypes, BodyTypes } from 'utils/apiTypes';

export const apiClient = (
  method: 'get' | 'post' | 'put' | 'delete' | 'patch',
  module: 'quotations' | 'quotations-token' | 'orders' | 'products' | 'leads' | 'none',
  url: string,
  body?: BodyTypes,
  params?: ParamsTypes,
  headers?: AxiosRequestHeaders,
) => {
  let baseUrl: string | undefined = '';
  const token = jsCookie.get('token');
  const defaultHeaders = {
    ...{ 'Content-Type': 'application/json' },
    ...(token && module !== 'none' ? { Authorization: `Bearer ${token}` } : {}),
  };

  switch (module) {
    case 'quotations':
      baseUrl = process.env.REACT_APP_QUOTATIONS_URL;
      break;
    case 'quotations-token':
      baseUrl = process.env.REACT_APP_QUOTATIONS_URL;
      headers = { ...defaultHeaders, ...{ Authorization: process.env.REACT_APP_QUOTATION_TOKEN } };
      break;
    case 'orders':
      baseUrl = process.env.REACT_APP_ORDERS_URL;
      break;
    case 'products':
      baseUrl = process.env.REACT_APP_PRODUCTS_URL;
      break;
    case 'leads':
      baseUrl = process.env.REACT_APP_LEADS_BASE_URL;
      break;
    default:
      break;
  }

  if (!headers) headers = defaultHeaders;
  else headers = { ...defaultHeaders, ...headers };

  switch (method) {
    case 'post':
      return axios.post(baseUrl + url, body, {
        headers, params,
        paramsSerializer: (params: ParamsTypes) => {
          return qs.stringify(params);
        },
     }).catch((error) => {
        throw error;
      });
    case 'delete':
      return axios({
        url: baseUrl + url,
        headers,
        method: 'DELETE',
        data: body,
      }).catch((error) => {
        throw error;
      });
    case 'put':
      return axios({
        url: baseUrl + url,
        headers,
        method: 'PUT',
        data: body,
      }).catch((error) => {
        throw error;
      });
    case 'patch':
      return axios({
        url: baseUrl + url,
        headers,
        method: 'PATCH',
        data: body,
      }).catch((error) => {
        throw error;
      });
    default:
      return axios.get(baseUrl + url, {
        headers, params,
        paramsSerializer: (params: ParamsTypes) => {
          return qs.stringify(params);
        },
      }).catch((error) => {
        throw error;
      });
  }
};
