import React from 'react';
import './CheckBox.scss';

interface CheckBoxProps {
  id?: string;
  checked?: boolean;
  label?: string;
  value: string;
  onChange: (thisCheck: string) => void;
}

export const CheckBox = (props: CheckBoxProps) => {
  const { id, checked, label, value, onChange } = props;

  return (
    <label className='Wrapper' htmlFor={id}>
      <input
        checked={checked || false}
        type="checkbox"
        id={id}
        onChange={(e) => { 
          onChange(value);
          return e.target.checked;
        }}
      />
      <span className='Label'>{label}</span>
    </label>
  );
};