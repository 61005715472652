import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { useAddressInputContext } from './AddressInput';
import type { Province } from '../../services/address';

interface ProvinceOption {
  label: string;
  value: Province
}

export const ProvinceInput = () => {
  const { data, defaultValues, changeProvince } = useAddressInputContext();
  const [options, setOptions] = useState<ProvinceOption[]>([]);
  const [value, setValue] = useState<ProvinceOption>();

  useEffect(()=>{
    const opts = data.provinces.map(reg => {
      return {
        value: reg,
        label: reg.name
      }
    });

    setOptions(opts);
    const defaultValue = opts.find(prov => prov.label === defaultValues.province);
    setValue(defaultValue);
  }, [data.provinces, defaultValues.province]);

  const onChange = (data: ProvinceOption) => {
    setValue(data);
    changeProvince(data.value);
  }

  return (
    <Select<ProvinceOption>
      placeholder='- Pilih Provinsi'
      options={options}
      value={value}
      onChange={onChange}
      isSearchable
    />
  )
}