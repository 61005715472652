import React, { useEffect, useState } from 'react';
import './Input.scss';

interface InputProps {
  placeholder?: string;
  onChange?: (thisValue: string) => void;
  value?: string | readonly string[] | undefined;
  maxLength?: number;
}

export const TextArea = (props: InputProps) => {
  const { placeholder, onChange, value, maxLength } = props;
  const [thisValue, setValue] = useState<string | number | readonly string[] | undefined>('');

  useEffect(() => {
    if (value) {
      setValue(value);
    } else {
      setValue('');
    }
  }, [value]);

  return (
    <div className='textarea-input'>
      <textarea
        className='block p-2.5 mb-2 w-full text-sm text-gray-900 rounded-lg border border-gray-300 resizeNone'
        value={thisValue}
        placeholder={placeholder}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => { onChange && onChange(e.target.value) }}
        maxLength={maxLength}
      />
      { value && value.length < 30 && (
        <p className='text-xs text-rose-700 text-right'>*Komentar minimal 30 karakter</p>
      )}
      { value && value.length >= 300 && (
        <p className='text-xs text-rose-700 text-right'>*Komentar maksimal 300 karakter</p>
      )}
    </div>
  );
};
