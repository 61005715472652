import { gql } from "@apollo/client";
import * as api from '../../utils/apolloClient';
import { CreatePolicyMembers } from "containers/review/types";
import { QaAgentAssignmentHistory, QaQuotesDataCount } from "./quotations.type";

interface DeclarationLinkType {
  hash: string;
}

interface SendDeclarationViaEmailResponseType {
  declarationLinks: DeclarationLinkType;
}

const UPDATE_DECLARATION_QUERY_MUTATION = gql`
  mutation UpdateQuotesDeclaration(
    $quotationId: String
    $declaration: String
  ) {
    updateQuotesDeclaration(input: {
      quotationId: $quotationId,
      declaration: $declaration,
    }) {
      declaration
    }
  }
`;

interface updateQuotesDeclarationProps {
  quotationId: string;
  /** stringify json of declarations */
  declaration: string;
}

export const updateQuotesDeclarationMutation = () => {
  return api.mutation<unknown, updateQuotesDeclarationProps>(UPDATE_DECLARATION_QUERY_MUTATION);
}

const GET_DECLARATION_LINK_MUTATION = gql`
  mutation GenerateDeclarationLink(
    $quotationId: String
  ) {
    generateDeclarationLink(input: {
      quotationId: $quotationId
    }) {
      declarationLinks {
        hash
      }
    }
  }
`;

export const getDeclarationLinkMutation = () => {
  return api.mutation<{ generateDeclarationLink: SendDeclarationViaEmailResponseType }, { quotationId: string }>(GET_DECLARATION_LINK_MUTATION);
}

const UPDATE_POLICY_MEMBERS = gql`
  mutation updatePolicyMembers(
    $quotationId: String!,
    $policyMembers: [CreatePolicyMemberInput!]!,
    $declarationType: DeclarationType!,
    $declaration: String,
    $buyerId: String,
    $category: String,
    $leadId: String,
    $planId: Int,
    $insuredItem: InsuredItemMutationInput,
    $productId: Int,
  ) {
    createQuotes(input: {
      quotationId: $quotationId,
      createPolicyMember: $policyMembers,
      declarationType: $declarationType,
      declaration: $declaration,
      category: $category,
      buyerId: $buyerId,
      planId: $planId,
      leadId: $leadId,
      insuredItem: $insuredItem,
      productId: $productId,
    }) {
      id
    }
  }
`;

interface UpdatePolicyMembersParams {
  quotationId: string;
  policyMembers: CreatePolicyMembers[];
  declarationType: string;
  declaration: string;
  category: string;
  buyerId: string;
  leadId: string;
  planId: number;
  insuredItem: {
    type: string;
    data: string;
  },
  productId: number;
}

export const updatePolicyMembersMutation = () => {
  return api.mutation<unknown, UpdatePolicyMembersParams>(UPDATE_POLICY_MEMBERS);
}

const ASSIGN_QA_AGENT = gql`
  mutation assignQaAgent(
    $quotationId: String!,
  ) {
    assignQaAgent(input: {
      quotationId: $quotationId,
    }) {
      ok
    }
  }
`;

export const assignQaAgentMutation = () => {
  return api.mutation<unknown, { quotationId: string }>(ASSIGN_QA_AGENT);
}

const GET_QA_AGENT_ASSIGNENT_HISTORY = gql`
      query getQuotationHistory(
        $quotationId: Uuid!,
      ) {
      getQuotationHistory(input: {
        quotationId: $quotationId 
        field: "Qa Agent"
      }) {
        history {
          id
          newValue
          createdAt
        }
      }
    }
`;

export const getQaAgentAssignmentHistoryQuery = (quotationId: string) => {
  return api.query<{ getQuotationHistory: QaAgentAssignmentHistory }, { quotationId: string }>(GET_QA_AGENT_ASSIGNENT_HISTORY, { quotationId }, {
    fetchPolicy: 'network-only',
  });
}

const GET_QUOTATION_DATA_COUNT = gql`
  query GetQaQuotesDataCount(
    $fromDate: String,
    $toDate: String,
  ){
    getQaQuotesDataCount(input:{
      fromDate: $fromDate,
      toDate: $toDate,
    }){
      pendingQaCount
      renewalPendingQaCount
      reviseCount
      renewalReviseCount
    }
  }
`;

interface GetQuotationDataCountParams {
  fromDate: string
  toDate: string
}

export const getQuotationDataCount = (data?: GetQuotationDataCountParams) => {
  return api.query<{ getQaQuotesDataCount: QaQuotesDataCount }, GetQuotationDataCountParams>(GET_QUOTATION_DATA_COUNT, data);
}