import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import './DropDown.scss';

interface OptionTypes {
  label: string;
  value: string;
}

interface DropDownProps {
  label?: string;
  options: OptionTypes[];
  value?: string | number | null | undefined | readonly string[] | boolean;
  onChange?: (thisOption: string | null) => void;
  id?: string;
  className?: string;
}

export const DropDown = (props: DropDownProps) => {
  const { options, value, onChange, label, id, className } = props;
  const defaultValue = options.length > 0 ? options[0].value : value;
  const [showValue, setValue] = useState<string | number | null | undefined | readonly string[] | boolean>(value || defaultValue);

  const handleChange = (option: OptionTypes | null) => {
    if (option) {
      setValue(option.value);
    }
    if (onChange) {
      onChange(option ? option.value : null);
    }
  };

  useEffect(() => {
    if (value) {
      setValue(value);
    }
  }, [value]);

  return (
    <div className='dropdown'>
      {label && <span className='dropdown-label'>{label}</span>}
      <Select<OptionTypes>
        id={id || 'dropdown-select'}
        value={options.find((opt) => opt.value === showValue)}
        getOptionLabel={(opt: OptionTypes) => opt.label}
        getOptionValue={(opt: OptionTypes) => opt.value}
        options={options}
        isSearchable
        backspaceRemovesValue
        onChange={handleChange}
        className={className || 'ml-4 z-10'}
      />
    </div>
  );
};
