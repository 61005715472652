import React, { FC } from 'react';
import { Button } from '@lifepal/lifepal-design-system';
import { Modal } from 'components';
import { AgentListModalContent } from './AgentListModalContent';

interface AgentListModalProps {
  open: boolean;
  onClose: () => void;
  onTakeOrder: ()=> void;
}

export const AgentListModal: FC<AgentListModalProps> = ({ onClose, open, onTakeOrder }) => {
  
  return (
    <Modal
      open={open}
      onCloseModal={onClose}
      className={{
        header: 'justify-center border-none p-none !text-base',
        content: '!overflow-hidden p-4',
      }}
      title="Quality Assurance Agent Assignment"
    >
      <div className={'py-5 w-full border-y border-brand-primary-color border-opacity-10'}><AgentListModalContent/></div>
      

      <div className='flex flex-row justify-end gap-4 pt-5'>
        <Button
          onClick={onClose}
          className={`text-brand-primary-color px-2 font-bold pointer hover:opacity-90`}
        >
          <span className='border-b-[1px] border-brand-primary-color border-dashed py-1.5'>Batalkan</span>
        </Button>
        
        <Button
          onClick={onTakeOrder}
          className={`text-white px-8 py-2 pointer hover:opacity-90 bg-brand-primary-color`}
        >
          <div className="flex gap-2">
            <img src="/assets/icon/assign.svg" />
            Ambil order ini
        </div>
        </Button>
      </div>
      
    </Modal>
  )
}
