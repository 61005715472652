import React from 'react';
import { Button } from '@lifepal/lifepal-design-system';
import { Modal } from 'components';
import { useReviewDetailContext } from 'containers/review/Detail';
import { format } from 'date-fns';

export const ModalApprove = () => {
  const { open, setOpen, updateQuotationStatus, isSubmitting, autoPolisInforcedDate } = useReviewDetailContext();

  const handleClose = () => {
    setOpen(undefined);
  };

  const handleApprove = async () => {
    updateQuotationStatus({ statusPayload: 'ACTIVE' });
  };

  return (
    <Modal
      open={open === 'approve'}
      onCloseModal={handleClose}
      title='Approve Order Ini?'
    >
      {autoPolisInforcedDate && (
        <span className='text-center mb-5'>
          Apakah Anda yakin akan <i>approve</i> order ini?<br /> Permintaan Tanggal Berlakunya Polis akan berubah menjadi hari ini ({format(new Date(), 'dd/MM/yyyy')})
        </span>
      )}
      <div className='modal-button-action'>
        <Button disabled={isSubmitting} onClick={handleClose} className={`bg-pointer border p-2 m-2 ${isSubmitting ? 'bg-slate-50' : 'pointer'}`}>
          Batal
        </Button>
        <Button disabled={isSubmitting} onClick={handleApprove} className={`text-white p-2 m-2 ${isSubmitting ? 'bg-blue-300' : 'bg-brand-primary-color pointer'}`}>
          Approve
        </Button>
      </div>
    </Modal>
  );
};
