/* Layout
/* consist of components that grouped to make a piece layout/ module
*/

import SuspendStatus from './SuspendStatus';
import DetailActions from './DetailActions';

export {
  SuspendStatus,
  DetailActions,
}
