import React from 'react';
import dayjs from 'dayjs';
import { useReviewDetailContext } from 'containers/review/Detail';
import { getQaAgentAssignmentHistoryQuery } from 'services/quotations';

export const AgentListModalContent= () => {
  
  const {quotationId} = useReviewDetailContext();
  const { data, loading } = getQaAgentAssignmentHistoryQuery(quotationId);
  if(loading || !data){
    return (
      <div>Loading...</div>
    )
  }
  return (
      <section className={'bg-blue-50 rounded-md'}>
        <header className="flex flex-row text-base bg-blue-800 text-white text-left px-3 py-2 font-bold rounded-t-md">
          <div className={'flex-[2_2_0%]'}>Nama Agen</div>
          <div className={'flex-1'}>Diambil pada</div>
        </header>
        {data.getQuotationHistory.history.map(a=>(
          <div className="flex flex-row px-3 py-2.5 " key={a.id}>
          <div className={'flex-[2_2_0%] text-base'}>{a.newValue}</div>
          <div className={'flex flex-row flex-1 text-base'}>
            <div className={'bg-emerald-100 py-1 px-2 rounded w-24 text-center'}>{dayjs(a.createdAt).format('DD/MM/YY')}</div>
            <div className={'bg-emerald-100 py-1 px-2 rounded w-20 ml-2 text-center'}>{dayjs(a.createdAt).format('HH:MM:ss')}</div>
          </div>
        </div>
        ))}
      </section>
  )
}