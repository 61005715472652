export enum OrderStatus {
  UNKNOWN = "UNKNOWN",
  PAYMENT_PENDING = "PAYMENT_PENDING",
  PAYMENT_PROCESSING = "PAYMENT_PROCESSING",
  FULLY_PAID = "FULLY_PAID",
  PARTIALLY_PAID = "PARTIALLY_PAID",
  REFUND_COMPLETE = "REFUND_COMPLETE",
  REFUND_PROCESSING = "REFUND_PROCESSING",
  CANCELED = "CANCELED",
  REFUND_PROCESSING_VIA_PG = "REFUND_PROCESSING_VIA_PG",
  REFUND_COMPLETE_VIA_PG = "REFUND_COMPLETE_VIA_PG",
}