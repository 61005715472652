import { DatePicker } from 'components';
import React, { useState } from 'react'

interface FutureDateMonthProps {
  value?: Date;
  onChange?: (date: Date) => void;
}

enum FutueDateMonthEnum {
  Auto = 'Auto',
  SettDate = 'SettDate',
}

const FutueDateMonthLabel: Record<FutueDateMonthEnum, string > = {
  Auto: 'Tanggal Otomatis',
  SettDate: 'Atur Tanggal'
}

export const FutureDateMonthSelect = (props: FutureDateMonthProps) => {
  const { onChange, value} = props;
  const date = new Date();
  const numberOfDaysToAdd = 60;
  date.setDate(date.getDate() + numberOfDaysToAdd);

  const [selected, setSelected] = useState<FutueDateMonthEnum>(value ? FutueDateMonthEnum.SettDate : FutueDateMonthEnum.Auto);
  return (
    <div>
      <div className='flex gap-4'>
        {
          Object.keys(FutueDateMonthEnum).map((key: FutueDateMonthEnum) => (
            <div 
              className='flex gap-2' 
              key={`declaration-date-radio-${key}`}
            >
              <input
                type="radio"
                id={`declaration-date-radio-${key}`}
                name='declaration-date'
                onChange={() => {
                  setSelected(key);
                  onChange(null);
                }}
                checked={key === selected}
              />
              <label 
                htmlFor={`declaration-date-radio-${key}`} 
                className="text-sm text-nowrap">{FutueDateMonthLabel[key]}
              </label>
            </div>
          ))
        }
      </div>
      <div className='mt-2'>
        {
          selected === FutueDateMonthEnum.SettDate && (
            <DatePicker
              value={value}
              onChange={onChange}
              minDate={new Date()}
              maxDate={date}
            />
          )
        }
      </div>
    </div>
  )
}
