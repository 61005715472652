import React from 'react';
import { idr } from 'utils/currency';
import { DetailBox } from './components/DetailBox';
import { DetailTypes, AdditionalBenefitsTypes } from '../../types';
interface SpecificationProps {
  loading: boolean;
  details: DetailTypes;
}

export const Specification = (props: SpecificationProps) => {
  const { details } = props;

  return (
    <DetailBox className='mb-7'>
      <div className='specification'>
        <div className='specification-logo p-horz-2-rem'>
          {details.productBrandLogo &&
            <img src={details.productBrandLogo} alt='brand logo' />
          }
        </div>
        <div>
          <p className='original'><b>{details.productName} - {details.planName || '-'}</b></p>
          <p className='text-z-14 text-w-400'>Premium</p>
          <p className='mb-5'>
            <b className='text-z-14'>{details.premium_monthly ? idr(details.premium_monthly) : 'Rp 0'} / bulan</b>
            <span className='text-z-14 ml-2 mr-2'>atau</span>
            <b className='text-z-14'>{details.premium ? idr(details.premium) : 'Rp 0'} / tahun</b>
          </p>
          {details.isAuto && (
            <ul className='mb-5'>
              <li className='text-z-14 text-w-400'>Harga Mobil {idr(details.sumInsured || 0)}</li>
              {details.benefits.length > 0 && details.benefits.map((item: AdditionalBenefitsTypes) => {
                const benefitName = item.name && item.name.toLowerCase();

                if (['tjhpk', 'diri'].some(word => benefitName.includes(word))) {
                  return (<li className='text-z-14 text-w-400' key={`${item.id}`}>{item.name}, {idr(item.value)}</li>);
                } else if (benefitName.includes('penumpang')) {
                  return (<li className='text-z-14 text-w-400' key={`${item.id}`}>{item.name}, {item.qty} Orang, {idr(item.value)}</li>);
                } else {
                  return (<li className='text-z-14 text-w-400' key={`${item.id}`}>{item.name}</li>);
                }
              })}
            </ul>
          )}
        </div>
      </div>
    </DetailBox>
  );
};
