import React, { useEffect, useState } from 'react';
import { MemberLayout, Table, Status, } from 'components';
import { differenceInDays, format } from 'date-fns';
import { useAuthContext } from 'providers/AuthProvider';
import 'containers/review/Review.scss';
import { ReviewHeader } from './ReviewHeader';
import { getQuotesList } from 'services/quotations/quotesList';
import { QuoteItem, QuotesListParams } from 'services/quotations/quotesList.types';
import { QaAgentFilter, QuotesStage, QuotesStatus } from 'constants/quotation.enum';
import { OrderStatus } from 'constants/order.enum';
import { ReviewContext } from './ReviewContext';
import { ReviewFilter } from './ReviewFilter';
import { getLocalItem, setLocalItem, upsertLocalItem } from 'utils/localStorage';

export const REVIEW_PARAM_NAME = 'ReviewParams';
const columns = [
  {
    Header: 'Order ID',
    accessor: 'humanId',
  },
  {
    Header: 'Tanggal Masuk QA',
    accessor: 'updatedAt',
  },
  {
    Header: 'Order Dibuat',
    accessor: 'diffDays',
    Cell: ({ value }: { value: string }) => {
      return `${value} hari lalu`;
    },
  },
  {
    Header: 'SPV',
    accessor: 'spvName',
    Cell: ({ value }: { value: string }) => {
      if (!value) {
        return '-';
      }

      return value;
    },
  },
  {
    Header: 'TSO',
    accessor: 'soldBy',
    Cell: ({ value }: { value: string }) => {
      if (!value) {
        return '-';
      }

      return value;
    },
  },
  {
    Header: 'Pembeli',
    accessor: 'buyerName',
    Cell: ({ value }: { value: string }) => {
      if (!value) {
        return '-';
      }

      return value;
    },
  },
  {
    Header: 'QA Agent',
    accessor: 'qaAgent',
    Cell: ({ value }: { value: string }) => {
      if (!value) {
        return '-';
      }

      return value;
    },
  },
  {
    Header: 'Status',
    accessor: 'quotationStatus',
    Cell: ({ value }: { value: 'paid' | 'unpaid' | 'solved' | 'suspend' | 'canceled' }) => {
      return <Status name={value} />
    },
  },
];

export const ReviewContainer = ({ isRenewal }: { isRenewal?: boolean }) => {
  const { getQueryParams, navigate } = useAuthContext();

  const status = getQueryParams('status') as QuotesStatus;
  const [params, setParams] = useState<QuotesListParams>({
    status,
    isRenewal: isRenewal || false,
    fromDate: getQueryParams('startDate'),
    toDate: getQueryParams('endDate'),
    stage: [QuotesStage.DECLARATION, QuotesStage.CREATE_ORDER],
    orderStatus: [OrderStatus.PARTIALLY_PAID, OrderStatus.FULLY_PAID],
    humanId: null,
    searchByBuyer: null,
    sort: 'created_at',
    sortBy: 'DESC',
    qaAgent: QaAgentFilter.ALL,
  });

  useEffect(() => {
    if (!status) {
      return;
    }

    changeParams({
      status,
    });
  }, [status]);

  useEffect(() => {
    changeParams({
      isRenewal: !!isRenewal,
    });
  }, [isRenewal]);

  const { data, loading: fetchLoading, error, refetch } = getQuotesList(params);

  const buildTableData = (orders: QuoteItem[]) => {
    return orders.map((od: QuoteItem, idx: number) => {
      const orderResults = Object.keys(od).map((name: string) => {
        if (name === 'status') {
          const orderStatus = orders[idx].orderStatus;
          let quotVal;

          switch (status) {
            case 'CANCEL':
              quotVal = 'canceled';
              break;
            case 'SUSPEND':
              quotVal = 'suspend';
              break;
            case 'REVISE':
              quotVal = 'suspend';
              break;
            default:
              if ((orderStatus === 'PAYMENT_PENDING') || (orderStatus === 'PAYMENT_PROCESSING')) {
                quotVal = 'process';
              } else {
                quotVal = 'paid';
              }
              break;
          }

          return {
            name: 'quotationStatus',
            value: quotVal,
          };
        }

        if (name === 'updatedAt') {
          return {
            name,
            value: format(new Date(orders[idx][name]), 'dd/MM/yy'),
          };
        }
        if (name === 'soldBy') {
          return {
            name,
            value: orders[idx][name]
              &&
              `${orders[idx][name].firstName} ${orders[idx][name].lastName}`,
          };
        }
        if (name === 'id') {
          return {
            name,
            value: orders[idx][name],
          }
        }
        if (name === 'humanId') {
          return {
            name,
            value: orders[idx][name],
          }
        }
        if (name === 'quotedAmount') {
          return {
            name,
            value: orders[idx][name],
          }
        }
        if (name === 'status') {
          return {
            name,
            value: orders[idx][name],
          }
        }
        if (name === 'spvName') {
          return {
            name,
            value: orders[idx][name],
          }
        }
        if (name === 'buyer') {
          return {
            name: 'buyerName',
            value: orders[idx].buyer && orders[idx].buyer.fullName
          };
        }
        if (name === 'qaAgent') {
          const qaAgent = orders[idx].qaAgent;

          return {
            name: 'qaAgent',
            value: qaAgent ? `${qaAgent.firstName} ${qaAgent.lastName}` : null,
          };
        }
        if (name === 'organization') {
          return {
            name: 'organizationName',
            value: orders[idx].organization && orders[idx].organization.name
          }
        }
        if (name === 'orderPolicyFile') {
          return {
            name,
            value: orders[idx][name],
          }
        }

        return {
          name,
          value: '',
        };
      });

      orderResults.push({
        name: 'quotationId',
        value: orders[idx].id,
      });
      orderResults.push({
        name: 'phoneNumber',
        value: orders[idx].buyer && orders[idx].buyer.phoneNumber,
      });
      orderResults.push({
        name: 'diffDays',
        value: `${differenceInDays(new Date(), new Date(orders[idx].orderCreatedAt))}`,
      });

      return orderResults;
    });
  }

  const changeParams = (newParams: Partial<QuotesListParams>) => {
    upsertLocalItem(REVIEW_PARAM_NAME, newParams, { ttl: 60 * 60 });

    setParams((prev) => {
      return {
        ...prev,
        ...newParams,
      }
    });

    if (newParams.fromDate || newParams.toDate || newParams.status) {
      navigate({
        pathname: isRenewal ? `/renewal/review` : `/review`,
        search: new URLSearchParams({
          startDate: newParams.fromDate || params.fromDate,
          endDate: newParams.toDate || params.toDate,
          status: newParams.status || params.status,
        }).toString(),
      }, {
        replace: true
      });
    }
  }

  const getDefaultParams = () => {
    if (params.status && params.fromDate && params.toDate) {
      return;
    }

    const defaultEndDate = new Date();
    const defaultStartDate = new Date(new Date().setDate(defaultEndDate.getDate() - 5));
    const data = getLocalItem<Partial<QuotesListParams>>(REVIEW_PARAM_NAME);

    changeParams({
      status: QuotesStatus.PENDING_QA,
      fromDate: format(defaultStartDate, "yyyy-MM-dd"),
      toDate: format(defaultEndDate, "yyyy-MM-dd"),
      ...data
    });
  }

  useEffect(() => {
    getDefaultParams();
  }, []);

  return (
    <ReviewContext.Provider
      value={{
        changeParams,
        params
      }}
    >
      <MemberLayout>
        <div className='dashboard-container'>
          <ReviewHeader />
          <div className='list-order p-vert-3-rem'>
            <h1 className='ml-8'>
              {isRenewal ? 'Renewal' : 'New Business'}&nbsp;-&nbsp;
              {status === 'PENDING_QA' ?
                'Menunggu QA'
                : status === 'REVISE' ?
                  'Revisi'
                  : '-'}
            </h1>
            <ReviewFilter />
            <Table
              id='quotationId'
              onClick={(id: string | number | undefined | null) => {
                if (id) {
                  navigate({
                    pathname: `/review/${id}`,
                  })
                }
              }}
              columns={columns}
              data={buildTableData(data?.quotes?.quotes || [])}
              loading={fetchLoading}
            />
          </div>
        </div>
      </MemberLayout>
    </ReviewContext.Provider>
  );
};
