import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { useAddressInputContext } from './AddressInput';
import type { District } from '../../services/address';

interface DisctrictOption {
  label: string;
  value: District
}

export const DisctrictInput = () => {
  const { data, defaultValues, changeDistrict } = useAddressInputContext();
  const [options, setOptions] = useState<DisctrictOption[]>([]);
  const [value, setValue] = useState<DisctrictOption>();

  useEffect(()=>{
    const opts = data.districts.map(reg => {
      return {
        value: reg,
        label: reg.name
      }
    });

    setOptions(opts);
    const defaultValue = opts.find(disctrict => disctrict.label === defaultValues.district);
    setValue(defaultValue);
  }, [data.districts, defaultValues.district]);

  const onChange = (data: DisctrictOption) => {
    setValue(data);
    changeDistrict(data.value);
  }

  return (
    <Select<DisctrictOption>
      placeholder='- Pilih Kecamatan'
      options={options}
      value={value}
      onChange={onChange}
      isSearchable
    />
  )
}