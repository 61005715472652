import { Button } from '@lifepal/lifepal-design-system';
import React, { useState } from 'react';
import { Modal } from 'components/Modal';
import { copyWhite } from 'utils/assets';
import { CopyableDeclarations } from './components/CopyableDeclaration';

export const CopyDeclarationModal = () => {

  const [open, setOpen] = useState(false);
  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  return (
    <>
      <Button
        onClick={openModal}
        className='text-white px-5 py-2 mt-3 bg-brand-primary-color rounded pointer hover:opacity-70 flex items-center gap-3'
      >
        <img className="w-fit h-fit" src={copyWhite} alt="Copy" /> Salin Deklarasi
      </Button>
      <Modal
        open={open}
        onCloseModal={closeModal}
        className={{ header: 'justify-start border-none p-none' }}
        title='Salin Deklarasi'
      >
        <CopyableDeclarations
          closeModal={closeModal}
        />
      </Modal>

    </>
  );
}
