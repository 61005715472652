import data from '@iconify/icons-simple-line-icons/logout';
import dayjs from 'dayjs';

interface LocalItemOption {
  ttl: number; // in second
}

interface StorageData<T> {
  ttl: number;
  data: T;
}

export const setLocalItem = <T>(name: string, data: T, options?: LocalItemOption) => {
  const storageData = {
    data,
    ttl: !options.ttl ? 0 : dayjs().add(options.ttl, 'seconds').unix(),
  }

  localStorage.setItem(name, JSON.stringify(storageData));
}

export const upsertLocalItem = <T>(name: string, data: T, options?: LocalItemOption) => {
  const localData = localStorage.getItem(name);

  if (!localData) {
    setLocalItem(name, data, options);
    return;
  }

  const storageData = JSON.parse(localData) as StorageData<T>;
  const newData = {
    ...storageData.data,
    ...data,
  }

  const newStorageData = {
    data: newData,
    ttl: !options.ttl ? 0 : dayjs().add(options.ttl, 'seconds').unix(),
  }

  localStorage.setItem(name, JSON.stringify(newStorageData));
}

export const getLocalItem = <T>(name: string, defaultData: Partial<T> = undefined) => {
  const data = localStorage.getItem(name);
  if (!data) {
    return defaultData;
  }

  const now = dayjs().unix();
  const storageData = JSON.parse(data) as StorageData<T>;
  if (!storageData.ttl || now <= storageData.ttl) {
    return storageData.data;
  }

  removeLocalItem(name);
  return defaultData;
}

export const removeLocalItem = (name: string) => {
  localStorage.removeItem(name);
}
