export const lifepal = '/assets/images/lifepal.png';
export const lifepalWhite = '/assets/images/lifepal-white.png';
export const pdfIcon = '/assets/icon/pdf.svg';
export const expand = '/assets/icon/expand.svg';
export const arrow = '/assets/icon/arrow.png';
export const comment = '/assets/icon/comment.png';
export const copyWhite = '/assets/icon/copy-white.svg';
export const orderCreated = '/assets/icon/order-created.png';
export const orderRefunded = '/assets/icon/order-refunded.png';
export const task = '/assets/images/task.svg';
export const trash = '/assets/icon/trash-red.png';
export const success = '/assets/images/success.svg';
