import { PersonTypes } from "types/person";

export const monthlyIncomeEnum: Record<string, string> = {
  'UNDER_5_MILLION_RUPIAH': 'Di Bawah 5 Juta Rupiah',
  '5_MILLION_20_MILLION_RUPIAH': '5 Juta - 20 Juta Rupiah',
  'ABOVE_20_MILLION_RUPIAH': 'Di Atas 20 Juta Rupiah',
};

export const sourceOfFundEnum: Record<string, string> = {
  'PEKERJAAN_TETAP': 'Pekerjaan Tetap',
  'PEKERJAAN_LEPAS': 'Pekerjaan Lepas',
  'WIRAUSAHA': 'Wirausaha',
};

export const maritalStatusEnum: Record<string, string> = {
  'SINGLE': 'Belum Menikah',
  'MARRIED': 'Menikah',
  'DIVORCED': 'Cerai Hidup',
  'WIDOW_WIDOWER': 'Cerai Mati',
};

export const genderEnum: Record<string, string> = {
  MALE: 'Pria',
  FEMALE: 'Wanita',
};

export const detailStatusEnum: Record<string, string> = {
  ACTIVE: 'aktif',
  SUSPEND: 'disuspend',
  CANCEL: 'ditolak/dibatalkan',
};

export enum Citizenships {
  WNI = "WNI",
  WNA = "WNA",
}

export enum IdentificationType {
  UNKNOWN_ID_CARD_TYPE = 'UNKNOWN_ID_CARD_TYPE',
  KTP = 'KTP',
  KK = 'KK',
  PASSPORT = 'PASSPORT',
  NPWP = 'NPWP',
}

export const IndentifyField: Partial<Record<IdentificationType, keyof PersonTypes>> = {
  KK: 'familyCardNumber',
  KTP: 'identityCardNumber',
  NPWP: 'taxId',
  PASSPORT: 'passportNumber',
}

export enum PolicyMembereType {
  BUYER = 'BUYER',
  BENEFICIARY = 'BENEFICIARY',
  HOLDER = 'HOLDER',
  INSURED = 'INSURED',
}

