import React, { useEffect, useState } from 'react'
import './RadioInput.scss'

interface FakeRadioInputProps {
  label: string;
  onChange?: () => void;
  revert?: boolean;
  checked?: boolean;
  name?: string;
  disabled?: boolean;
  className?: string;
}

export const RadioInput = (props: FakeRadioInputProps) => {
  const { label, disabled, name, onChange, className, revert, checked:defaultCheck } = props;
  const [checked, setChecked] = useState(defaultCheck);

  const handleChange = () => {
    if(disabled) {
      return;
    }

    onChange && onChange()
  }

  useEffect(()=>{
    setChecked(props.checked)
  },[props.checked])

  return (
    <label 
      className={`form-control ${disabled? 'disabled': ''} ${revert? 'revert': ''} ${className || ''}`}
      onClick={handleChange}
    >
      <input
        name={name}
        type="radio"
        onChange={handleChange}
        checked={checked}
        disabled={disabled}
      />
      <span className='label'>{label}</span>
  </label>
  )
}
