// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-action {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.edit-action span {
  font-size: 12px;
  font-weight: normal;
}
.edit-action span:last-child {
  margin-left: 10px;
}

.display-picture {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
}
.display-picture .input-upload {
  width: 100%;
  height: 100%;
  border: 1px dashed #dfdfdf;
  border-radius: 4px;
  display: flex;
}
.display-picture .input-upload input {
  display: none;
}
.display-picture .input-upload label {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #dfdfdf;
  font-size: 14px;
  color: #1d66dd;
  cursor: pointer;
}
.display-picture .remove-picture {
  display: flex;
  position: absolute;
  cursor: pointer;
  z-index: 3;
  background: rgb(51, 51, 51);
  opacity: 0.5;
  border-radius: 20px;
  width: 30%;
  align-items: center;
  justify-content: center;
  color: #535353;
}
.display-picture img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/containers/review/components/Detail/Detail.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;AACF;AACE;EACE,eAAA;EACA,mBAAA;AACJ;AACI;EACE,iBAAA;AACN;;AAIA;EACE,aAAA;EACA,kBAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;AADF;AAGE;EACE,WAAA;EACA,YAAA;EACA,0BAAA;EACA,kBAAA;EACA,aAAA;AADJ;AAGI;EACE,aAAA;AADN;AAII;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,cAAA;EACA,eAAA;EACA,cAAA;EACA,eAAA;AAFN;AAME;EACE,aAAA;EACA,kBAAA;EACA,eAAA;EACA,UAAA;EACA,2BAAA;EACA,YAAA;EACA,mBAAA;EACA,UAAA;EACA,mBAAA;EACA,uBAAA;EACA,cAAA;AAJJ;AAOE;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;AALJ","sourcesContent":[".edit-action {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n\n  span {\n    font-size: 12px;\n    font-weight: normal;\n\n    &:last-child {\n      margin-left: 10px;\n    }\n  }\n}\n\n.display-picture {\n  display: flex;\n  position: relative;\n  align-items: center;\n  justify-content: center;\n  width: 80px;\n  height: 80px;\n\n  .input-upload {\n    width: 100%;\n    height: 100%;\n    border: 1px dashed #dfdfdf;\n    border-radius: 4px;\n    display: flex;\n\n    input {\n      display: none;\n    }\n\n    label {\n      width: 100%;\n      height: 100%;\n      display: flex;\n      flex-direction: column;\n      justify-content: center;\n      align-items: center;\n      color: #dfdfdf;\n      font-size: 14px;\n      color: #1d66dd;\n      cursor: pointer;\n    }\n  }\n\n  .remove-picture {\n    display: flex;\n    position: absolute;\n    cursor: pointer;\n    z-index: 3;\n    background: rgb(51, 51, 51);\n    opacity: 0.5;\n    border-radius: 20px;\n    width: 30%;\n    align-items: center;\n    justify-content: center;\n    color: #535353;\n  }\n\n  img {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
