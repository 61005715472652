import React, { useEffect } from 'react'
import { useAuthContext } from 'providers/AuthProvider';

export const ReviewHeader = () => {
  const { user } = useAuthContext();

  const buildGreetingText = () => {
    const date = new Date();
    const time = date.getHours();
    let text = 'Selamat ';

    if (time >= 3 && time <= 10) {
      text += 'Pagi';
    } else if (time >= 11 && time <= 14) {
      text += 'Siang';
    } else if (time >= 15 && time <= 17) {
      text += 'Sore';
    } else {
      text += 'Malam';
    }

    return text;
  }

  return (
    <div className='dashboard-header p-1-half-rem mb-6'>
      <div className='greetings mr-12'>
        <h2>{buildGreetingText()},</h2>
        <h1>{user && user.full_name || '-'}</h1>
      </div>
    </div>
  )
}
