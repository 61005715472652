import React, { ChangeEvent, createRef, useState } from 'react';
import { apiClient } from 'utils/apiClient';
import { DetailTypes } from 'containers/review/types';
import { useReviewDetailContext } from 'containers/review/Detail';

type ValuesType = string | number | readonly string[] | undefined | null | boolean;

interface UploadInputProps {
  quotationValue: string;
  fieldName: string;
  inputType: 'image' | 'file';
  openedItem: string;
  onRemoveFile: (fieldName: string) => void;
  onSaveImage: (imageUrl: string) => void;
}

export const UploadInput = (props: UploadInputProps) => {
  const { 
    quotationValue, 
    fieldName, 
    inputType, 
    openedItem, 
    onRemoveFile, 
    onSaveImage, 
  } = props;
  const fileRef: React.RefObject<HTMLInputElement> = createRef();
  const accpetFileType = inputType === 'image' ? '.jpg, .jpeg, .png' : '.pdf';
  
  const getS3Url = async (fileName: string) => {
    return await apiClient('get', 'quotations', `/v1/aws/generate/presigned/url?fileName=${fileName}`)
  }

  const uploadFile = async (uploadUrl: string, type: string, file: File) => {
    await apiClient('put', 'none', uploadUrl, file, undefined, {
      'Content-Type': type,
    })
  }

  const onChangeFile = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files;

    if (file) {
      await getS3Url(file[0].name).then(async (res) => {
        const { data } = res;
        if (data) {
          await uploadFile(data.urls, file[0].type, file[0]);
          const imagePath = data.urls.split('?');

          if (imagePath && imagePath.length > 0) {
            onSaveImage(imagePath);
          }
        }
      }
    )}
  }

  return (
    <div className='flex flex-row w-20 h-20 my-2'>
      {quotationValue ?
        inputType === 'image' ?
          <img alt='display' src={quotationValue} />
          :
          <a className='text-sm underline text-sky-600' href={quotationValue} >Buka Dokumen</a>
        :
        <span className='text-sm p-5 border border-slate-200'>Tidak diisi.</span>
      }
      {(openedItem === fieldName) && (
        <div className='flex flex-row mt-4 ml-5'>
          <button className='text-sm text-rose-600 hover:underline' onClick={() => fileRef?.current?.click()} >Upload</button>
            <input
              className="hidden" type="file" 
              accept={accpetFileType} 
              ref={fileRef}
              onChange={async (event) => {
                if (!event.target.files || (event.target.files && event.target.files.length <= 0)) {
                  return;
                }

                onChangeFile(event);
              }}
            />
            {quotationValue && (
              <button className='ml-3 text-sm text-gray-500 hover:underline w-20' onClick={() => onRemoveFile(fieldName)}>Hapus Dokumen</button>
            )}
        </div>
      )}
    </div>
  );
}
