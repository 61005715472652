import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react'
import { EditableDetailAction } from './EditableDetailAction';

// TODO add yup validation field that trigger on change or submit

interface renderInputProps<T> {
  value: T,
  onChange: (value: T) => void;
}

interface SubmitData<T> {
  value: T 
}

export interface EditableDetailProps<T>{
  label: string | ReactNode;
  value: T;
  submitOnlyChange?: boolean;
  renderValue?: (d: T) => ReactNode;
  editable?: boolean;
  renderInput: (data: renderInputProps<T>) => ReactNode;
  classNames?: {
    action?: string;
  };
  onCancel?: () => void;
  onSubmit: (data: SubmitData<T>) => void;
}

interface EditableDetailContextProps<T = unknown> extends EditableDetailProps<T> {
  isEdit: boolean;
  onSubmit: () => void;
  enableEdit: () => void;
  disableEdit: () => void;
}

const EditableDetailContext = createContext<EditableDetailContextProps>({} as EditableDetailContextProps);
export const useEditableDetailContext = () => useContext(EditableDetailContext);

export const EditableDetail = <T,>(props: EditableDetailProps<T>) => {
  const { label, value, renderValue, renderInput, editable, onSubmit, onCancel, submitOnlyChange} = props;
  const [changedValue, setChangedValue] = useState<T>(value);
  const [isEdit, setIsEdit] = useState(false);
  const enableEdit = () => {
    setIsEdit(true);
  }

  useEffect(() => {
    setChangedValue(value);
  }, [value]);

  const onChange = (val: T) => {
    setChangedValue(val);
  }

  const disableEdit = () => {
    setIsEdit(false);
  }

  const render = () => {
    const val = renderValue ? renderValue(changedValue) : changedValue as ReactNode;
    return val || '-';
  }

  const submit = () => {
    if (value === changedValue && submitOnlyChange) {
      return;
    }

    onSubmit({
      value: changedValue,
    });
  }

  const cancel = () => {
    if (value !== changedValue) {
      setChangedValue(value);
    }

    onCancel && onCancel();
  }

  return (
    <EditableDetailContext.Provider
      value={{
        ...props,
        isEdit,
        enableEdit,
        disableEdit,
        onSubmit: submit,
        onCancel: cancel,
      }}
    >
      <div className='flex flex-col'>
        <div className='text-z-14 mt-5 inline-flex items-center gap-2'>
          <div className='font-bold'>{label}</div>
          {
            editable && (
              <EditableDetailAction/>
            )
          }
        </div>
        { !isEdit && (
          <div className='text-z-14 text-w-400 text-dark-grey'>{render()}</div>
        )}
        { isEdit && (
          <div>
            {renderInput({value: changedValue, onChange})}
          </div>
        )}
      </div>
    </EditableDetailContext.Provider>
  )
}

EditableDetail.defaultProps = {
  editable: true,
  submitOnlyChange: true,
}