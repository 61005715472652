import React from 'react';
import { Modal } from 'components';
import { Button } from '@lifepal/lifepal-design-system';
import { task } from 'utils/assets';
import { useReviewDetailContext } from 'containers/review/Detail';

const STATUS_LABEL: Record<string, string> = {
  'ACTIVE': 'Aktif',
  'CANCEL': 'Dibatalkan',
  'SUSPEND': 'Disuspend',
  'PENDING_QA': 'Pending QA',
};

export const ModalValidation = () => {
  const { open, isSubmitting, redirectToHome, status, modifiedBy } = useReviewDetailContext();

  return (
    <Modal
      open={open === 'validation'}
      onCloseModal={redirectToHome}
      onClose={false}
      className={{ header: 'border-none p-none', content: 'laptop:!w-1/3 !w-2/3 p-2-rem' }}
    >
      <div className='flex flex-col w-full gap-5 laptop:gap-10'>
        <img alt='task-img' src={task} className='object-cover w-full' />
        <div className='flex flex-col w-full items-center justify-center gap-2 text-center'>
          <h1 className='font-bold text-xl'>Tidak dapat mengupdate order ini</h1>
          <p className='text-sm'>Order ini telah diupdate{modifiedBy && ` oleh ${modifiedBy}`} ke {STATUS_LABEL[status]}</p>
        </div>
        <Button disabled={isSubmitting} onClick={redirectToHome} className={`text-white font-extrabold p-2 m-2 ${isSubmitting ? 'bg-blue-300' : 'bg-brand-primary-color pointer'}`}>
          OK
        </Button>
      </div>
    </Modal>
  );
};
