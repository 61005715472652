import React from 'react';
import { BrowserRouter, Route, Routes, useRoutes } from 'react-router-dom';
import { AuthProvider, ProtectRoute } from 'providers/AuthProvider';
import { LoginPage } from 'pages/login';
import { ReviewPage } from 'pages/review';
import { ReviewDetailPage } from 'pages/review/Detail';
import 'styles/styles.scss';

const App = () => {
  return (
    <>
      <BrowserRouter>
        <AuthProvider>
          <ProtectRoute>
            <Routes>
              <Route caseSensitive={false} path='/' element={<ReviewPage />} />
              <Route caseSensitive={false} path='/login' element={<LoginPage />} />
              <Route caseSensitive={false} path='/review' element={<ReviewPage />} />
              <Route caseSensitive={false} path='/review/:quotationId' element={<ReviewDetailPage />} />
              <Route caseSensitive={false} path='/renewal/review' element={<ReviewPage isRenewal />} />
            </Routes>
          </ProtectRoute>
        </AuthProvider>
      </BrowserRouter>
    </>
  );
};

export default App;
