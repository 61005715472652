import React, { useEffect, useState } from 'react';
import Select from 'react-select'
import { useAddressInputContext } from './AddressInput';
import type { Village } from '../../services/address';
  
interface SubDisctrictOption {
  label: string;
  value: Village;
}

export const SubDisctrictInput = () => {
  const { data, defaultValues, changeSubDistrict } = useAddressInputContext();
  const [options, setOptions] = useState<SubDisctrictOption[]>([]);
  const [value, setValue] = useState<SubDisctrictOption>();

  useEffect(()=>{
    const opts = data.subDistricts.map(reg => {
      return {
        value: reg,
        label: reg.name
      }
    });

    setOptions(opts);
    const defaultValue = opts.find(subDisctrict => subDisctrict.label === defaultValues.subDistrict);
    setValue(defaultValue);
  }, [data.subDistricts, defaultValues.subDistrict]);

  const onChange = (data: SubDisctrictOption) => {
    setValue(data);
    changeSubDistrict(data.value);
  }

  return (
    <Select<SubDisctrictOption>
      placeholder='- Pilih Kelurahan'
      options={options}
      value={value}
      onChange={onChange}
      isSearchable
    />
  )
}