import React from 'react';
import { useTable } from 'react-table';
import './Table.scss';

interface TableColumns {
  Header: string;
  accessor: string;
  className?: string;
}

export interface TableData {
  name: string;
  value?: string | number | undefined;
}

interface TableProps {
  columns: TableColumns[];
  data: TableData[][];
  disabledHover?: boolean;
  onClick?: (thisRow: string | number | undefined | null) => void;
  id?: string;
  loading?: boolean;
  emptyMessage?: string;
}

export const Table = ({ emptyMessage, columns, data: thisData, disabledHover, onClick, id, loading }: TableProps) => {
  const data = thisData.map((datas: TableData[]) => {
    const combinedData = datas.map((dataCom: TableData) => {
      return { ...{ [dataCom.name]: dataCom.value } };
    });
    return combinedData.reduce((result, current) => {
      return Object.assign(result, current);
    }, {});
  });

  const colClassNames = columns.reduce((res, col) => {
    res[col.Header] = col.className;
    return res;
  }, {} as Record<string, string>);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

  return (
    <table className='table-pos' {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => {
          const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();
          return (
            <tr key={key} {...restHeaderGroupProps}>
              {headerGroup.headers.map(column => {
                const { key: hKey, ...restHeaderProps } = column.getHeaderProps();
                return (
                  <th className='p-1-half-rem' key={hKey} {...restHeaderProps}>{column.render('Header')}</th>
                );
              })}
            </tr>
          );
        })}
      </thead>
      <tbody {...getTableBodyProps()}>
        {loading ?
          <tr>
            {Array.from(Array(8).keys()).map((row, i) =>
              <td key={i} className='p-8 loading-shimmer'></td>
            )}
          </tr>
          : 
          rows.length ? 
            rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  className={`
                  border-cell-bottom pointer ${disabledHover && (i % 2 == 0) && 'background-hover'}
                `}
                  key={row.getRowProps().key}
                  onClick={() => { if (id && onClick) { onClick(row.original[id]); } }}
                >
                  {row.cells.map(cell => {
                    const className = colClassNames[cell?.column?.Header.toString()]
                    const { key: cKey, ...restCellProps } = cell.getCellProps();
                    return <td className={`p-8 text-center ${className || ''}`} key={cKey} {...restCellProps}>{cell.render('Cell')}</td>
                  })}
                </tr>
              )
            })
          : (
            <tr>
              <td 
                colSpan={columns.length}
                className='text-center pt-4 font-semibold'
              >
                {emptyMessage || 'Tidak ada data'}
              </td>
            </tr>
          )
        }

      </tbody>
    </table>
  );
};
