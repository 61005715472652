import React, { useRef } from 'react';

interface CheckBoxProps {
  checked?: boolean;
  label?: string;
  onChange: (open: boolean) => void;
}

export const CheckBox = (props: CheckBoxProps) => {
  const { checked, label, onChange } = props;
  const inputs = useRef(null);

  if (!label) {
    return (
      <div className='checkbox'>
        <input ref={inputs} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          onChange(!checked)
        }} checked={checked} type='checkbox' />
        <label onClick={() => {
          if (inputs?.current?.click) {
            inputs.current.click();
          }
        }} className='checkbox-border' />
      </div>
    );
  }

  return (
    <button type='button' onClick={() => onChange(!checked)} className={`flex items-center gap-2 rounded-lg border-red-400 border px-3 py-1 font-bold text-red-400 text-sm ${checked && 'bg-red-400'}`}>
      <div className='checkbox'>
        <input 
          ref={inputs} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          e.preventDefault();
          e.stopPropagation();
          onChange(!checked)
        }} checked={checked} type='checkbox' />
        <label onClick={() => {
          if (inputs?.current?.click) {
            inputs.current.click();
          }
        }} className='checkbox-border' />
      </div>
      <span className={checked ? 'text-white' : ''}>{label}</span>
    </button>
  );
};
