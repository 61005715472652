import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { useJwt, decodeToken } from 'react-jwt';
import jsCookie from 'js-cookie';
import { useNavigate, useLocation, useSearchParams, NavigateOptions, To } from 'react-router-dom';
import { UserTypes } from 'interfaces/user';

interface AuthTypes {
  token: string;
  login: (thisToken: string) => void;
  isLoading: boolean;
  navigate: (to: To, options?: NavigateOptions | undefined) => void;
  logout: () => void;
  user: UserTypes;
  setUser: (thisUser: UserTypes) => void;
  pathName: string;
  query: string;
  getParams: (thisQuery: string) => string | number | null;
  getQueryParams: (thisQuery: string) => string | null;
}

const AuthContext = createContext<AuthTypes>({} as AuthTypes);

const AuthProvider = ({ children }: { children?: ReactNode }) => {
  const { pathname, search } = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [token, setToken] = useState<string>('');
  const [user, setUser] = useState<UserTypes>({} as UserTypes);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const logout = () => {
    jsCookie.remove('token');
    jsCookie.remove('filterDateRange');
    setToken('');
    setUser(null);
    navigate('/login');
  };

  const getQueryParams = (query: string) => {
    return searchParams.get(query);
  };

  const loginUser = async (token: string) => {
    setToken(token);
    const decodedToken = decodeToken(token);
    setUser(decodedToken as UserTypes);
  }

  useEffect(() => {
    const thisToken = jsCookie.get('token');
    if (thisToken) {
      loginUser(thisToken);
    } else {
      navigate('/login');
    }
    setIsLoading(false);
  },[]);

  return (
    <AuthContext.Provider
      value={{
        token,
        login: loginUser,
        isLoading,
        navigate,
        logout,
        user,
        setUser,
        pathName: pathname,
        query: search,
        getQueryParams,
        getParams: getQueryParams, // TODO: alias and remove later
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuthContext = () => useContext(AuthContext);

export const ProtectRoute = ({ children }: { children?: ReactNode }) => {
  const { isLoading } = useAuthContext();
  if (isLoading) return <div>Loading...</div>;
  return <>{children}</>;
};

export { AuthProvider, useAuthContext, AuthContext };
