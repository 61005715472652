import React from 'react';
import './Status.scss';

interface StatusProps {
  name: 'paid' | 'unpaid' | 'solved' | 'suspend' | 'canceled';
}

const STATUS = {
  paid: 'Dibayar',
  unpaid: 'Menunggu Pembayaran',
  solved: 'Diselesaikan',
  suspend: 'Disuspend',
  canceled: 'Dibatalkan',
  process: 'Diproses',
};

export const Status = (props: StatusProps) => {
  const { name } = props;

  return (
    <div className={`${name} p-vert-1 p-horz-1-rem`}>{STATUS[name]}</div>
  );
};
