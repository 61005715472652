import React, { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Button } from '@lifepal/lifepal-design-system';
import { Modal, Selected, Table } from 'components';
import { useReviewDetailContext } from '../Detail';
import { TableData } from 'components/Table';
import { SelectRadio, SelectRadioGroup } from 'components/SelectRadio';
import { CellProps, UseTableRowProps } from 'react-table';
import { CivImage } from 'types/quotation';
import { updateQuotesDeclarationMutation } from 'services/quotations';
import { toast } from 'react-toastify';
import { SelectItem } from 'components/SelectRadio/types';

export const ModalSightCallAssignment = () => {
  const { open, setOpen, quotation, declarationQuestions, quotationId, refetchData } = useReviewDetailContext();
  const [handleUpdateDeclarationMutation] = updateQuotesDeclarationMutation();
  const [loading, setLoading] = useState(false);
  const [changedDeclaration, setChangedDeclaration] = useState<Record<string, string>>({});

  const { civImages } = quotation;
  const imageDeclarations = declarationQuestions.filter(q => !q.is_required && q.field_type === 'image');

  /** image as key */ 
  const imagesUrl = civImages.map(img => img.url);

  useEffect(()=>{
    const selectedData = {};
    const currentDeclaration: Record<string, string> = JSON.parse(quotation.declaration);

    imageDeclarations.forEach( decl => {
      const img = currentDeclaration[decl.field_name];
      // unused image not available from sightcall
      if(!img || !imagesUrl.includes(img)) {
        return;
      }
  
      selectedData[img] = decl.field_name;
    });

    setChangedDeclaration(prev => {
      return {
        ...prev,
        ...selectedData,
      }
    })
  }, [quotation.declaration])

  // this will rerender selectradio, useCallback wont work
  const changeDeclaration = (declaration: string, image: string)=> {
    setChangedDeclaration((prev) => {
      if(!declaration) {
        const previous = {...prev};
        delete previous[image];
        return previous;
      }

      return {
        ...prev,
        [image]: declaration,
      }
    })
  };

  const onSubmit = async () => {
    setLoading(true);
    const declarations = Object.keys(changedDeclaration).reduce((res, img)=>{
      const decField = changedDeclaration[img];
      res[decField] = img;
      return res;
    }, {})

    try{
      const {errors} = await handleUpdateDeclarationMutation({
        variables: {
          quotationId,
          declaration: JSON.stringify(declarations),
        }
      });
  
      if(errors) {
        throw errors;
      }
    } catch(err) {
      console.error('[Error] updateQuotesDeclaration : ', err);
      return;
    } finally {
      setLoading(false);
    }

    refetchData();
    toast.success(`Berhasil mengalokasikan ${Object.values(changedDeclaration).length} foto`);
    setOpen(undefined);
  }
  
  const images = civImages?.map( image => {
    return Object.keys(image).map(key => {
      return {
        name: key,
        value: image[key]
      } as TableData;
    })
  });

  const allocated = Object.values(changedDeclaration).length;
  const declarations = imageDeclarations.map(q => {
    return {
      value: q.field_name,
      label: q.name,
    } as SelectItem;
  });

  const columns = [
    {
      Header: 'Gambar',
      accessor: 'url',
      Cell: ({ value }: { value: string }) => {
        return (
          <div 
            className='flex items-center' 
            onClick={() => {window.open(value, '_blank')}}
          >
            <img src={value} className='w-20 h-20'/>
            <div>
              <i className='icon ion-md-download pointer ml-2 text-grey'/>
            </div>
        </div>
        );
      },
    },
    {
      Header: 'Tanggal',
      accessor: 'timestamp',
      Cell: ({ value }: { value: Date }) => {
        if (value) {
          return dayjs(value).format('DD/MM/YYYY HH:mm:ss');
        }
        return '-';
      },
    },
    {
      Header: 'Pilih',
      accessor: '',
      className: '!overflow-visible !whitespace-normal',
      Cell: ({row }:  CellProps<CivImage>) => {
        return (
          <SelectRadio
            label='Pilih'
            onChange={(selected) => {
              changeDeclaration(selected?.value, row.values.url)
            }}
            value={changedDeclaration[row.values.url]}
          />
        );
      },
    }
  ];

  return (
    <Modal
      open={open === 'sightcall'}
      onCloseModal={() => setOpen(undefined)}
      className={{ 
        header: 'justify-start border-none p-none flex flex-center',
        content: '!overflow-auto p-8 !min-w-[600px] !w-[60%]'
      }}
      title={'Hasil Inspeksi Kendaraan'}
    >
      <span className='text-sm text-sky-600'>{civImages.length - allocated} dari {civImages.length} Foto Tersedia</span>
      <span className='text-sm text-sky-600'>{allocated} dari {declarations.length} deklarasi sudah dialokasikan</span>

      <div className="modal-table-content">
        {/* only open select radio once at time */}
        <SelectRadioGroup
          items={declarations}
          selectedItems={Object.values(changedDeclaration)}
        >
          <Table
            columns={columns}
            data={images}
            emptyMessage='Belum ada foto tersimpan dari SightCall'
          />
        </SelectRadioGroup>
      </div>
      {images.length > 0 && (
        <div className="flex justify-center">
          <Button
            variant="primary"
            size="md"
            isBlock={false}
            onClick={onSubmit}
            isLoading={loading}
            className="p-4 m-4 w-1/4 border border-sky-600 bg-transparent text-sky-600 hover:text-sky-600"
          >
            Simpan
          </Button>
        </div>
      )}
    </Modal>
  );
}
