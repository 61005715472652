import { UserTypes } from "interfaces/user";

const validateRoles = (allowedRoles: string[], groups: string[]) => {
  return (groups && groups.length) ? groups.some(group => allowedRoles.includes(group)) : false;
};

// TODO: need to ignore no-explicit-any rule since react-jwt returns non type safe decodedToken
/* eslint-disable  @typescript-eslint/no-explicit-any */
export const validateQaRole = (decodedToken: UserTypes | any) => {
  if (decodedToken && Object.keys(decodedToken).length > 0) {
    const allowedRoles = ['sales-qa', 'qa-tugu'];
    return validateRoles(allowedRoles, decodedToken.groups);
  }
};
