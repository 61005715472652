import React, { ReactNode, useState } from 'react';
import { toast } from 'react-toastify';
import '../../../Review.scss';

interface DetailBoxProps {
  className?: string;
  children: ReactNode;
  title?: string;
  subTitle?: string;
  loading?: boolean;
  isAutoExpand?: boolean;
}

export const DetailBox = (props: DetailBoxProps) => {
  const { className, title, subTitle, children, loading, isAutoExpand = false } = props;
  const [open, setOpen] = useState<boolean>(title && !isAutoExpand ? false : true);

  return (
    <div className={`detail-content-description ${className}`}>
      {title &&
        <div className={`box-title p-3 ${open && 'open-box'}`}>
          <div className='flex flex-col'>
            <h6>{title}</h6>
            <div className='flex items-center mt-1.5'>
              {subTitle && <p>{subTitle}</p>}
              {title === 'Orders' && (
                <button className="focus:outline-none" onClick={() => {
                  navigator.clipboard.writeText(subTitle);
                  toast.success('Order ID disalin.');
                }}>
                  <img className="ml-3 hover:opacity-70 pointer w-fit h-fit" src='/assets/icon/copy.png' alt="Copy" />
                </button>
              )}
            </div>
          </div>
          {!loading ?
            open ?
              <i
                onClick={() => { setOpen(false); }}
                className='icon ion-ios-arrow-up pointer'
              />
              : <i
                onClick={() => { setOpen(true); }}
                className='icon ion-ios-arrow-down pointer'
              />
            :
            <></>
          }
        </div>
      }
      {open && <div className='p-3'>{children}</div>}
    </div>
  );
};
