import React, { useState } from 'react';
import { Button } from '@lifepal/lifepal-design-system';
import { Modal, DropDown } from 'components';
import { useReviewDetailContext } from 'containers/review/Detail';
import { ReasonType } from 'types/quotation';

export const ModalReject = () => {
  const { open, setOpen, updateQuotationStatus, isSubmitting, cancelReasons } = useReviewDetailContext();
  const [reject, setReject] = useState<string | null>('');

  const handleClose = () => {
    setOpen(undefined);
  };

  const handleReject = async () => {
    if (reject) {
      updateQuotationStatus({
        statusPayload: 'CANCEL',
        cancelReasonId: reject
      });
    }
  };

  return (
    <Modal
      open={open === 'reject'}
      onCloseModal={handleClose}
      title='Reject Order Ini?'
      className={{ header: 'border-none p-none' }}
    >
      <div className='modal-content-form pb-1-half-rem'>
        <DropDown
          value={reject}
          id='select-cancel-reasons'
          options={cancelReasons && cancelReasons.length > 0 && cancelReasons.map((item: ReasonType) => ({ name: item.message, value: item.id, label: item.message })) || []}
          onChange={(reject: string | null) => setReject(reject)}
        />
      </div>
      <div className='modal-button-action mt-5'>
        <Button disabled={isSubmitting} onClick={handleClose} className={`bg-pointer border p-2 m-2 ${isSubmitting ? 'bg-slate-50' : 'pointer'}`}>
          Batal
        </Button>
        <Button disabled={isSubmitting} onClick={handleReject} className={`text-white p-2 m-2 ${isSubmitting ? 'bg-blue-300' : 'bg-brand-primary-color pointer'}`}>
          Reject
        </Button>
      </div>
    </Modal>
  );
};
