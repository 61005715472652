// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-datepicker-wrapper input {
  padding: 10px 1rem;
}

.react-datepicker__triangle {
  display: none !important;
}

.react-datepicker-popper {
  z-index: 5;
}`, "",{"version":3,"sources":["webpack://./src/components/Input/DatePicker.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;AAAJ;;AAIA;EACE,wBAAA;AADF;;AAIA;EACE,UAAA;AADF","sourcesContent":[".react-datepicker-wrapper {\n  input {\n    padding: 10px 1rem;\n  }\n}\n\n.react-datepicker__triangle {\n  display: none !important;\n}\n\n.react-datepicker-popper {\n  z-index: 5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
