import React, { useState, createContext, useContext, useEffect } from 'react';
import get from 'lodash/get';
import QueryString from 'qs';
import { Button } from '@lifepal/lifepal-design-system';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { createSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { differenceInCalendarDays } from 'date-fns';
import classNames from 'classnames';
import { MemberLayout, Comment, CheckBox } from 'components';
import { SuspendStatus } from 'layouts';
import { ModalSuspend } from 'containers/review/components/ModalSuspend';
import { ModalReject } from 'containers/review/components/ModalReject';
import { ModalApprove } from 'containers/review/components/ModalApprove';
import { ModalRecord } from 'containers/review/components/ModalRecord';
import { ModalValidation } from 'containers/review/components/ModalValidation';
import { ModalSightCallAssignment } from './components/ModalSightCallAssignment';
import { Specification } from './components/Detail/Specification';
import { Insured } from './components/Detail/Insured';
import { Person } from './components/Detail/Person';
import { Declaration } from './components/Detail/Declaration';
import { Orders } from './components/Detail/Orders';
import {
  DetailTypes,
  PolicyMembersTypes,
  OrderTypes,
  DeclarationQuestionTypes,
  DeclarationTypes,
} from 'containers/review/types';
import {
  GetQuotesTypes,
  UpdateQuotationStatusProps,
  ReasonType,
  SuspendReasonUpdateInputTypes,
  QuoteReasonTypes,
} from 'types/quotation';
import { ReviewActionType, SelectedSuspendReasonType } from 'types/review';
import { getPolicyMembers } from 'utils/getPolicyMembers';
import { apiClient } from 'utils/apiClient';
import { getOtherDeclarationQuestions } from 'utils/declaration';
import {
  IdentificationType,
  IndentifyField,
  PolicyMembereType,
  detailStatusEnum,
} from 'utils/Enums';
import { useAuthContext } from 'providers/AuthProvider';
import './Review.scss';
import './components/Modal.scss';
import { ModalSuccess } from './components/ModalSuccess';
import { AgentButton } from './components/Detail/components/AgentButton';

interface ReviewDetailTypes {
  setOpen: (thisOpen: ReviewActionType) => void;
  open: ReviewActionType;
  leadId: string | undefined;
  phoneNumber: string | undefined;
  updateQuotationStatus: (props: UpdateQuotationStatusProps) => void;
  setDetails: (thisDetail: DetailTypes) => void;
  getQuotation: () => Promise<GetQuotesTypes>;
  quotationId: string | undefined;
  quotationReasons: QuoteReasonTypes[] | undefined;
  setIsSubmitting: (submitting: boolean) => void;
  isSubmitting: boolean;
  suspendReason?: string;
  autoPolisInforcedDate: boolean;
  redirectToHome: () => void;
  status: string;
  modifiedBy?: string;
  setSelectedSuspendReasons: (suspend: SelectedSuspendReasonType[]) => void;
  selectedSuspendReasons: SelectedSuspendReasonType[];
  suspendReasons: ReasonType[];
  cancelReasons: ReasonType[];
  isAutoMoto: boolean;
  quotation: GetQuotesTypes;
  order: OrderTypes;
  declarationQuestions: DeclarationQuestionTypes[];
  refetchData: () => void;
  isAllowedQA: boolean;
}

const ReviewDetailContext = createContext<ReviewDetailTypes>(
  {} as ReviewDetailTypes
);

interface ReviewDetailProps {
  quotationId: string | undefined;
}

export const ReviewDetailContainer = (props: ReviewDetailProps) => {
  const { quotationId } = props;
  const { getParams, navigate, user } = useAuthContext();
  const status = getParams('status') as string;
  const startDate = getParams('startDate') as string;
  const endDate = getParams('endDate') as string;
  const [open, setOpen] = useState<ReviewActionType | undefined>(undefined);
  const [details, setDetails] = useState<DetailTypes>({} as DetailTypes);
  const [loading, setLoading] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [readyApprove, setReadyApprove] = useState<boolean>(true);
  const [order, setOrder] = useState<OrderTypes>({} as OrderTypes);
  const [declarationQuestions, setDeclarationQuestions] = useState<
    DeclarationQuestionTypes[]
  >([] as DeclarationQuestionTypes[]);
  const [otherDeclarationQuestions, setOtherDeclarationQuestions] = useState<
    DeclarationQuestionTypes[]
  >([{} as DeclarationQuestionTypes]);
  const [isTuguQA, setTuguQA] = useState<boolean>(false);
  const [isCompany, setIsCompany] = useState<boolean>(false);
  const [autoPolisInforcedDate, setAutoPolisInforcedDate] =
    useState<boolean>(false);
  const [statusUpdated, setStatusUpdated] = useState<string>('');
  const [selectedSuspendReasons, setSelectedSuspendReasons] = useState<
    SelectedSuspendReasonType[]
  >([]);
  const [suspendReasons, setSuspendReasons] = useState<ReasonType[]>([]);
  const [cancelReasons, setCancelReasons] = useState<ReasonType[]>([]);
  const [isAutoMoto, setIsAutoMoto] = useState(true);
  const [isAllowedQA, setAllowedQA] = useState(true);

  const GET_ORDER_QUOTATION_QUERY = gql`
    query OrderQuotation($quotationId: Uuid!, $quotesId: String) {
      getSuspendReasons: getReasons(
        input: { category: "Suspend Reason", appName: "qa_app" }
      ) {
        id
        isActive
        createdAt
        updatedAt
        message
        displayMessage
        suspendReasonGroup
        qaApp
        tsoApp
        system
        internalManual
        internalBulkUpload
      }
      getCancelReasons: getReasons(
        input: { category: "Cancel Reason", appName: "qa_app" }
      ) {
        id
        isActive
        createdAt
        updatedAt
        message
        displayMessage
        suspendReasonGroup
        qaApp
        tsoApp
        system
        internalManual
        internalBulkUpload
      }
      orderV2(byQuotationId: { quotationId: $quotationId }) {
        id
        policy {
          suspendReason
        }
        parentOrder {
          humanId
          policy {
            policyFile
            policyNumber
          }
          quotation {
            declaration
          }
        }
        productName
        productBrandName
        productBrandLogo
        planName
        modifiedBy
        humanId
      }
      getQuotesById(input: { id: $quotesId }) {
        category
        status
        declarationType
        declaration
        productId
        civImages {
          url
          timestamp
        }
        qaAgent {
          firstName
          lastName
          email
        }
        buyer {
          email
          id
          phoneNumber
          fullName
          dob
          birthPlace
          citizenship
          idVerificationPicture
          idCardFile
          idType
          identityCardNumber
          passportNumber
          familyCardNumber
          maritalStatus
          monthlyIncome
          sourceOfFund
          gender
          taxId
          taxIdImage
          address
          province
          city
          district
          subDistrict
          postalCode
          rt
          rw
        }
        cancelReason
        planId
        leadId
        quoteReasons {
          message
          createdAt
        }
        policyMembers {
          relation
          type
          idType
          percentageBeneficiary
          insuredItem {
            term
            type
            sumInsured
            year
            brand
            model
            region
            area
            brandId
            modelId
            typeId
            additionalBenefits {
              value
              name
              code
              qty
            }
          }
          person {
            id
            idType
            taxId
            email
            isChildren
            birthPlace
            fullName
            dob
            gender
            phoneNumber
            waPhoneNumber
            idNumber
            idCountry
            citizenship
            idCardFile
            countryOfBirth
            religion
            maritalStatus
            address
            province
            city
            district
            subDistrict
            postalCode
            rt
            rw
            mainProfile
            additionalData
            kycVerification
            idVerificationPicture
            taxId
            taxIdImage
            kycAgentStatus
            identityCardNumber
            passportNumber
            familyCardNumber
            monthlyIncome
            sourceOfFund
          }
        }
      }
    }
  `;

  const UPDATE_QUOTATION_STATUS_MUTATION = gql`
    mutation UpdateQuotationStatus(
      $id: String
      $status: QuotesStatus
      $cancelReasonId: String
      $suspendReasons: [String!]
      $suspendReasonsV2: [SuspendReasonUpdateInput!]!
    ) {
      updateQuotationStatus(
        input: {
          id: $id
          status: $status
          cancelReasonId: $cancelReasonId
          suspendReasons: $suspendReasons
          suspendReasonsV2: $suspendReasonsV2
        }
      ) {
        id
        status
        buyerEmail
        buyerPhone
        cancelReasonId
      }
    }
  `;

  const [handleGetOrderQuotation, { data, refetch: refetchData }] =
    useLazyQuery<{
      getQuotesById: GetQuotesTypes;
      orderV2: OrderTypes;
      getSuspendReasons: ReasonType[];
      getCancelReasons: ReasonType[];
    }>(GET_ORDER_QUOTATION_QUERY);
  const [handleUpdateQuotationStatus] = useMutation(
    UPDATE_QUOTATION_STATUS_MUTATION
  );

  const getOrderQuotation = async () => {
    setLoading(true);
    try {
      const { data, error } = await handleGetOrderQuotation({
        variables: {
          quotationId: quotationId,
          quotesId: quotationId,
        },
        fetchPolicy: 'network-only',
      });

      if (error) throw error;

      fetchDeclarationQuestions(get(data, 'getQuotesById.productId'));
      const otherDeclarationQuestions = getOtherDeclarationQuestions();
      setOtherDeclarationQuestions(otherDeclarationQuestions);
      setOrder(data.orderV2);

      const details = prepareQuotationData(data.getQuotesById, data.orderV2);
      const product = await fetchPlan(details);
      details.premium_monthly = product.premium_monthly;
      details.premium = product.premium;

      setSuspendReasons(data.getSuspendReasons);
      setCancelReasons(data.getCancelReasons);
      setDetails(details);
      setLoading(false);
      return data.getQuotesById as GetQuotesTypes;
    } catch (error) {
      toast.error('Gagal mengambil data Order/Quotation.');
      return;
    } finally {
      setLoading(false);
    }
  };

  const fetchDeclarationQuestions = async (productId: number) => {
    try {
      const questions = await apiClient(
        'get',
        'products',
        `/products/${productId}/declarations`
      );

      if (questions && questions.data) {
        setDeclarationQuestions(questions.data);
        return questions.data;
      }
    } catch (error) {
      toast.error('Gagal mengambil data Deklarasi.');
      return;
    }
  };

  const fetchPlan = async (details: DetailTypes) => {
    let planUrl = '/plans';

    if (details.isAuto) {
      planUrl = planUrl + '/auto';
    }

    const query = {
      ...(details.year && { year: `${details.year}` }),
      ...(details.term && { term: details.term }),
      ...(details.member && { member: details.member }),
      ...(details.namePlan && { name: details.namePlan }),
      ...(details.benefits && {
        additional_benefits: JSON.stringify(details.benefits),
      }),
      ...(details.sumInsured && { sum_insured: details.sumInsured }),
      ...(details.budget && { budget: details.budget }),
    };

    try {
      const plan = await apiClient(
        'get',
        'products',
        `${planUrl}/${details.planId}?${QueryString.stringify(query)}`
      );
      if (plan.data) return plan.data;
    } catch (error) {
      toast.error('Gagal mengambil data produk');
      console.error('Failed to fetch product', error);
    }
  };

  const prepareQuotationData = (data: GetQuotesTypes, order: OrderTypes) => {
    const myDetails: DetailTypes = details;
    myDetails.agentQA = data.qaAgent;
    myDetails.buyer = data.buyer;
    myDetails.leadId = data.leadId || undefined;
    myDetails.humanId = order.humanId;
    myDetails.phoneNumber = data.buyer.phoneNumber;
    myDetails.productName = order.productName;
    myDetails.productBrandLogo = order.productBrandLogo;
    myDetails.productBrandName = order.productBrandName;
    myDetails.planName = order.planName;
    myDetails.modifiedBy = order.modifiedBy;
    myDetails.planId = data.planId;
    myDetails.cancelReason = data.cancelReason;
    myDetails.quotationReasons =
      (data.quoteReasons &&
        data.quoteReasons.length > 0 &&
        data.quoteReasons) ||
      [];

    const autoObject = get(data, 'policyMembers', []).find(
      (row: PolicyMembersTypes) => row.insuredItem
    );
    if (autoObject && autoObject.insuredItem) {
      const { brand, model, type, region, modelId, typeId } =
        autoObject.insuredItem;
      myDetails.namePlan = `${brand}_${model}_${type}_${region}`;
      myDetails.isAuto = true;
      myDetails.modelId = modelId;
      myDetails.typeId = typeId;
    }
    const duration = JSON.stringify(get(data, 'declaration.duration', 0));
    const year = get(autoObject, 'insuredItem.year', null);
    myDetails.year = year;
    const term = get(autoObject, 'insuredItem.term', null);
    myDetails.term = term;
    const memberList = get(data, 'policyMembers', []).filter(
      (row: PolicyMembersTypes) =>
        row.person && row.type === PolicyMembereType.INSURED
    );
    const members = memberList.length
      ? JSON.stringify(getPolicyMembers(memberList, duration))
      : null;
    myDetails.member = members;
    myDetails.benefits = get(autoObject, 'insuredItem.additionalBenefits', []);
    const sumInsured = get(autoObject, 'insuredItem.sumInsured', null);
    myDetails.sumInsured = sumInsured;
    const budget = get(data, 'declaration.product.budget', 0);
    myDetails.budget = budget;
    myDetails.category = data.category;

    myDetails.insuredList = data.policyMembers
      .filter((d: PolicyMembersTypes) => d.type === PolicyMembereType.INSURED)
      .sort((a, b) => (a.relation === '' ? -1 : b.relation === '' ? 1 : 0));
    myDetails.beneficiaryList = data.policyMembers.filter(
      (d: PolicyMembersTypes) => d.type === PolicyMembereType.BENEFICIARY
    );
    myDetails.holder = data.policyMembers.find(
      (d: PolicyMembersTypes) =>
        !d.relation && d.type === PolicyMembereType.HOLDER
    );

    // for auto insured
    myDetails.insured = data.policyMembers.find(
      (d: PolicyMembersTypes) =>
        !d.relation && d.type === PolicyMembereType.INSURED
    );

    myDetails.declaration = JSON.parse(data.declaration);
    myDetails.status = data.status;
    myDetails.orderBuyerPhoneNumber = data.buyer.phoneNumber;

    const checkPolisInforcedDate = validateAutoPolisInforcedDate(
      myDetails.declaration?.polis_inforced
    );
    setAutoPolisInforcedDate(checkPolisInforcedDate);
    setDetails(myDetails);
    setIsAutoMoto(/^(auto|moto)$/i.test(data.category));

    if (myDetails?.buyer?.gender.toLowerCase() === 'other') {
      setIsCompany(true);
    }

    return myDetails;
  };

  useEffect(() => {
    if (!data) {
      return;
    }

    prepareQuotationData(data.getQuotesById, data.orderV2);
  }, [data]);

  const redirectToHome = () => {
    navigate({
      pathname: '/review',
    });
  };

  const updateQuotationStatus = async (payload: UpdateQuotationStatusProps) => {
    const { statusPayload, cancelReasonId, selectedSuspendReasons } = payload;
    await getOrderQuotation().then(async (data: GetQuotesTypes) => {
      if (!['PENDING_QA', 'REVISE'].includes(data.status)) {
        setOpen('validation');
        return;
      }

      setIsSubmitting(true);

      const oldFormatSuspendReason =
        (selectedSuspendReasons?.length > 0 &&
          selectedSuspendReasons.map(
            (suspend: SuspendReasonUpdateInputTypes) => suspend.suspendReasonId
          )) ||
        [];

      try {
        const { errors } = await handleUpdateQuotationStatus({
          variables: {
            id: quotationId,
            status: statusPayload,
            ...(cancelReasonId && cancelReasonId.length > 0
              ? { cancelReasonId: cancelReasonId }
              : {}),
            suspendReasonsV2:
              selectedSuspendReasons?.length > 0 ? selectedSuspendReasons : [],
            suspendReasons: oldFormatSuspendReason,
          },
        });
        if (errors) throw errors;

        setIsSubmitting(false);
        setStatusUpdated(statusPayload);
        toast.success(`Berhasil mengubah status ke ${statusPayload}`, {
          autoClose: 5000,
        });
        setTimeout(() => {
          redirectToHome();
        }, 5000);
      } catch (error) {
        const errorString = JSON.stringify(error);
        setIsSubmitting(false);
        if (
          !errorString.includes(
            'Tanggal berlaku polis tidak dapat sebelum tanggal hari ini'
          )
        ) {
          toast.error(
            error.message ||
            `Gagal mengubah status quotation menjadi ${statusPayload}`
          );
        }
      }

      setOpen(undefined);
    });
  };

  const validateAutoPolisInforcedDate = (inforceddate?: string) => {
    if (
      !inforceddate ||
      (inforceddate &&
        differenceInCalendarDays(new Date(inforceddate), new Date()) < 0)
    ) {
      return true;
    }

    return false;
  };

  const checkPolicyMemberType = (policyMember: PolicyMembersTypes): boolean => {
    return (
      !policyMember.idType ||
      policyMember.idType === IdentificationType.UNKNOWN_ID_CARD_TYPE ||
      !policyMember.person[IndentifyField[policyMember.idType]]
    );
  };

  const onApprove = () => {
    // validate first. idType should not empty and should id should be filled
    // pemegang polis
    if (checkPolicyMemberType(details.holder)) {
      toast.error('Gagal approve quotation, tipe identitas wajib diisi');
      return;
    }

    if (!['auto', 'moto'].includes(details.category)) {
      for (let i = 0; i < details.insuredList.length; i++) {
        const policyMember = details.insuredList[i];
        if (checkPolicyMemberType(policyMember)) {
          toast.error(
            'Gagal approve quotation, tipe identitas tertanggung wajib diisi '
          );
          return;
        }
      }

      for (let i = 0; i < details.beneficiaryList.length; i++) {
        const policyMember = details.beneficiaryList[i];
        if (checkPolicyMemberType(policyMember)) {
          toast.error(
            'Gagal approve quotation, tipe identitas penerima manfaat wajib diisi '
          );
          return;
        }
      }
    }

    setOpen('approve');
  };

  useEffect(() => {
    getOrderQuotation();
  }, []);

  useEffect(() => {
    if (user && user.groups && user.groups.includes('qa-tugu')) {
      setReadyApprove(true);
      setTuguQA(true);
      return;
    }
    setReadyApprove(false);
    setTuguQA(false);
  }, [user]);

  useEffect(() => {
    const qaAgent = data && data.getQuotesById && data.getQuotesById.qaAgent;

    if (!qaAgent) {
      setAllowedQA(false);
    }

    if (
      user &&
      user.email &&
      qaAgent
    ) {
      setAllowedQA(user.email === data.getQuotesById.qaAgent.email);
    }
  }, [user, data]);

  const orderDeclarations = (
    !data?.getQuotesById?.declaration
      ? {}
      : JSON.parse(data?.getQuotesById?.declaration)
  ) as DeclarationTypes;
  return (
    <ReviewDetailContext.Provider
      value={{
        open,
        setOpen,
        leadId: details.leadId,
        phoneNumber: details.orderBuyerPhoneNumber,
        updateQuotationStatus,
        setDetails,
        quotationId,
        quotationReasons: details.quotationReasons,
        getQuotation: getOrderQuotation,
        isSubmitting,
        setIsSubmitting,
        suspendReason: order.policy?.suspendReason || '',
        autoPolisInforcedDate: autoPolisInforcedDate,
        redirectToHome,
        status: details.status,
        modifiedBy: details.modifiedBy,
        setSelectedSuspendReasons,
        selectedSuspendReasons: selectedSuspendReasons,
        suspendReasons,
        cancelReasons,
        isAutoMoto,
        quotation: data?.getQuotesById,
        declarationQuestions,
        order,
        refetchData,
        isAllowedQA,
      }}
    >
      <MemberLayout>
        <div className="review-detail-container">
          {details.status ? (
            statusUpdated ||
              ['ACTIVE', 'SUSPEND', 'CANCEL'].includes(details.status) ? (
              <div className="my-12 mx-6 p-8 shadow-lg hover:shadow-xl">
                <p className="text-2xl my-3 font-medium">
                  Quotation Status: {statusUpdated || details.status}
                </p>
                <p className="text-lg my-2">
                  Maaf, quotation ini telah{' '}
                  {detailStatusEnum[statusUpdated || details.status]}
                </p>
              </div>
            ) : (
              <>
                <div className="detail-actions">
                  <Button
                    className={classNames(
                      'reject px-8 py-2',
                      isAllowedQA ? 'pointer hover:opacity-90' : 'opacity-30'
                    )}
                    onClick={() => setOpen('reject')}
                    disabled={!isAllowedQA}
                  >
                    <i className="icon ion-ios-close mr-2.5" />
                    Reject
                  </Button>
                  <Button
                    className={classNames(
                      'suspend px-8 py-2',
                      selectedSuspendReasons.length <= 0
                        ? 'opacity-30'
                        : 'pointer'
                    )}
                    onClick={() => setOpen('suspend')}
                    disabled={
                      selectedSuspendReasons.length <= 0 || !isAllowedQA
                    }
                  >
                    <i className="icon ion-ios-pause mr-2.5" />
                    Suspend
                  </Button>
                  <Button
                    className={classNames(
                      'record px-8 py-2',
                      isAllowedQA ? 'pointer hover:opacity-70' : 'opacity-30'
                    )}
                    onClick={() => setOpen('record')}
                    disabled={!isAllowedQA}
                  >
                    <i className="icon ion-ios-refresh mr-2.5" />
                    Rekaman
                  </Button>
                  <div className="ml-auto flex">
                    <AgentButton qaAgent={data.getQuotesById?.qaAgent} />
                    <Button
                      className={classNames(
                        'text-white px-8 py-2',
                        isSubmitting || !readyApprove
                          ? 'bg-blue-300'
                          : 'bg-brand-primary-color pointer hover:opacity-90'
                      )}
                      onClick={onApprove}
                      disabled={!readyApprove || !isAllowedQA}
                    >
                      Approve
                    </Button>
                  </div>
                </div>
                <div>
                  <SuspendStatus
                    suspendReasons={selectedSuspendReasons}
                    onClearAll={() => setSelectedSuspendReasons([])}
                    disabled={!isAllowedQA}
                  />
                </div>
                <Comment
                  orderId={order && order.id}
                  quotationStatus={details && details.status}
                />
                <div className="review-detail-content">
                  {!isAllowedQA && (
                    <div className="overlay w-full h-full z-50 absolute bg-[#666666] opacity-60 pointer-events-auto cursor-not-allowed" />
                  )}
                  <h1 className="mt-7 mb-7">Atur Spesifikasi</h1>
                  <Specification loading={loading} details={details} />
                  <h1 className="mb-7">Orders</h1>
                  <Orders loading={loading} details={order} />
                  <h1 className="mb-7">Pembeli</h1>
                  <Person
                    person={details.buyer}
                    showAddress={true}
                    loading={loading}
                    isCompany={isCompany}
                    personType="Pembeli"
                  />
                  <h1 className="mb-7">Tertanggung</h1>
                  {['auto', 'moto'].includes(details.category) ? (
                    <Insured loading={loading} details={details} />
                  ) : (
                    details.insuredList.map(
                      (insured: PolicyMembersTypes, i: number) => {
                        const isMainInsured = insured.relation === '';
                        return (
                          <Person
                            key={`insured-${i}`}
                            person={insured.person}
                            showAddress={isMainInsured}
                            loading={loading}
                            policyMember={insured}
                            personType="Tertanggung"
                          />
                        );
                      }
                    )
                  )}
                  <h1 className="mb-7">Pemegang Polis</h1>
                  <Person
                    showAddress
                    isCompany={isCompany}
                    loading={loading}
                    policyMember={details && details.holder}
                    person={details?.holder?.person}
                    personType="Pemegang Polis"
                  />
                  {!['auto', 'moto'].includes(details.category) && (
                    <>
                      <h1 className="mb-7">Penerima Manfaat</h1>
                      {details.beneficiaryList.map(
                        (beneficiary: PolicyMembersTypes, i: number) => (
                          <Person
                            showAddress={false}
                            key={`beneficiary-${i}`}
                            isCompany={isCompany}
                            loading={loading}
                            policyMember={beneficiary}
                            person={beneficiary.person}
                            personType="Ahli Waris"
                          />
                        )
                      )}
                    </>
                  )}
                  <h1 className="mb-7">Deklarasi</h1>
                  <Declaration
                    orderDeclarations={orderDeclarations}
                    declarationQuestions={declarationQuestions}
                  />
                  {['Zurich Syariah', 'Zurich Asuransi Indonesia'].includes(
                    details.productBrandName
                  ) && (
                      <Declaration
                        title="Dokumen Lainnya"
                        orderDeclarations={orderDeclarations}
                        declarationQuestions={otherDeclarationQuestions}
                      />
                    )}
                  {!isTuguQA && (
                    <div className="my-10 flex w-2/3">
                      <CheckBox
                        key="ready-approve-check"
                        checked={readyApprove}
                        value=""
                        onChange={() => setReadyApprove(!readyApprove)}
                      />
                      <span className="text-sm ml-4">
                        Dengan menyetujui quotation ini, Anda membenarkan bahwa
                        data-data yang dilampirkan untuk pengajuan polis telah
                        sesuai dan juga sudah mengkaji ulang riwayat <i>chat</i>{' '}
                        dan <i>recording</i>.
                      </span>
                    </div>
                  )}
                </div>
              </>
            )
          ) : (
            <></>
          )}
        </div>
        {open === 'suspend' && (
          <ModalSuspend declaration={details.declaration} />
        )}
        {open === 'reject' && <ModalReject />}
        {open === 'approve' && <ModalApprove />}
        {open === 'record' && <ModalRecord />}
        {open === 'validation' && <ModalValidation />}
        {open === 'sightcall' && <ModalSightCallAssignment />}
        {open === 'success' && <ModalSuccess />}
      </MemberLayout>
    </ReviewDetailContext.Provider>
  );
};

export const useReviewDetailContext = () => useContext(ReviewDetailContext);
