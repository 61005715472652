import React, { useEffect, useState } from 'react';
import './Input.scss';

interface InputProps {
  placeholder?: string;
  onChange?: (thisValue: string) => void;
  value?: string | number | readonly string[] | undefined | null | boolean;
}

export const Input = (props: InputProps) => {
  const { placeholder, onChange, value } = props;
  const [thisValue, setValue] = useState<string | number | readonly string[] | undefined | null | boolean>('');

  useEffect(() => {
    setValue(value);
  }, [value]);

  return (
    <input
      className='p-vert-1 p-horz-1-rem'
      type='text'
      defaultValue={thisValue as string}
      placeholder={placeholder}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => { onChange && onChange(e.target.value) }}
    />
  );
};
