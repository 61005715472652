import React from 'react'
import { useEditableDetailContext } from './EditableDetail';

export const EditableDetailAction = () => {
  const { classNames, onCancel, onSubmit, isEdit, enableEdit, disableEdit } = useEditableDetailContext();
  const cancel = () => {
    disableEdit();
    onCancel && onCancel();
  }

  const submit = () => {
    disableEdit();
    onSubmit && onSubmit();
  }

  const onEdit = () => {
    enableEdit();
  }

  return (
    <>
      {isEdit && (
        <div className={`text-xs inline-flex gap-2.5 ${classNames?.action || ''}`}>
          <span onClick={() => { submit(); }} className='text-blue pointer'>Simpan</span>
          <span onClick={() => { cancel(); }} className='text-red pointer'>Batal</span>
        </div>
      )}

      {
        !isEdit && (
          <i 
            onClick={() => { onEdit(); }} 
            className={`lp-edit pointer ml-2 text-grey ${classNames?.action || ''}`} 
          />
        )
      }
    </>
  )
}

