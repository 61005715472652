import React, { Fragment, useState, useEffect } from 'react';
import { SelectedSuspendReasonType } from 'types/review';
import { useReviewDetailContext } from 'containers/review/Detail';
import { PolicyMembersPersonType } from 'containers/review/types';
import { CheckBox } from 'components/Selected/Checkbox';
import './Selected.scss'

interface ItemTypes {
  key: string;
  value: string;
}

interface SelectedProps {
  items: ItemTypes[];
  selected: SelectedSuspendReasonType[];
  label: string;
  onChange: (selects: SelectedSuspendReasonType[]) => void;
  section?: PolicyMembersPersonType | 'Deklarasi';
  value?: string;
}

export const Selected = (props: SelectedProps) => {
  const { items, label, onChange, section, value } = props;
  const { suspendReasons, selectedSuspendReasons } = useReviewDetailContext();
  const [open, setOpen] = useState<boolean>(false);
  const [check, setCheck] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const newHandleCheck = (select: string) => {
    setLoading(true);
    let currentSelects: SelectedSuspendReasonType[] = [...selectedSuspendReasons];
    const currentSelectedIndex = currentSelects.findIndex(item => item.id === select);

    if (currentSelectedIndex < 0) {
      const suspendReasonGroupItem = suspendReasons.find((suspendReason) => suspendReason.id === select);
      const currentSelect: SelectedSuspendReasonType = {
        id: suspendReasonGroupItem.id,
        suspendReasonGroup: suspendReasonGroupItem.suspendReasonGroup,
        message: suspendReasonGroupItem.message,
        section: section,
        value: value,
      }

      currentSelects.push(currentSelect);
    } else {
      currentSelects = currentSelects.filter((item: SelectedSuspendReasonType) => item.id !== select);
    }

    if (onChange) onChange(currentSelects);
  }

  useEffect(() => {
    // setCheck -> boolean
    // to find if the length of items is exist
    // items is filtered by if the value includes in selected array
    // need to filter options for the section only with id is matching
    const filter = items.filter((res: ItemTypes) => selectedSuspendReasons.findIndex(item => item.id === res.value) > -1);
    const check = filter.length > 0;
    setCheck(check);
    if (loading) {
      setLoading(false);
    }
  }, [open, loading, selectedSuspendReasons]);

  return (
    <div className='flex flex-col relative w-fit'>
      <CheckBox label={label} checked={check} onChange={(() => setOpen(!open))} />
      {open && <div className='z-[2] shadow-xl overflow-auto flex flex-col p-[1.5rem] shadow-1 rounded-lg gap-3 absolute mt-5 top-full left-0 bg-white'>
        {items.map((item: ItemTypes, idx: number) => (
          <Fragment key={item.value}>
            <div className='flex flex-row flex-nowrap gap-2 items-center'>
              <CheckBox checked={selectedSuspendReasons.some(reason => reason.id === item.value)} onChange={() => { newHandleCheck(item.value); }} />
              <span className='whitespace-nowrap' key={item.value}>{item.key}</span>
            </div>
            {idx !== items.length - 1 && <hr className='border-dotted' />}
          </Fragment>
        ))}
      </div>}
    </div>
  );
};
