// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-input {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  border: 1px solid #dbdbdb;
  align-items: center;
  border-radius: 4px;
  background-color: #fff;
}
.search-input:focus-within {
  border: 1px solid #1d66dd;
}
.search-input i {
  font-size: 23px;
}
.search-input i::before {
  color: #1d66dd;
}
.search-input input {
  border: none;
  width: 100%;
  color: #363636;
}
.search-input input:focus {
  outline: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Input/Search.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,yBAAA;EACA,mBAAA;EACA,kBAAA;EACA,sBAAA;AACF;AACE;EACE,yBAAA;AACJ;AAEE;EACE,eAAA;AAAJ;AACI;EACE,cAAA;AACN;AAGE;EACE,YAAA;EACA,WAAA;EACA,cAAA;AADJ;AAGI;EACE,aAAA;AADN","sourcesContent":[".search-input {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: nowrap;\n  border: 1px solid #dbdbdb;\n  align-items: center;\n  border-radius: 4px;\n  background-color: #fff;\n\n  &:focus-within {\n    border: 1px solid #1d66dd;\n  }\n\n  i {\n    font-size: 23px;\n    &::before {\n      color: #1d66dd;\n    }\n  }\n\n  input {\n    border: none;\n    width: 100%;\n    color: #363636;\n\n    &:focus {\n      outline: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
