import React from 'react';
import { useParams } from 'react-router-dom';
import { ReviewDetailContainer } from 'containers/review/Detail';

export const ReviewDetailPage = () => {
  const { quotationId } = useParams();

  return (
    <ReviewDetailContainer quotationId={quotationId} />
  );
};
