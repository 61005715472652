import React, { useEffect } from 'react';
import Select from 'react-select'
import { Regency, Province, lazyGetRegencies } from '../../../services/address';

interface RegencyInputProps extends Partial<Pick<HTMLInputElement, 'name' | 'id'>>{
  defaultValue: string;
  provinceId?: number;
  onChange?: (city: Regency) => void;
  isSearchable?: boolean;
}

export const RegencyInput = (props: RegencyInputProps) => {
  const { defaultValue, onChange, provinceId, ...other } = props;
  const [getRegencies, { data: regencies }] = lazyGetRegencies();

  useEffect(()=>{
    if(!provinceId) {
      return;
    }

    getRegencies({variables: {provinceId: provinceId}})
  }, [provinceId]);

  useEffect(()=>{
    getRegencies();
  }, []);

  let options: Array<{
    value: Regency;
    label: string;
  }> = [];

  if(regencies) {
    options = regencies.getRegencies.map(reg => {
      return {
        value: reg,
        label: reg.name
      }
    });
  }

  const defaultRegency = options.find(reg => reg.label === defaultValue);
  
  return (
    <div>
      <Select
        placeholder='- Pilih Kota'
        options={options}
        value={defaultRegency}
        isSearchable
        onChange={(val) => {
          onChange && onChange(val?.value)
        }}
        {...other}
      />
    </div>
  )
}