import React, { useEffect, useState } from 'react';
import jsCookie from 'js-cookie';
import { gql, useMutation } from '@apollo/client';
import { Input } from '@lifepal/lifepal-design-system';
import { LoginLayout } from 'components';
import { lifepal } from 'utils/assets';
import { useAuthContext } from 'providers/AuthProvider';
import './Login.scss';
import { format } from 'date-fns';
import { createSearchParams } from 'react-router-dom';
import { validateQaRole } from 'utils/qa';

const LOGIN_MUTATION = gql`
  mutation login($username: String!, $password: String!) {
    login(credential: { username: $username, password: $password }) {
      access
      refresh
    }
  }
`;

export const LoginContainer = () => {
  const { login, navigate, token, user } = useAuthContext();
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [handleLogin] = useMutation(LOGIN_MUTATION);

  const HandleLogin = async () => {
    try {
      const { data } = await handleLogin({
        variables: {
          password,
          username,
        }
      });

      if (data && data.login) {
        await login(data.login.access);
      }
    } catch (error) {
      setErrorMessage('Email atau password salah.');
    }
  };

  useEffect(() => {
    if (!token || !user) {
      return;
    }

    const validateRole = validateQaRole(user)
    if (!validateRole) {
      setErrorMessage('Anda tidak dapat menggunakan aplikasi ini.');
      return;
    }
    
    jsCookie.set('token', token, { expires: 1 });
    const defaultEndDate = new Date();
    const defaultStartDate = new Date(new Date().setDate(defaultEndDate.getDate() - 5));

    navigate({
      pathname: '/review',
      search: `${createSearchParams({
        status: 'PENDING_QA',
        startDate: format(defaultStartDate, 'yyyy-MM-dd'),
        endDate: format(defaultEndDate, 'yyyy-MM-dd'),
      })}`,
    });
  }, [token, user]);

  return (
    <LoginLayout>
      <div className='login-container'>
        <img className='login-logo mb-7' src={lifepal} alt='lifepal' />
        <div className='login-content'>
          <h1>Selamat Datang!</h1>
          <div className='login-navigation'>
            <div className='hr' />
          </div>
          <form className='login-form' onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            HandleLogin();
          }}>
            <label>Email</label>
            <Input
              name='email'
              placeholder=''
              type='text'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setUsername(e.target.value); }}
              inputMode='text'
              className='mb-8'
            />
            <div className='password-label'>
              <label>Password</label>
            </div>
            <Input
              type='password'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setPassword(e.target.value); }}
              inputMode='text'
              name='password'
              placeholder=''
              className={!errorMessage && 'mb-8'}
            />
            {errorMessage &&
              <span className='mb-8 mt-2.5 error-message'>{errorMessage}</span>
            }
            <button className='pointer' type='submit'>Masuk</button>
          </form>
        </div>
      </div>
    </LoginLayout>
  );
};
