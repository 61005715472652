import React, { ReactNode, useEffect, useRef } from 'react';
import './Modal.scss';

interface ModalTypes {
  children: ReactNode;
  open: boolean;
  onCloseModal: () => void;
  title?: string;
  className?: {
    root?: string;
    content?: string;
    header?: string;
  };
  onClose: boolean;
}

export const Modal = (props: ModalTypes) => {
  const { children, open, onCloseModal, title, className, onClose } = props;
  const containerRef = useRef<HTMLDivElement>();
  const contentRef = useRef<HTMLDivElement>();

  const onCloseClick = () => {
    if (onCloseModal) {
      containerRef.current.removeEventListener('click', handleClick);
      onCloseModal();
    }
  };

  const handleClick = (e: MouseEvent) => {
    const clickedEl = e.target;
    const contentEl = contentRef.current;

    if (
      !contentRef?.current ||
      !document.contains(clickedEl as Node) ||
      !containerRef.current.contains(clickedEl as Node)
    ) {
      return;
    }

    if (!contentEl.contains(clickedEl as Node)) {
      onCloseClick()
    }
  }

  useEffect(()=>{
    if (!containerRef.current) {
      return;
    }

    containerRef.current.addEventListener('click', handleClick);
  });

  if (!open) {
    return <></>;
  }

  return (
    <div
      ref={containerRef}
      className={`modal ${className && className.root}`}
    >
      <div
        ref={contentRef}
        className={`modal-content ${className && className.content || 'p-3-rem'}`}
      >
        {(title || onClose) && <div
          className={`modal-content-header mb-6 pb-[30px] ${!title && 'border-none p-none'} ${className && className.header}`}
        >
          {onClose && <i className='icon ion-ios-close pointer' onClick={() => { onCloseClick(); }}  />}
          {title && <h4>{title}</h4>}
        </div>}
        {children}
      </div>
    </div>
  );
};

Modal.defaultProps = {
  onClose: true,
};
