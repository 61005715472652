import * as api from '../../utils/apolloClient';
import { gql } from '@apollo/client';
import { District, DistrictDataParams, PostalCode, PostalCodesDataParams, Province, Regency, RegencyDataParams, Village, VillageDataParams } from './address.types';

const PROVINCE_QUERY = gql`
  query getProvinces{
    getProvinces{
      id,
      name
    }
  }
`;

export const lazyGetProvinces = () => {
  return api.lazyQuery<{getProvinces: Province[]}>(PROVINCE_QUERY);
};

const REGENCY_QUERY = gql`
  query getRegencies($provinceId: Int){
    getRegencies( input: {
      provinceId: $provinceId,
    }){
      id
      name
    }
  }
`;

export const lazyGetRegencies = () => {
  return api.lazyQuery<{getRegencies: Regency[]}, RegencyDataParams>(REGENCY_QUERY);
}

const DISTRICT_QUERY = gql`
  query getDistrict($regencyId: Int){
    getDistricts( input: {
      regencyId: $regencyId,
    }){
      id
      name
    }
}`;

export const lazyGetDistrict = () => {
  return api.lazyQuery<{getDistricts: District[]}, DistrictDataParams>(DISTRICT_QUERY);
}

const VILLAGE_QUERY = gql`
  query getVillage($districtId: Int){
    getVillages( input: {
      districtId: $districtId,
    }){
      id
      name
    }
  }
`;

export const lazyGetVillage = () => {
  return api.lazyQuery<{getVillages: Village[]}, VillageDataParams>(VILLAGE_QUERY);
}

const POSTAL_CODE_QUERY = gql`
  query getPostalCodes($villageIds: [Int!]){
    getPostalCodes( input: {
      limit: 20,
      villageIds: $villageIds
    }){
      id
      postalCode
    }
  }
`;

export const lazyGetPostalCode = () => {
  return api.lazyQuery<{getPostalCodes: PostalCode[]}, PostalCodesDataParams>(POSTAL_CODE_QUERY);
}