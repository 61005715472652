import { gql } from '@apollo/client';
import * as api from '../../utils/apolloClient';
import { UpdatePersonParamsTypes } from './types';

const SAVE_OR_UPDATE_PERSON_QUERY_MUTATION = gql`
  mutation SaveOrUpdatePerson(
    $personId: String
    $fullName: String!
    $gender: Gender!
    $maritalStatus: String
    $dob: String!
    $citizenship: String
    $phoneNumber: String
    $type: PolicyMemberType
    $relation: String
    $isChildren: Boolean
    $email: String
    $idCardFile: String
    $sourceOfFund: String
    $monthlyIncome: String
    $otherSourceOfFund: String
    $mainProfile: MainProfPersons
    $isCreateUser: Boolean
    $identityCardNumber: String
    $passportNumber: String
    $familyCardNumber: String
    $idVerificationPicture: String
    $address: String
    $rt: String
    $rw: String
    $province: String
    $city: String
    $district: String
    $subDistrict: String
    $postalCode: String
    $birthPlace: String
    $taxId: String
    $taxIdImage: String
    $idCardType: IdCardType
  ) {
    saveOrUpdatePerson(
      input: {
        personId: $personId
        fullName: $fullName
        gender: $gender
        maritalStatus: $maritalStatus
        dob: $dob
        citizenship: $citizenship
        phoneNumber: $phoneNumber
        type: $type
        relation: $relation
        isChildren: $isChildren
        email: $email
        idCardFile: $idCardFile
        sourceOfFund: $sourceOfFund
        monthlyIncome: $monthlyIncome
        otherSourceOfFund: $otherSourceOfFund
        mainProfile: $mainProfile
        isCreateUser: $isCreateUser
        identityCardNumber: $identityCardNumber
        passportNumber: $passportNumber
        familyCardNumber: $familyCardNumber
        idVerificationPicture: $idVerificationPicture
        address: $address
        rt: $rt
        rw: $rw
        province: $province
        city: $city
        district: $district
        subDistrict: $subDistrict
        postalCode: $postalCode
        birthPlace: $birthPlace
        taxId: $taxId
        taxIdImage: $taxIdImage
        idCardType: $idCardType
      }
    ) {
      id
      gender
      dob
      address
      birthPlace
      citizenship
      city
      district
      email
      idCardFile
      idNumber
      idType
      idVerificationPicture
      sourceOfFund
      mainProfile
      phoneNumber
      postalCode
      province
      rt
      rw
      subDistrict
      waPhoneNumber
      otherSourceOfFund
      monthlyIncome
      maritalStatus
      taxId
      taxIdImage
      identityCardNumber
      passportNumber
      familyCardNumber
      idVerificationPicture
      idType
    }
  }
`;

export const saveOrUpdatePersons = () => {
  return api.mutation<any, UpdatePersonParamsTypes>(SAVE_OR_UPDATE_PERSON_QUERY_MUTATION);
}
