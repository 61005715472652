import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Pagination, Button } from '@lifepal/lifepal-design-system';
import { Modal, Table } from 'components';
import { TableData } from 'components/Table';
import { useReviewDetailContext } from 'containers/review/Detail';
import { RecordTypes } from 'containers/review/types';
import { apiClient } from 'utils/apiClient';

const minutesConverter = (duration: number) => {
  let minutes = String(duration > 59 ? Math.floor(duration / 60) : '00');
  let seconds = String(duration > 59 ? duration % 60 : duration);

  if (minutes.length < 2) { minutes = `0${minutes}`; }
  if (seconds.length < 2) { seconds = `0${seconds}`; }

  return `${minutes}:${seconds}`;
};

export const ModalRecord = () => {
  const { open, setOpen, leadId, phoneNumber } = useReviewDetailContext();
  const [records, setRecord] = useState<TableData[][]>([[]]);
  const [rec, setRec] = useState<string>('');

  const columns = [
    {
      Header: 'Waktu Panggilan',
      accessor: 'created_at',
      Cell: ({ value }: { value: Date }) => {
        if (value) {
          return dayjs(value).format('DD MMMM YYYY, HH:MM');
        }
        return '-';
      },
    },
    {
      Header: 'Durasi',
      accessor: 'duration',
      Cell: ({ value }: { value: string }) => {
        if (value) {
          return minutesConverter(Number(value));
        }
        return '-';
      },
    },
    {
      Header: '',
      accessor: 'download_link',
      Cell: ({ value }: { value: string }) => {
        if (!value) {
          return '-';
        }

        return (
          <Button
            variant="ghost"
            size="md"
            isBlock
            onClick={() => {
              if (rec && rec === value) {
                setRec('');
              } else {
                setRec(value);
              }
            }}
          >
            {rec && rec === value ?
              <>
                <span className="icon ion-ios-square" />
                <span>Selesai</span>
              </>
            :
              <>
                <span className="icon ion-ios-play" />
                <span>Dengarkan</span>
              </>
            }
          </Button>
        );
      },
    }
  ];

  const getRecordings = async () => {
    await apiClient('get', 'leads', `/v1/call-record/phone-number/${phoneNumber}`).then((res) => {
      const { data } = res;
      const rec = data.map((recs: RecordTypes) => {
        const orderResults = Object.keys(recs).map((name: string) => {
          return {
            name,
            value: recs[name as keyof typeof recs],
          };
        });

        return orderResults;
      });
      setRecord(rec);
    });
  };

  useEffect(() => {
    if (open === 'record') {
      getRecordings();
    }
  }, [open, leadId]);

  return (
    <Modal
      open={open === 'record'}
      onCloseModal={() => { setOpen(undefined); }}
      className={{ header: 'justify-start border-none p-none' }}
      title={phoneNumber}
    >
      <div className='modal-table-content'>
        <Table
          columns={columns}
          data={records}
        />
      </div>
      <Pagination
        totalPages={1}
        currentPage={1}
        handlePagination={()=>{
          return;
        }}
      />
      {rec &&
        <div className='play-audio'>
          <audio src={rec} controls autoPlay />
        </div>
      }
    </Modal>
  );
};
