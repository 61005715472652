import React, { ReactNode } from 'react';
import { createSearchParams, useLocation } from 'react-router-dom';
import { useAuthContext } from 'providers/AuthProvider';
import { useMemberLayoutContext } from 'components/Layout/MemberLayout';
import './Menu.scss';

interface MenuTypes {
  label: string;
  path?: string;
  status?: string;
  icon?: ReactNode;
  badges?: number;
}

interface NavigationProps {
  menus: MenuTypes[];
}

export const Navigation = (props: NavigationProps) => {
  const { menus } = props;
  const { query, navigate } = useAuthContext();
  const location = useLocation();
  const { open } = useMemberLayoutContext();

  return (
    <div className={`navigation-list p-vert-3 ${open && 'd-flex'}`}>
      {menus && menus.map((menu, idx) => {
        const activeMenu = location.pathname === menu.path && query.includes(menu.status);

        return (
          <div
            className={`
              p-vert-1-half p-horz-3 select-list pointer ${(activeMenu) && 'active'}`}
            key={menu.label+menu.path}
            onClick={() => {
              if (menu.path && menu.status) {
                navigate({
                  pathname: menu.path,
                  search: `${createSearchParams({
                    status: menu.status,
                  })}`,
                });
              }
            }}
          >
            {menu.icon}
            <span className={`${menu.path && 'hover:font-bold'} ${menu.icon ? 'ml-3' : 'ml-10' } ${activeMenu && 'font-bold'}`}>{menu.label}</span>
            {menu.badges !== null && <div className='badges ml-2.5'>{menu.badges}</div>}
          </div>
        );
      })}
    </div>
  );
};
