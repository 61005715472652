import React from 'react';
import { Selected } from "components"
import { useReviewDetailContext } from 'containers/review/Detail';
import { PolicyMembersPersonType } from 'containers/review/types';
import { ReasonType } from 'types/quotation';
import { SelectedSuspendReasonType } from 'types/review';

interface SuspendReasonButtonProps {
  suspendReasonGroup: string;
  personType?: PolicyMembersPersonType;
  section?: PolicyMembersPersonType | 'Deklarasi';
  relation?: string;
  value?: string;
}

export const SuspendReasonButton = (props: SuspendReasonButtonProps) => {
  const { suspendReasonGroup, personType, section, value, relation } = props;
  const { suspendReasons, setSelectedSuspendReasons, selectedSuspendReasons } = useReviewDetailContext();

  const validateSuspendReasonFilter = (reason: ReasonType) => {
    const reasonGroupKey = reason.suspendReasonGroup.toLowerCase();

    if (reasonGroupKey !== suspendReasonGroup) {
      return false;
    }

    const isKeyPresentOnGroupMessage = new RegExp(personType, "i").test(reason.message);

    if (!isKeyPresentOnGroupMessage) {
      return false;
    }

    if (personType === "Ahli Waris" || personType === "Tertanggung") {
      const regexContainsRelation = new RegExp(`${relation}`, 'i');
      const relationIncludesInMessage = regexContainsRelation.test(reason.message);

      if (!relation) {
        const regExp = /DP|SP/i;
        return !regExp.test(reason.message);
      } else {
        return relationIncludesInMessage;
      }
    }

    return true;
  };

  const options = suspendReasons
    .filter((reason: ReasonType) => validateSuspendReasonFilter(reason))
    .map((res: ReasonType) => ({
      key: res.message,
      value: res.id
    }));

  if (options.length <= 0) return null;

  return (
    <div className="my-2">
      <Selected
        label='Suspend'
        items={options}
        selected={selectedSuspendReasons}
        onChange={(selects: SelectedSuspendReasonType[]) => setSelectedSuspendReasons(selects)}
        section={section}
        value={value}
      />
    </div>
  );
}
