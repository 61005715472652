export enum QuotesStage {
  CREATE_QUOTES = "CREATE_QUOTES",
  POLICY_INFO = "POLICY_INFO",
  DECLARATION = "DECLARATION",
  BUYER_INFO = "BUYER_INFO",
  CREATE_ORDER = "CREATE_ORDER",
}

export enum QuotesStatus {
  UNKNOWN_QUOTES_STATUS = "UNKNOWN_QUOTES_STATUS",
  PENDING_QA = "PENDING_QA",
  REVISE = "REVISE",
  ACTIVE = "ACTIVE",
  SUSPEND = "SUSPEND",
  CANCEL = "CANCEL",
  DRAFT = "DRAFT",
}

export enum QaAgentFilter {
  ALL = 'ALL',
  QA_AGENT = 'QA_AGENT',
  NO_QA_AGENT = 'NO_QA_AGENT',
}