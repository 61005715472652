import React from 'react';
import { format } from 'date-fns';
import { Button } from '@lifepal/lifepal-design-system';
import { SearchInput, DropDown, DatePicker } from 'components';
import 'containers/review/Review.scss';
import { useReviewContext } from './ReviewContext';
import { QaAgentFilter } from 'constants/quotation.enum';

export const ReviewFilter = () => {
  const { changeParams, params } = useReviewContext();

  const handleSearch = (search: string) => {
    const regExp = /^LP-[\d]{12}/;
    if (regExp.test(search)) {
      changeParams({
        humanId: search,
        searchByBuyer: null,
      });
      return;
    }

    changeParams({
      humanId: null,
      searchByBuyer: search,
    });
  };

  const getDateFromRange = (startDay: number, endDay: number) => {
    const today = new Date();
    const fromDate = format(new Date(new Date().setDate(today.getDate() - startDay)), "yyyy-MM-dd");
    const toDate = format(new Date(new Date().setDate(today.getDate() - endDay)), "yyyy-MM-dd");

    return {
      fromDate,
      toDate,
    };
  }
  const handleDateFilter = (startDay: number, endDay: number) => {
    const today = new Date();
    const fromDate = format(new Date(new Date().setDate(today.getDate() - startDay)), "yyyy-MM-dd");
    const toDate = format(new Date(new Date().setDate(today.getDate() - endDay)), "yyyy-MM-dd");

    changeParams(getDateFromRange(startDay, endDay));
  }

  const isDateFilterActive = (startDay: number, endDay: number) => {
    const dates = getDateFromRange(startDay, endDay);
    return dates.fromDate === params.fromDate && dates.toDate === params.toDate;
  }

  return (
    <div>
      <div className='search-filter mt-8 mb-2 p-horz-2-rem'>
        <SearchInput
          placeholder='Cari: order ID atau nama pembeli'
          onChange={(text) => handleSearch(text)}
        />
        <div className='dropdown-group flex'>
          <DropDown
            options={[
              {
                label: 'Semua',
                value: QaAgentFilter.ALL,
              },
              {
                label: 'Order Saya',
                value: QaAgentFilter.QA_AGENT,
              },
              {
                label: 'Belum ada QA agent',
                value: QaAgentFilter.NO_QA_AGENT,
              },
            ]}
            value={params.qaAgent}
            onChange={(thisOption: QaAgentFilter) => {
              changeParams({
                qaAgent: thisOption,
              })
            }}
          />
          <DropDown
            options={[
              {
                label: 'Dari yang terlama',
                value: 'ASC',
              },
              {
                label: 'Dari yang terbaru',
                value: 'DESC',
              }
            ]}
            value={params.sortBy}
            onChange={(thisOption: 'ASC' | 'DESC') => {
              changeParams({
                sortBy: thisOption,
              })
            }}
          />
        </div>
      </div>
      <div className='filter-date my-8 p-horz-2-rem flex justify-between'>
        <div className='flex'>
          <div className='select-date'>
            <span>Dari:</span>
            <div className='date-input'>
              <DatePicker
                value={new Date(params.fromDate)}
                onChange={(thisDate: Date) => {
                  changeParams({
                    fromDate: format(thisDate, 'yyyy-MM-dd')
                  })
                }}
              />
            </div>
          </div>
          <div className='select-date ml-5'>
            <span>Sampai:</span>
            <div className='date-input'>
              <DatePicker
                value={new Date(params.toDate)}
                minDate={new Date(params.fromDate)}
                onChange={(thisDate: Date) => {
                  changeParams({
                    toDate: format(thisDate, 'yyyy-MM-dd')
                  })
                }}
              />
            </div>
          </div>
        </div>

        <div className='flex flex-center ml-5'>
          <Button
            className={`p-2.5 m-2 pointer rounded-lg ${isDateFilterActive(1, 0) ? 'bg-brand-primary-color text-white' : 'bg-slate-300'}`}
            onClick={() => handleDateFilter(1, 0)}
          >
            Kurang dari 2 hari
          </Button>
          <Button
            className={`p-2.5 m-2 pointer rounded-lg ${isDateFilterActive(3, 2) ? 'bg-brand-primary-color text-white' : 'bg-slate-300'}`}
            onClick={() => handleDateFilter(3, 2)}
          >
            2 - 3 hari
          </Button>
          <Button
            className={`p-2.5 m-2 pointer rounded-lg ${isDateFilterActive(7, 4) ? 'bg-brand-primary-color text-white' : 'bg-slate-300'}`}
            onClick={() => handleDateFilter(7, 4)}
          >
            4 - 7 hari
          </Button>
          <Button
            className={`p-2.5 m-2 pointer rounded-lg ${isDateFilterActive(30, 8) ? 'bg-brand-primary-color text-white' : 'bg-slate-300'}`}
            onClick={() => handleDateFilter(30, 8)}
          >
            Lebih dari 7 hari
          </Button>
        </div>
      </div>
    </div>
  )
}
