import React from 'react';
import { format } from 'date-fns';
import { useReviewDetailContext } from '../../Detail';
import { AddressInput, DateEditableDetail, DropDown, EditableDetail, Input, RegencyInput, TextEditableDetail } from 'components';
import { DetailBox } from "containers/review/components/Detail/components/DetailBox";
import { PolicyMembersTypes, PolicyMembersPersonType, CreatePolicyMembers, InsuredItemTypes } from '../../types';
import { Citizenships, IdentificationType, PolicyMembereType, genderEnum, maritalStatusEnum, monthlyIncomeEnum, sourceOfFundEnum } from 'utils/Enums';
import { PersonTypes } from 'types/person';
import { saveOrUpdatePersons } from 'services/persons';
import { UpdatePersonParamsTypes } from 'services/persons/types';
import { SuspendReasonButton } from './components/SuspendReasonButton';
import { toast } from 'react-toastify';
import { updatePolicyMembersMutation } from 'services/quotations';
import { UploadInput } from 'components/UploadInput';

interface PersonProps {
  person: PersonTypes;
  loading: boolean;
  policyMember?: PolicyMembersTypes;
  isCompany?: boolean;
  showAddress?: boolean;
  personType: PolicyMembersPersonType | 'Pembeli';
}

export const Person = (props: PersonProps) => {
  const { isCompany, policyMember, showAddress, personType, person } = props;
  const { getQuotation, quotation, quotationId } = useReviewDetailContext();

  const relation = policyMember?.relation;
  const [handleUpdatePerson] = saveOrUpdatePersons();
  const [updatePolicyMembers] = updatePolicyMembersMutation();

  const updatePerson = async <T extends keyof UpdatePersonParamsTypes>(field: T, value?: UpdatePersonParamsTypes[T], fieldName?: string) => {
    updatePersonFields(
      {
        [field]: value,
      },
      fieldName
    );
  };

  const updatePolicyMembersIdType = async (personId: string, idType: IdentificationType) => {
    const currentPolicyMembers = quotation.policyMembers;

    let changed = false;
    let quoInsuredItem: string;
    const policyMembers = currentPolicyMembers.map(pm => {
      const insuredItem = { ...pm.insuredItem };

      const policyMemberNew: CreatePolicyMembers = {
        id: pm.id,
        personId: pm?.person?.id,
        idType: pm.idType as IdentificationType,
        insuredItem: Object.values(insuredItem).length ? JSON.stringify(insuredItem) : null,
        isChildren: pm.isChildren,
        percentageBeneficiary: pm.percentageBeneficiary,
        relation: pm.relation,
        type: pm.type,
      };

      if (policyMemberNew.type === PolicyMembereType.INSURED) {
        quoInsuredItem = policyMemberNew.insuredItem;
      }

      if (policyMemberNew.personId === personId && policyMemberNew.idType !== idType && policyMember.type === policyMemberNew.type) {
        changed = true;
        policyMemberNew.idType = idType;
      }

      return policyMemberNew;
    });

    if (!changed) {
      return;
    }

    try {
      const { errors } = await updatePolicyMembers({
        variables: {
          quotationId,
          policyMembers,
          declarationType: quotation.declarationType,
          declaration: quotation.declaration,
          category: quotation.category,
          buyerId: quotation.buyer.id,
          leadId: quotation.leadId,
          planId: quotation.planId,
          productId: quotation.productId,
          insuredItem: {
            type: PolicyMembereType.INSURED,
            data: quoInsuredItem,
          },
        }
      });

      if (errors) {
        throw errors;
      }
    } catch (err) {
      toast.error(`Gagal mengubah type Id`);
    }
  }

  const updatePersonFields = async <T extends keyof UpdatePersonParamsTypes>(fields: Partial<UpdatePersonParamsTypes>, fieldName?: string) => {
    const updatePersonObject: UpdatePersonParamsTypes = {
      personId: person.id,
      fullName: person.fullName,
      gender: person.gender,
      dob: person.dob,
      ...fields
    };

    try {
      await handleUpdatePerson({
        variables: {
          ...updatePersonObject
        }
      });

      getQuotation();
    } catch (error) {
      toast.error(`Gagal mengubah ${fieldName}`);
    }
  };


  return (
    <DetailBox
      className='mb-7'
      title={`${personType}${!relation ? '' : ` - ${relation}`}`}
      subTitle={person && person.fullName || ''}
    >
      <div>
        <TextEditableDetail
          label={isCompany ? 'Nama Perusahaan' : 'Nama Lengkap'}
          value={person?.fullName}
          onSubmit={({ value }) => {
            updatePerson('fullName', value, isCompany ? 'Nama Perusahaan' : 'Nama Lengkap');
          }}
        />
        <SuspendReasonButton
          suspendReasonGroup='full_name'
          personType={personType}
          section={personType}
          relation={relation}
          value={person?.fullName}
        />
      </div>
      <div>
        <p className='text-z-14 mt-5'><b>{isCompany ? 'Nomor Telepon PIC' : 'Nomor Telepon'}</b></p>
        <span className='text-z-14 text-w-400 text-dark-grey'>
          +62{person && person.phoneNumber || '-'}
        </span>
        <SuspendReasonButton
          suspendReasonGroup='phone_number'
          personType={personType}
          section={personType}
          relation={relation}
          value={person.phoneNumber}
        />
      </div>
      {!isCompany && (
        <>
          <div>
            <EditableDetail
              label='Jenis Kelamin'
              value={person.gender}
              renderValue={(val) => {
                return genderEnum[val] || '';
              }}
              renderInput={({ onChange, value }) => {
                return (
                  <DropDown
                    id='gender-input'
                    value={value}
                    onChange={onChange}
                    options={[
                      {
                        label: genderEnum['MALE'],
                        value: 'MALE',
                      },
                      {
                        label: genderEnum['FEMALE'],
                        value: 'FEMALE',
                      }
                    ]}
                    className='z-10'
                  />
                );
              }}
              onSubmit={({ value }) => {
                updatePerson('gender', value, 'Jenis Kelamin');
              }}
            />
            <SuspendReasonButton
              suspendReasonGroup='gender'
              personType={personType}
              section={personType}
              relation={relation}
              value={person.gender}
            />
          </div>
          <div>
            <DateEditableDetail
              label='Tanggal Lahir'
              value={new Date(person?.dob)}
              onSubmit={({ value }) => {
                updatePerson('dob', format(value, 'yyyy-MM-dd'));
              }}
              renderValue={(value) => {
                return format(value, 'dd MMMM yyyy')
              }}
            />
            <SuspendReasonButton
              suspendReasonGroup='Tanggal Lahir'
              personType={personType}
              section={personType}
              relation={relation}
              value={person.dob}
            />
          </div>
        </>
      )}

      <div>
        <EditableDetail
          label='Kota Lahir'
          value={person.birthPlace}
          renderInput={({ onChange, value }) => {
            return (
              <RegencyInput
                defaultValue={value}
                onChange={(regency) => {
                  onChange(regency.name)
                }}
              />
            )
          }}
          onSubmit={({ value }) => {
            updatePerson('birthPlace', value);
          }}
        />
        <SuspendReasonButton
          suspendReasonGroup='birth_place'
          personType={personType}
          section={personType}
          relation={relation}
          value={person.birthPlace}
        />
      </div>
      <div>
        <EditableDetail
          label='Kewarganegaraan'
          value={person.citizenship}
          renderInput={({ onChange, value }) => {
            return (
              <div className='flex'>
                {Object.keys(Citizenships).map((item) => (
                  <div className='flex' key={`declaration-radio-${item}`}>
                    <input
                      type="radio"
                      id={`declaration-radio-${item}`}
                      name="citizenship"
                      onChange={() => onChange(item)}
                      checked={value === item}
                    />
                    <label
                      htmlFor={`declaration-radio-${item}`}
                      className="text-sm ml-2 mr-4">{Citizenships[item as keyof typeof Citizenships]}</label>
                  </div>
                ))}
              </div>
            );
          }}
          onSubmit={({ value }) => {
            updatePerson('citizenship', value);
          }}
        />

        <SuspendReasonButton
          suspendReasonGroup='citizenship'
          personType={personType}
          section={personType}
          relation={relation}
          value={person.citizenship}
        />
      </div>
      <div>
        <TextEditableDetail
          label={isCompany ? 'Email PIC' : 'Email'}
          value={person?.email}
          onSubmit={({ value }) => {
            updatePerson('email', value, isCompany ? 'Email PIC' : 'Email');
          }}
        />
        <SuspendReasonButton
          suspendReasonGroup='email'
          personType={personType}
          section={personType}
          relation={relation}
          value={person.email}
        />
      </div>
      <div>
        <EditableDetail
          label='Status Perkawinan'
          value={person.maritalStatus}
          renderValue={(value) => {
            return maritalStatusEnum[value];
          }}
          renderInput={({ value, onChange }) => {
            return (
              <DropDown
                id='marital-input'
                value={value}
                onChange={onChange}
                options={Object.keys(maritalStatusEnum).map(maritalKey => {
                  return {
                    value: maritalKey,
                    label: maritalStatusEnum[maritalKey]
                  }
                })}
              />
            )
          }}
          onSubmit={({ value }) => {
            updatePerson('maritalStatus', value, `Status Perkawinan`);
          }}
        />
        <SuspendReasonButton
          suspendReasonGroup='marital_status'
          personType={personType}
          section={personType}
          relation={relation}
          value={person.maritalStatus}
        />
      </div>
      {showAddress && (
        <>
          <div>
            <TextEditableDetail
              label='Alamat Lengkap'
              value={person?.address}
              onSubmit={({ value }) => {
                updatePerson('address', value);
              }}
            />
            <SuspendReasonButton
              suspendReasonGroup='address'
              personType={personType}
              section={personType}
              relation={relation}
              value={person.address}
            />
          </div>
          <AddressInput
            defaultValues={{
              province: person.province,
              city: person.city,
              district: person.district,
              subDistrict: person.subDistrict,
              postalCode: person.postalCode
            }}
          >
            {({ inputs, values, reset }) => {
              const ProvinceInput = inputs.province;
              const CityInput = inputs.city;
              const DisctrictInput = inputs.district;
              const SubDisctrictInput = inputs.subDistrict;
              const PostalCodeInput = inputs.postalCode;

              const updateAddress = (fieldName: string) => {
                // clone object, to make it as non state object
                updatePersonFields({
                  ...values
                }, fieldName);
              }

              return (
                <>
                  <div>
                    <EditableDetail
                      label='Provinsi'
                      value={values.province}
                      renderInput={() => {
                        return (
                          <ProvinceInput />
                        );
                      }}
                      onCancel={reset}
                      submitOnlyChange={false}
                      onSubmit={() => updateAddress('Provinsi')}
                    />
                    <SuspendReasonButton
                      suspendReasonGroup='province'
                      personType={personType}
                      section={personType}
                      relation={relation}
                      value={person.province}
                    />
                  </div>
                  <div>
                    <EditableDetail
                      label='Kota'
                      value={values.city}
                      renderInput={() => {
                        return (
                          <CityInput />
                        );
                      }}
                      onCancel={reset}
                      submitOnlyChange={false}
                      onSubmit={() => updateAddress('Kota')}
                    />
                    <SuspendReasonButton
                      suspendReasonGroup='city'
                      personType={personType}
                      section={personType}
                      relation={relation}
                      value={person.city}
                    />
                  </div>
                  <div>
                    <EditableDetail
                      label='Kecamatan'
                      value={values.district}
                      renderInput={() => {
                        return (
                          <DisctrictInput />
                        );
                      }}
                      onCancel={reset}
                      submitOnlyChange={false}
                      onSubmit={() => updateAddress('Kecamatan')}
                    />
                    <SuspendReasonButton
                      suspendReasonGroup='district'
                      personType={personType}
                      section={personType}
                      relation={relation}
                      value={person.district}
                    />
                  </div>
                  <div>
                    <EditableDetail
                      label='Kelurahan'
                      value={values.subDistrict}
                      renderInput={() => {
                        return (
                          <SubDisctrictInput />
                        );
                      }}
                      onCancel={reset}
                      submitOnlyChange={false}
                      onSubmit={() => updateAddress('Kelurahan')}
                    />
                    <SuspendReasonButton
                      suspendReasonGroup='sub_district'
                      personType={personType}
                      section={personType}
                      relation={relation}
                      value={person.subDistrict}
                    />
                  </div>
                  <div>
                    <EditableDetail
                      label='Kode Pos'
                      value={values.postalCode}
                      renderInput={() => {
                        return (
                          <PostalCodeInput />
                        );
                      }}
                      onCancel={reset}
                      submitOnlyChange={false}
                      onSubmit={() => updateAddress('Kode Pos')}
                    />
                    <SuspendReasonButton
                      suspendReasonGroup='postal_code'
                      personType={personType}
                      section={personType}
                      relation={relation}
                      value={person.postalCode}
                    />
                  </div>
                </>
              )
            }}
          </AddressInput>
          <div>
            <TextEditableDetail
              label={'RT'}
              value={person.rt}
              onSubmit={({ value }) => {
                updatePerson('rt', value, 'RT');
              }}
            />
            <SuspendReasonButton
              suspendReasonGroup='rt'
              personType={personType}
              section={personType}
              relation={relation}
              value={person.rt}
            />
          </div>
          <div>
            <TextEditableDetail
              label={'RW'}
              value={person.rw}
              onSubmit={({ value }) => {
                updatePerson('rw', value, 'RW');
              }}
            />
            <SuspendReasonButton
              suspendReasonGroup='rw'
              personType={personType}
              section={personType}
              relation={relation}
              value={person.rw}
            />
          </div>
        </>
      )}

      {
        personType != 'Pembeli' && (
          <div>
            <EditableDetail
              label='Tipe Identitas'
              renderValue={(value) => {
                if (value !== IdentificationType.UNKNOWN_ID_CARD_TYPE) {
                  return value;
                }

                return 'Tipe Identitas Belum dipilih';
              }}
              value={policyMember.idType as IdentificationType}
              renderInput={({ onChange, value }) => {
                return (
                  <>
                    <DropDown
                      id='id-input'
                      value={value}
                      onChange={onChange}
                      options={Object.values(IdentificationType)
                        .filter(value => value !== IdentificationType.UNKNOWN_ID_CARD_TYPE)
                        .map(id => {
                          return {
                            label: id,
                            value: id,
                          }
                        })}
                      className='z-10'
                    />
                  </>
                );
              }}
              onSubmit={async ({ value }) => {
                /** for holder only update policyMember.typeId. beneficiary and insured will update person.typeId*/
                await updatePolicyMembersIdType(person.id, value);
                if ( policyMember.type !== PolicyMembereType.HOLDER) {
                  updatePerson('idCardType', value, 'Tipe Identitas');
                } else {
                  getQuotation();
                }
              }}
            />
          </div>
        )
      }
      {
        personType != 'Pembeli' && (policyMember.idType === IdentificationType.KTP || policyMember.idType === IdentificationType.UNKNOWN_ID_CARD_TYPE) && (
          <div>
            <TextEditableDetail
              label={`No. KTP`}
              value={person.identityCardNumber}
              onSubmit={({ value }) => {
                updatePerson('identityCardNumber', value, 'No. KTP');
              }}
            />
            <SuspendReasonButton
              suspendReasonGroup={'identityCardNumber'}
              personType={personType}
              relation={relation}
              value={person.identityCardNumber}
            />
          </div>
        )
      }

      {
        personType != 'Pembeli' && (policyMember.idType === IdentificationType.KK || policyMember.idType === IdentificationType.UNKNOWN_ID_CARD_TYPE) && (
          <div>
            <TextEditableDetail
              label={`No. KK`}
              value={person.familyCardNumber}
              onSubmit={({ value }) => {
                updatePerson('familyCardNumber', value, 'No. KK');
              }}
            />
            <SuspendReasonButton
              suspendReasonGroup={'familyCardNumber'}
              personType={personType}
              relation={relation}
              value={person.familyCardNumber}
            />
          </div>
        )
      }

      {
        personType != 'Pembeli' && (policyMember.idType === IdentificationType.PASSPORT || policyMember.idType === IdentificationType.UNKNOWN_ID_CARD_TYPE) && (
          <div>
            <TextEditableDetail
              label={`No. Paspor`}
              value={person.passportNumber}
              onSubmit={({ value }) => {
                updatePerson('passportNumber', value, 'No. Paspor');
              }}
            />
            <SuspendReasonButton
              suspendReasonGroup={'passportNumber'}
              personType={personType}
              relation={relation}
              value={person.passportNumber}
            />
          </div>
        )
      }

      {
        personType != 'Pembeli' && (policyMember.idType === IdentificationType.NPWP || policyMember.idType === IdentificationType.UNKNOWN_ID_CARD_TYPE) && (
          <div>
            <TextEditableDetail
              label={`No. NPWP`}
              value={person.taxId}
              onSubmit={({ value }) => {
                updatePerson('taxId', value, 'No. NPWP');
              }}
            />
            <SuspendReasonButton
              suspendReasonGroup={'taxId'}
              personType={personType}
              relation={relation}
              value={person.taxId}
            />
          </div>
        )
      }

      {
        person.idType !== IdentificationType.NPWP && (
          <EditableDetail
            label='Foto Identitas'
            value={person.idCardFile}
            renderValue={(value) => {
              if (!value) {
                return (
                  <span className='flex items-center p-5 text-sm border border-slate-200 w-20 h-20 mt-2'>Tidak diisi.</span>
                )
              }
              return (
                <img alt='foto identitas' src={value} className='w-20 h-20 mt-2' />
              )
            }}
            onSubmit={({ value }) => {
              updatePerson('idCardFile', value, 'Foto Identitas');
            }}
            renderInput={({ onChange, value }) => {
              return (
                <UploadInput
                  quotationValue={value}
                  fieldName='id_card_file'
                  inputType='image'
                  openedItem={'id_card_file'}
                  onRemoveFile={() => {
                    onChange('')
                  }}
                  onSaveImage={(imagePath) => {
                    onChange(imagePath[0]);
                  }}
                />
              )
            }}
          />
        )
      }

      {
        (person.idType === IdentificationType.NPWP || person.idType === IdentificationType.UNKNOWN_ID_CARD_TYPE) && (
          <EditableDetail
            label='Foto NPWP'
            value={person.taxIdImage}
            renderValue={(value) => {
              if (!value) {
                return (
                  <span className='flex items-center p-5 text-sm border border-slate-200 w-20 h-20 mt-2'>Tidak diisi.</span>
                )
              }
              return (
                <img alt='foto identitas' src={value} className='w-20 h-20 mt-2' />
              )
            }}
            onSubmit={({ value }) => {
              updatePerson('taxIdImage', value, 'Foto NPWP');
            }}
            renderInput={({ onChange, value }) => {
              return (
                <UploadInput
                  quotationValue={value}
                  fieldName='tax_id_image'
                  inputType='image'
                  openedItem={'tax_id_image'}
                  onRemoveFile={() => {
                    onChange('')
                  }}
                  onSaveImage={(imagePath) => {
                    onChange(imagePath[0]);
                  }}
                />
              )
            }}
          />
        )
      }

      <EditableDetail
        label='Foto Dengan Identitas'
        value={person.idVerificationPicture}
        renderValue={(value) => {
          if (!value) {
            return (
              <div className='flex items-center p-5 text-sm border border-slate-200 w-20 h-20 mt-2'>Tidak diisi.</div>
            )
          }
          return (
            <img alt='foto gengan identitas' src={value} className='w-20 h-20 mt-2' />
          )
        }}
        onSubmit={({ value }) => {
          updatePerson('idVerificationPicture', value, 'Foto Dengan Identitas');
        }}
        renderInput={({ onChange, value }) => {
          return (
            <UploadInput
              quotationValue={value}
              fieldName='id_verification_picture'
              inputType='image'
              openedItem={'id_verification_picture'}
              onRemoveFile={() => {
                onChange('')
              }}
              onSaveImage={(imagePath) => {
                onChange(imagePath[0]);
              }}
            />
          )
        }}
      />

      {(policyMember && personType === 'Ahli Waris') && (
        <>
          <div>
            <div className='text-z-14 mt-5 d-flex align-center'>
              <b>Relasi</b>
            </div>
            <span className='text-z-14 text-w-400 text-dark-grey'>
              {relation}
            </span>
            <SuspendReasonButton
              suspendReasonGroup='relation'
              personType={personType}
              section={personType}
              relation={relation}
              value={relation}
            />
          </div>
          <div>
            <div className='text-z-14 mt-5 d-flex align-center'>
              <b>Persentase Pembagian Manfaat</b>
            </div>
            <span className='text-z-14 text-w-400 text-dark-grey'>
              {policyMember.percentageBeneficiary} %
            </span>
            <SuspendReasonButton
              suspendReasonGroup='percentage_beneficiary'
              personType={personType}
              section={personType}
              relation={relation}
              value={policyMember.percentageBeneficiary}
            />
          </div>
        </>
      )}

      {
        (personType === 'Pembeli' || personType === 'Pemegang Polis') && (
          <>
            <div>
              <EditableDetail
                label='Pendapatan Bulanan'
                value={person.monthlyIncome}
                renderValue={(val) => {
                  return monthlyIncomeEnum[val];
                }}
                onSubmit={({ value }) => {
                  updatePerson('monthlyIncome', value, 'Pendapatan Bulanan');
                }}
                renderInput={({ onChange, value }) => {
                  return (
                    <DropDown
                      id='income-input'
                      value={value}
                      onChange={onChange}
                      options={
                        Object.keys(monthlyIncomeEnum).map(key => {
                          return {
                            label: monthlyIncomeEnum[key],
                            value: key,
                          };
                        })
                      }
                    />
                  );
                }}
              />
              <SuspendReasonButton
                suspendReasonGroup='monthly_income'
                personType={personType}
                section={personType}
                relation={relation}
                value={person.monthlyIncome}
              />
            </div>

            <div>
              <EditableDetail
                label='Sumber Pendapatan'
                value={person.sourceOfFund}
                renderValue={(val) => {
                  return sourceOfFundEnum[val];
                }}
                onSubmit={({ value }) => {
                  updatePerson('sourceOfFund', value, 'Sumber Pendapatan');
                }}
                renderInput={({ onChange, value }) => {
                  return (
                    <DropDown
                      id='fund-source-input'
                      value={value}
                      onChange={onChange}
                      options={
                        Object.keys(sourceOfFundEnum).map(key => {
                          return {
                            label: sourceOfFundEnum[key],
                            value: key,
                          };
                        })
                      }
                    />
                  );
                }}
              />
              <SuspendReasonButton
                suspendReasonGroup='source_of_fund'
                personType={personType}
                section={personType}
                relation={relation}
                value={person.monthlyIncome}
              />
            </div>
          </>
        )
      }
    </DetailBox>
  );
};
