import React from 'react';
import { DetailBox } from "containers/review/components/Detail/components/DetailBox";
import { DetailTypes } from '../../types';
import { SuspendReasonButton } from './components/SuspendReasonButton';

interface InsuredProps {
  loading: boolean;
  details: DetailTypes;
}

export const Insured = (props: InsuredProps) => {
  const { details } = props;

  return (
    <DetailBox className='mb-7' title='Spesifikasi'>
      <p className='text-z-14 text-dark-light text-w-500'>Plat Kendaraan</p>
      <span className='text-z-14 text-w-400 text-dark-grey'>
        {details.insured && details.insured.insuredItem && details.insured.insuredItem.area || '-'}
      </span>
      <div className='text-z-14 text-dark-grey text-bold mt-5 d-flex align-center'>
        Spesifikasi Mobil
      </div>
      <p className='text-z-14 text-dark-light text-w-500 mt-5'>Merk Mobil</p>
      <span className='text-z-14 text-w-400 text-dark-grey'>
        {details.insured && details.insured.insuredItem && details.insured.insuredItem.brand || '-'}
      </span>
      <p className='text-z-14 text-dark-light text-w-500 mt-5'>Model Mobil</p>
      <span className='text-z-14 text-w-400 text-dark-grey'>
        {details.insured && details.insured.insuredItem && details.insured.insuredItem.model || '-'}
      </span>
      <p className='text-z-14 text-dark-light text-w-500 mt-5'>Tipe Mobil</p>
      <span className='text-z-14 text-w-400 text-dark-grey'>
        {details.insured && details.insured.insuredItem && details.insured.insuredItem.type || '-'}
      </span>
      <SuspendReasonButton suspendReasonGroup='type' personType='Tertanggung' section='Tertanggung' />
      <p className='text-z-14 text-dark-light text-w-500 mt-5'>Tahun Pembuatan Mobil</p>
      <span className='text-z-14 text-w-400 text-dark-grey'>
        {details.insured && details.insured.insuredItem && details.insured.insuredItem.year || '-'}
      </span>
      <SuspendReasonButton suspendReasonGroup='year' personType='Tertanggung' section='Tertanggung' />
    </DetailBox>
  );
};
