import React from 'react';
import classNames from 'classnames';
import { Button } from '@lifepal/lifepal-design-system';
import { SelectedSuspendReasonType } from 'types/review';
import { trash } from 'utils/assets';

interface SuspendStatusProps {
  suspendReasons: SelectedSuspendReasonType[];
  onClearAll: () => void;
  disabled: boolean;
}

const SuspendStatus = (props: SuspendStatusProps) => {
  const { suspendReasons, onClearAll, disabled } = props;

  return (
    <div className='w-full py-3 px-8 flex justify-between align-center bg-red-400'>
      <div>
        <span className='text-md text-white'>{suspendReasons.length} Suspend Reason Selected</span>
      </div>
      <div>
        <Button 
          className={classNames('px-5 py-2 flex align-center bg-white text-md text-red-400 rounded-md font-bold', disabled ? 'bg-slate-200' : 'hover:bg-slate-100')}
          onClick={onClearAll}
          disabled={disabled}
        >
            <img src={trash} alt='x' className='w-[24px] h-[24px] mr-2.5' />
          <span>Clear All</span>
        </Button>
      </div>
    </div>
  );
}

export default SuspendStatus;
