import { Button } from '@lifepal/lifepal-design-system';
import React, { useState } from 'react';
import { useReviewDetailContext } from 'containers/review/Detail';
import { updateQuotesDeclarationMutation } from 'services/quotations';
import { toast } from 'react-toastify';

interface CopyableProps {
  closeModal: () => void;
}

export const CopyableDeclarations = (props:CopyableProps) => {
  const { order, declarationQuestions, quotationId, refetchData, setOpen } = useReviewDetailContext();
  const { closeModal} = props;
  const [loading, setLoading] = useState(false);
  const parentDeclarations: Record<string, string> = JSON.parse(order.parentOrder?.quotation.declaration);
  
  const [handleUpdateDeclarationMutation] = updateQuotesDeclarationMutation();
  const [selectedDeclarations, setSelectedDeclarations] = useState<Record<string, boolean> >({});
  const parentDeclarationsArray = Object.keys(parentDeclarations);
  const currentDeclarationFields = {};
  const currentDeclarationsArray: string[] = [];
  
  declarationQuestions.forEach((value) => {
    currentDeclarationFields[value.field_name] = value.name;
    currentDeclarationsArray.push(value.field_name);
  });

  const copyableDeclarations = currentDeclarationsArray.filter(value => {
    return parentDeclarationsArray.includes(value) && value !== 'polis_inforced' && value !== 'inforceddate';
  })
  
  const isDeclarationSelected = !!Object.values(selectedDeclarations).length;
  
  const handleSelectedDeclaration = (select: string, add: boolean) => { 
    setSelectedDeclarations(prev => {
      const prevData={...prev};
      if(add){
        prevData[select]=true;
      } else {
        delete prevData[select];
      } 
      return prevData;
    }); 
  }
  
  const handleSelectAllDeclaration = (selectAll: boolean) => { 
    setSelectedDeclarations({});
    if(selectAll){
      setSelectedDeclarations(Object.fromEntries(copyableDeclarations.map(e => [e, true])))
    }
  }
  
  const handleSubmitDeclaration = async () => {
    setLoading(true);
    try {
      const declaration = Object.keys(selectedDeclarations).reduce((declaration, declarationKey) =>{
        declaration[declarationKey]= parentDeclarations[declarationKey];
        return declaration;
      }, {});

      await handleUpdateDeclarationMutation({
        variables: {
          quotationId: quotationId,
          declaration: JSON.stringify(declaration),
        }
      });
      
      await refetchData();
      setOpen('success');
    } catch (error) {
      toast.error('Terjadi kesalahan');
    } finally {
      setLoading(false);
      closeModal();
    }
  }
    
  return (
    <>
      <div>
        <div>
          <input 
            type="checkbox" 
            className='w-fit h-fit mr-3 bg-[#F2F6FD] border-[#1D66DD]'
            key={'declaration-all'} 
            name={'declaration-all'} 
            id={'declaration-all'}
            checked={Object.keys(selectedDeclarations).length===copyableDeclarations.length} 
            onChange={(e) => handleSelectAllDeclaration(e.target.checked)}
          />
          <label htmlFor={'declaration-all'}>Select All</label>
        </div>
        {
          copyableDeclarations.map((declaration) => (
            <div className='flex align-center mt-[25px]' key={declaration}>
              <input type="checkbox" className='w-fit h-fit mr-3 bg-[#F2F6FD] border-[#1D66DD]'
                key={declaration} name={declaration} id={declaration}
                checked={selectedDeclarations[declaration]||false} 
                onChange={(e) => { 
                  handleSelectedDeclaration(declaration,e.target.checked);
                }}
              />
              <label htmlFor={declaration}>{currentDeclarationFields[declaration]}</label>
            </div>
          ))
        }
        <Button
          onClick={handleSubmitDeclaration}
          isBlock={true}
          disabled={!isDeclarationSelected || loading}
          className={`
            text-white py-4 mt-3 bg-brand-primary-color rounded hover:opacity-70 
            ${!isDeclarationSelected ? 'opacity-70' : ''}
          `}
        >
          Salin {Object.values(selectedDeclarations).length || ''} Deklarasi
        </Button>
      </div>
    </>
  );
}