import React, { createContext, useContext, useEffect, useState } from 'react'
import { SelectItem, SelectRadioGroupContextProps, SelectRadioGroupProps } from './types';

const SelectRadioGroupContext = createContext({} as SelectRadioGroupContextProps);
export const useSelectRadioGroupContext = () => useContext(SelectRadioGroupContext);

export const SelectRadioGroup = (props: SelectRadioGroupProps) => {
  const [activeId, setActiveId] = useState<string>();
  const [items, setItems] = useState<SelectItem[]>(props.items);
  const itemKeys = items.reduce((r, item)=>{
    r[item.value] = item;
    return r;
  },{});

  const [selectedItem, setSelectedItem] = useState<Record<SelectItem['value'], SelectItem>>({});
  // only to create incremenal number
  let id = 0;

  useEffect(()=>{
    const defaultSelected = props.selectedItems.reduce((r, val)=>{
      r[val] = itemKeys[val];
      return r;
    },{});

    setSelectedItem(defaultSelected);
  },[props.selectedItems])
  
  useEffect(()=>{
    const selectedValues = Object.keys(selectedItem);
    if(!selectedValues.length) {
      setItems(props.items);
      return;
    }

    const currentItems = props.items.map(item => {
      return {
        ...item,
        disabled: selectedValues.includes(item.value) ,
      } as SelectItem;
    });

    setItems(currentItems);
  }, [selectedItem]);

  const getUniqueId = () => {
    id = id + 1;
    return id.toString()
  }

  const registerSelectedItem = (item: SelectItem, previous: SelectItem) => {
    if (!props.items) {
      return;
    }
    
    setSelectedItem((prev) => {
      const prevData = { ... prev};
      if (previous) {
        delete prevData[previous.value];
      }

      const sel = {
        ...prevData,
        [item.value]: item
      }
      return sel;
    });
  }

  const removeSelectedItem = (item: SelectItem) => {
    if (!props.items) {
      return;
    }

    setSelectedItem((prev) => {
      const prevData = { ... prev};
      delete prevData[item.value];
      return {
        ...prevData
      }
    });
  }

  return (
    <SelectRadioGroupContext.Provider
      value={{
        items,
        activeId,
        setActiveId,
        getUniqueId,
        registerSelectedItem,
        removeSelectedItem,
      }}
    >
      {props.children}
    </SelectRadioGroupContext.Provider>
  )
}
