import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { useAddressInputContext } from './AddressInput';
import type { PostalCode } from '../../services/address';

interface PostalCodeOption {
  label: string;
  value: PostalCode;
}

export const PostalCodeInput = () => {
  const { data, defaultValues, changePostalCode } = useAddressInputContext();
  const [options, setOptions] = useState<PostalCodeOption[]>([]);
  const [value, setValue] = useState<PostalCodeOption>();

  useEffect(()=>{
    const opts = data.postalCodes.map(postalCode => {
      return {
        value: postalCode,
        label: postalCode.postalCode.toString()
      }
    });

    setOptions(opts);
    const defaultValue = opts.find(postalCode => postalCode.label === defaultValues.postalCode);
    setValue(defaultValue);
  }, [data.postalCodes, defaultValues.postalCode]);

  const onChange = (data: PostalCodeOption) => {
    setValue(data);
    changePostalCode(data.value);
  }

  return (
    <Select<PostalCodeOption>
      options={options}
      value={value}
      onChange={onChange}
      isSearchable
    />
  )
}