// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkbox {
  width: 12px;
  height: 12px;
  border-radius: 4px;
  position: relative;
}
.checkbox .checkbox-border {
  position: absolute;
  left: -1px;
  top: -1px;
  right: -1px;
  bottom: -1px;
  border-radius: 4px;
  border: 2px solid #EF6767;
  background: transparent;
}
.checkbox input {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  outline: none !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Selected/Selected.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;AACF;AAAE;EACE,kBAAA;EACA,UAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,yBAAA;EACA,uBAAA;AAEJ;AAAE;EACE,kBAAA;EACA,SAAA;EACA,QAAA;EACA,UAAA;EACA,WAAA;EACA,wBAAA;AAEJ","sourcesContent":[".checkbox {\n  width: 12px;\n  height: 12px;\n  border-radius: 4px;\n  position: relative;\n  .checkbox-border {\n    position: absolute;\n    left: -1px;\n    top: -1px;\n    right: -1px;\n    bottom: -1px;\n    border-radius: 4px;\n    border: 2px solid #EF6767;\n    background: transparent;\n  }\n  input {\n    position: absolute;\n    left: 0px;\n    top: 0px;\n    right: 0px;\n    bottom: 0px;\n    outline: none !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
