// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Wrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
}
.Wrapper input[type=checkbox] {
  cursor: pointer;
  position: absolute;
  z-index: 3;
  width: 16px;
  height: 16px;
  opacity: 0;
}
.Wrapper input[type=checkbox]:checked + .Label::after {
  content: "\\f2bc";
}

.Label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}
.Label::after {
  font-family: "Ionicons";
  content: "";
  position: absolute;
  left: 4px;
  top: -6px;
  font-size: 20px;
  color: #1d66dd;
}
.Label::before {
  content: "";
  padding: 0;
  width: 16px;
  height: 16px;
  display: inline-block;
  border: 1px solid #333333;
  border-radius: 2px;
  margin-right: 10px;
  vertical-align: middle;
}`, "",{"version":3,"sources":["webpack://./src/components/CheckBox/CheckBox.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;AACF;AACE;EACE,eAAA;EACA,kBAAA;EACA,UAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;AACJ;AAGQ;EACE,gBAAA;AADV;;AAQA;EACE,qBAAA;EACA,sBAAA;EACA,kBAAA;AALF;AAOE;EACE,uBAAA;EACA,WAAA;EACA,kBAAA;EACA,SAAA;EACA,SAAA;EACA,eAAA;EACA,cAAA;AALJ;AAQE;EACE,WAAA;EACA,UAAA;EACA,WAAA;EACA,YAAA;EACA,qBAAA;EACA,yBAAA;EACA,kBAAA;EACA,kBAAA;EACA,sBAAA;AANJ","sourcesContent":[".Wrapper {\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  position: relative;\n\n  input[type=\"checkbox\"] {\n    cursor: pointer;\n    position: absolute;\n    z-index: 3;\n    width: 16px;\n    height: 16px;\n    opacity: 0;\n\n    &:checked {\n      + .Label {\n        &::after {\n          content: '';\n        }\n      }\n    }\n  }\n}\n\n.Label {\n  display: inline-block;\n  vertical-align: middle;\n  position: relative;\n\n  &::after {\n    font-family: 'Ionicons';\n    content: '';\n    position: absolute;\n    left: 4px;\n    top: -6px;\n    font-size: 20px;\n    color: #1d66dd;\n  }\n\n  &::before {\n    content: '';\n    padding: 0;\n    width: 16px;\n    height: 16px;\n    display: inline-block;\n    border: 1px solid #333333;\n    border-radius: 2px;\n    margin-right: 10px;\n    vertical-align: middle;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
