import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react'
import {lazyGetRegencies, lazyGetProvinces, lazyGetDistrict, lazyGetPostalCode, lazyGetVillage } from '../../services/address';
import type { Regency, Province, PostalCode, Village, District } from '../../services/address';
import { ProvinceInput } from './ProvinceInput';
import { RegencyInput } from './RegencyInput';
import { DisctrictInput } from './DistrictInput';
import { SubDisctrictInput } from './SubDisctrict';
import { PostalCodeInput } from './PostalCode';
import { toast } from 'react-toastify';

type AddressType = keyof Address;
interface ChildrenData {
  inputs: Record<AddressType, () => JSX.Element>
  values: Record<AddressType, string>,
  reset: () => void,
}

interface AddressInputProps {
  defaultValues?: Record<AddressType, string>;
  children: (data: ChildrenData) => ReactNode;
  onChange?: (address: Address) => void;
}

interface Address {
  province: Partial<Province>,
  city: Partial<Regency>,
  district: Partial<District>,
  subDistrict: Partial<Village>,
  postalCode: Partial<PostalCode>,
}

interface AddressInputContextProps extends AddressInputProps{
  data: {
    provinces: Province[],
    cities: Regency[],
    districts: District[],
    subDistricts: Village[]
    postalCodes: PostalCode[],
  };
  address: Address;
  changeProvince: (province: Province) => void;
  changeCity: (city: Regency) => void;
  changeDistrict: (district: District) => void;
  changeSubDistrict: (subDistrict: Village) => void;
  changePostalCode: (postalCode: PostalCode) => void;
}

const AddressInputContext = createContext<AddressInputContextProps>({} as AddressInputContextProps);
export const useAddressInputContext = () => useContext(AddressInputContext);

export const AddressInput = (props: AddressInputProps) => {
  const { children, defaultValues } = props;

  const [getProvinces, {data: provinces}] = lazyGetProvinces();
  const [getRegencies, {data: regencies}] = lazyGetRegencies();
  const [getDistricts, {data: districts}] = lazyGetDistrict();
  const [getSubDistricts, {data: subDistricts}] = lazyGetVillage();
  const [getPostalCodes, {data: postalCodes}] = lazyGetPostalCode();

  const defaultValue: Address = {
    province: {
      name: defaultValues?.province 
    },
    city: {
      name: defaultValues?.city,
    },
    district: {
      name: defaultValues?.district
    },
    subDistrict: {
      name: defaultValues?.subDistrict,
    },
    postalCode: {
      postalCode: Number(defaultValues?.postalCode),
    },
  }

  const [address, setAddress] = useState<Address>(defaultValue);

  useEffect(()=>{
    getProvinces().then( query => {
      if (query.error) {
        toast.error('Terjadi kesalahan');
      }

      if(!defaultValues.province) {
        return;
      }

      const province = query.data.getProvinces.find(province => province.name === defaultValues.province);
      if(province) {
        changeProvince(province)
      }
    });
  }, []);

  useEffect(()=>{
    if (!address?.province?.id) {
      return;
    }
    getRegencies({
      variables: {
        provinceId: address.province.id,
      }
    }).then(query => {
      if (query.error) {
        toast.error('Terjadi kesalahan');
      }

      if(!defaultValues.city) {
        return;
      }

      const city = query.data.getRegencies.find(city => city.name === defaultValues.city);
      if(city) {
        changeCity(city);
      }
    });
  }, [address.province.id]);

  useEffect(()=>{
    if (!address?.city?.id) {
      return;
    }
    getDistricts({
      variables: {
        regencyId: address.city.id,
      }
    }).then(query => {
      if (query.error) {
        toast.error('Terjadi kesalahan');
      }
      
      if(!defaultValues.district) {
        return;
      }

      const district = query.data.getDistricts.find(districts => districts.name === defaultValues.district);
      if (district) {
        changeDistrict(district);
      }
    });
  }, [address?.city?.id]);

  useEffect(()=>{
    if (!address?.district?.id) {
      return;
    }
    getSubDistricts({
      variables: {
        districtId: address.district.id,
      }
    }).then(query => {
      if (query.error) {
        toast.error('Terjadi kesalahan');
      }

      if(!defaultValues.subDistrict) {
        return;
      }

      const subDistrict = query.data.getVillages.find(village => village.name === defaultValues.subDistrict);
      if (subDistrict) {
        changeSubDistrict(subDistrict);
      }
    });
  }, [address?.district?.id]);

  useEffect(()=>{
    if (!address?.subDistrict?.id) {
      return;
    }
    getPostalCodes({
      variables: {
        villageIds: [address.subDistrict.id],
      }
    }).then(query => {
      if (query.error) {
        toast.error('Terjadi kesalahan');
      }

      if (!defaultValues.postalCode) {
        return;
      }

      const postalCode = query.data.getPostalCodes.find(postalCode => postalCode.postalCode === Number(defaultValues.postalCode));
      if (postalCode) {
        changePostalCode(postalCode);
      }
    });
  }, [address?.subDistrict?.id]);

  const changeProvince = (province: Province) => {
    setAddress((prev) => {
      return {
        ...prev,
        province,
        city: null,
        district: null,
        subDistrict: null,
        postalCode: null,
      }
    });
  }

  const changeCity = (city: Regency) => {
    setAddress((prev) => {
      return {
        ...prev,
        city,
        district: null,
        subDistrict: null,
        postalCode: null,
      }
    });
  }
  
  const changeDistrict = (district: District) => {
    setAddress((prev) => {
      return {
        ...prev,
        district,
        subDistrict: null,
        postalCode: null,
      }
    });
  }

  const changeSubDistrict = (subDistrict: Village) => {
    setAddress((prev) => {
      return {
        ...prev,
        subDistrict,
        postalCode: null,
      }
    });
  }

  const values: Record<AddressType, string> = {
    province: address?.province?.name || '',
    city: address?.city?.name || '',
    district: address?.district?.name || '',
    subDistrict: address?.subDistrict?.name  || '',
    postalCode: address?.postalCode?.postalCode.toString() || '',
  }

  const reset = () => {
    setAddress(defaultValue);
  }

  const changePostalCode = (postalCode: PostalCode) => {
    setAddress((prev) => {
      return {
        ...prev,
        postalCode,
      }
    });
  }

  return (
    <AddressInputContext.Provider
      value={{
        ...props,
        address,
        data: {
          provinces: provinces?.getProvinces || [],
          cities: regencies?.getRegencies || [],
          districts: districts?.getDistricts || [],
          subDistricts: subDistricts?.getVillages || [],
          postalCodes: postalCodes?.getPostalCodes || [],
        },
        changeCity,
        changeDistrict,
        changePostalCode,
        changeProvince,
        changeSubDistrict,
      }}
    >
      {children({
        values,
        inputs: {
          province: ProvinceInput,
          city: RegencyInput,
          district: DisctrictInput,
          subDistrict: SubDisctrictInput,
          postalCode: PostalCodeInput,
        },
        reset,
      })}
    </AddressInputContext.Provider>
  )
}
