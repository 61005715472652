import React, { useEffect, useState } from 'react'
import { SelectItem, SelectRadioProps } from './types'
import { RadioInput } from 'components/RadioInput';
import { useSelectRadioGroupContext } from './SelectRadioGroup';

export const SelectRadio = (props: SelectRadioProps) => {
  const {items, value, label, onChange } = props;
  const [open, setOpen] = useState(false);
  const {
    activeId, 
    getUniqueId,
    setActiveId,
    registerSelectedItem,
    removeSelectedItem, 
    items: parentItems,
  } = useSelectRadioGroupContext();
  const [uniqueId, setUniqueId] = useState<string>();
  const options = (parentItems || items) as SelectItem[];

  const defaultSelected = options.find(opt => opt.value === value);
  const [selected, setSelected] = useState<SelectItem>(defaultSelected);

  useEffect(()=>{
    if(!getUniqueId) {
      return;
    }
    setUniqueId(getUniqueId());
  },[getUniqueId]);

  useEffect(() => {
    if (activeId !== uniqueId && open) {
      setOpen(false);
    }
  }, [activeId])

  const onCancel = () => {
    removeSelectedItem && removeSelectedItem(selected);
    setSelected(undefined);
    onChange && onChange(undefined);
  }

  const toggleSelection = () => {
    setActiveId(open ? '': uniqueId);
    setOpen(prev => !prev);
  }

  const handleChange = (item: SelectItem) => {
    registerSelectedItem && registerSelectedItem(item, {...selected});
    setSelected(item);
    setOpen(false);
    onChange && onChange(item);
  }

  return (
    <div 
      className='flex relative'
    >
      <div 
        className={`rounded-lg border-red-400 border px-2 py-1.5 font-bold text-red-400 text-sm ${selected? 'bg-red-400 text-white': ''}`}
        onClick={toggleSelection}
      >
        <RadioInput
          label={selected?.label || label}
          checked={!!selected}
          revert={!!selected}
        />
      </div>

      {open && (
        <div 
          className='z-[2] shadow-base overflow-auto flex flex-col px-3 shadow-1 rounded-lg 
          absolute mt-5 top-full right-0 bg-white min-w-48 text-[black] font-medium'
        >
          {options.map(opt => {
            return (
              <div 
                className='border-0 border-t border-dashed border py-3 first:border-t-0'
                key={opt.value}
              >
                <RadioInput
                  label={opt.label}
                  checked={selected?.value === opt.value}
                  onChange={()=>handleChange(opt)}
                  disabled={!!opt.disabled && opt.value !== selected?.value}
                />
              </div>
            )
          })}

          {!options.length && (
            <span className='py-3'>Tidak ada pilihan</span>
          )}

          {selected && (
            <div 
              className='text-red-400 font-bold whitespace-nowrap border-0 border-t border-dashed py-3'
              onClick={onCancel}
            >
              <span>Batalkan Pilihan</span>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
