import React, { ReactNode } from 'react';
import './Layout.scss';

interface LoginLayoutProps {
  children?: ReactNode;
}

export const LoginLayout = (props: LoginLayoutProps) => {
  const { children } = props;

  return (
    <div className='login-layout'>
      {children}
    </div>
  );
};
