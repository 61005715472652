import React from 'react';
import { Button } from '@lifepal/lifepal-design-system';
import { Modal } from 'components/Modal';
import { useReviewDetailContext } from 'containers/review/Detail';
import { success } from 'utils/assets';

export const ModalSuccess = () => {
  const { open, setOpen, } = useReviewDetailContext();

  const handleClose = () => {
    setOpen(undefined);
  };

  return (
    <Modal
      open={open === 'success'}
      onCloseModal={handleClose}
      title=''
      className={{ header: 'border-none p-none',content:'modal-success'}}
    >
        <div className='flex align-center justify-center'>
          <img alt='task-img' src={success}/>
        </div>
        <div className='divide-y divide-dashed divide-y-2'>
          <span className='flex align-center justify-center my-5 text-center font-bold'>
            Sukses menyalin deklarasi!
          </span>
          <div className='justify-center my-5'>
            <Button 
              onClick={handleClose} 
              className={`text-white p-2 mt-5 bg-blue-600 w-full`}
            >OK</Button>
          </div>
        </div>
    </Modal>
  );
};
