import React, { useEffect, useState } from 'react';

import { format } from 'date-fns';
import { id } from 'date-fns/locale';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { get } from 'lodash';
import { toast } from 'react-toastify';

import { useAuthContext } from 'providers/AuthProvider';
import { Button } from '@lifepal/lifepal-design-system';
import { arrow, comment } from 'utils/assets';
import classNames from 'classnames';

import './Comment.scss';
import { useReviewDetailContext } from 'containers/review/Detail';
import { QuoteReasonTypes } from 'types/quotation';

interface CommentProps {
  orderId: string;
  quotationStatus: string | undefined;
}

interface CommentItemProps {
  actionBy: string;
  comment: string;
  createdAt: string;
}

export const Comment = (props: CommentProps) => {
  const { user } = useAuthContext();
  const { orderId, quotationStatus } = props;
  const [chatOpen, setChatOpen] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [showComments, setShowComments] = useState<boolean>(false);
  const [commentInput, setCommentInput] = useState<string>('');
  const [commentsGraph, setComments] = useState([]);
  const {quotationReasons = [], suspendReason } = useReviewDetailContext();

  const limitedComments = commentsGraph.length > 0 && [...commentsGraph]
    .sort((a: CommentItemProps, b: CommentItemProps) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
    .slice(0, 5) || [];


  const GET_COMMENTS_QUERY = gql`
    query GetOrderComments(
      $orderId: String!, 
      $page: Int!, 
      $limit: Int!
    ) {
      getListComment(input: {
        orderId: $orderId,
        page: $page,
        limit: $limit
      }) {
        totalItems
        itemCount
        pageCount
        listComment {
          status
          actionBy
          comment
          createdAt
        }
      }
    }`;

  const POST_COMMENT_MUTATION = gql`
    mutation PostComment(
      $orderId: String!,
      $status: String!,
      $subStatus: String!,
      $actionBy: String!,
      $comment: String!
    ) {
      postComment(input: {
        orderId: $orderId
        status: $status
        subStatus: $subStatus
        actionBy: $actionBy
        comment: $comment
      }) {
        success
      }
    }`;

  const [handleGetComments] = useLazyQuery(GET_COMMENTS_QUERY);
  const [handlePostComment] = useMutation(POST_COMMENT_MUTATION);

  const fetchComments = async (orderId: string) => {
    try {
      const { data, error } = await handleGetComments({
        variables: {
          orderId: orderId,
          page: 1,
          limit: 50
        },
        fetchPolicy: 'network-only',
      });

      if (error) throw error;
      setComments(get(data, 'getListComment.listComment', []));
    } catch (error) {
      setComments([]);
      return;
    }
  };

  const postComment = async () => {
    if (!orderId) {
      toast.error('Gagal menemukan Order dari Quotation ini.');
      return;
    }

    const isTuguQA = user.groups && user.groups.includes('qa-tugu');

    try {
      const { errors } = await handlePostComment({
        variables: {
          orderId: orderId,
          status: quotationStatus || '',
          subStatus: '',
          actionBy: `${isTuguQA ? 'Tugu' : 'QA'}_${user.full_name || ''}`,
          comment: commentInput
        }
      });

      if (errors) throw errors;
      setCommentInput('');
      fetchComments(orderId);
      toast.success('Berhasil menambahkan komentar.');
    } catch (error) {
      toast.error('Gagal menambahkan komentar.');
      return;
    }
  };

  useEffect (() => {
    if (orderId) {
      fetchComments(orderId);
    }
  }, [orderId]);

  const defineActiveTab = (tab: number) => {
    setActiveTab(tab);
    if (tab == 0) {
      document.getElementById('scroll-menu').scrollBy(-70, 0);
    }

    if (tab == 1) {
      document.getElementById('scroll-menu').scrollBy(-40, 0);
    }

    if (tab == 2) {
      document.getElementById('scroll-menu').scrollBy(70, 0);
    }
  }

  const showCommentsAction = () => {
    setShowComments(true);
  }

  const chatOpenAction = () => {
    setChatOpen(!chatOpen);
    setShowComments(chatOpen);
  }
  
  return (
    <div className='comment--wrapper'>
      <div 
        className='comment--wrapper__button'
        onClick={() => chatOpenAction()}
      >
        {
          chatOpen ? 
            <img alt='close' src={arrow}/> :
            <img alt='open' src={comment}/>
        }
      </div>
      {
        chatOpen && (
          <div className='comment--wrapper__vertical--chat px-4 py-6'>
            <div className={classNames(activeTab == 0 ? 'h-48' : 'h-16', 'bg__blue__top')}></div>
            <div className='flex justify-between'>
              <div className='comment__menu-wrapper'>
                <div id='scroll-menu' className='comment__menu-items'>
                  <div id='comment-tab' className={classNames('comment__menu-item flex', activeTab == 0 ? 'active' : 'inactive')} onClick={() => defineActiveTab(0)}>
                    <img src='/assets/icon/comment-1.png' alt='comment-icon'/>
                    <span className='comment__menu-item-text'>Comment Log</span>
                  </div>
                  <div id='reason-tab' className={classNames('comment__menu-item flex', activeTab == 1 ? 'active' : 'inactive')} onClick={() => defineActiveTab(1)}>
                    <img src='/assets/icon/suspend-reason.png' alt='suspend-reason-icon'/>
                    <span className='comment__menu-item-text'>Suspend Reasons</span>
                  </div>
                  <div id='back-order-tab' className={classNames('comment__menu-item flex', activeTab == 2 ? 'active' : 'inactive')} onClick={() => defineActiveTab(2)}>
                    <img src='/assets/icon/suspend-back-order.png' alt='suspend-back-order-icon'/>
                    <span className='comment__menu-item-text'>Suspend Back Order API</span>
                  </div>
                </div>
              </div>
            </div>
            
            { activeTab == 0 &&
              <div className='p-4 mt-4 bg-white rounded drop-shadow-lg'>
                <textarea 
                  className='block p-2.5 mb-2 w-full text-size text-gray-900 rounded-lg border border-gray-300 resizeNone h-32'
                  name="text-area-comment"
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setCommentInput(e.target.value)}
                  value={commentInput}
                  maxLength={300}
                  placeholder=''
                />
                {commentInput && commentInput.length < 30 && (
                  <p className='text-xs text-rose-700 text-right'>*Komentar minimal 30 karakter</p>
                )}
                {commentInput && commentInput.length >= 300 && (
                  <p className='text-xs text-rose-700 text-right'>*Komentar maksimal 300 karakter</p>
                )}
                <Button 
                  className='mt-2 blue w-full p-horz-half-rem p-vert-half-rem rounded'
                  disabled={commentInput.length < 30}
                  onClick={postComment}
                >
                  <span className='text-white text-size'>+ Tambahkan Komen</span>
                </Button>
              </div>
            }

            { !showComments && limitedComments.length > 1 &&
              <div className='bg__blue__bottom'>
                <div className='text-center mt-1'>
                  <span className='text-white text-size cursor-pointer' onClick={() => showCommentsAction()}>Lihat Selengkapnya</span>
                </div>
              </div>
            }
            
            { activeTab == 0 && limitedComments.length > 0 &&
              <div id='list-comments' className='comment__list'>
                {
                  limitedComments
                  .map((Comment: CommentItemProps, index) => {
                    const { actionBy, comment, createdAt } = Comment;
                    const commentByUser = actionBy.includes(user.full_name);
                    return (
                      <div key={index} className={`px-4 py-2 mt-4 flex flex-col rounded-xl drop-shadow-sm bg-blue-100 ${commentByUser && 'ml-6'}`} >
                        <span className='text-size text-zinc-800 my-1.5 font-medium'>{ actionBy }</span>
                        <span className='text-size text-zinc-800 my-1'>{ comment }</span>
                        <span className='text-size text-right text-zinc-400 mb-1.5'>
                          {createdAt && format(new Date(createdAt), 'dd MMMM, yyyy hh:mm aa', { locale: id })}
                        </span> 
                      </div>
                    )
                  })
                }
              </div>
            }

            { activeTab == 1 && quotationReasons.length > 0 &&
              <div id='list-suspend-reasons' className='comment__list-full mt-8'>
                { quotationReasons
                  .map((reason: QuoteReasonTypes, index) => {
                    return (
                      <div key={index} className={`px-4 py-2 mt-4 flex flex-col rounded-xl drop-shadow-sm bg-blue-100`} >
                        <span className='text-size text-zinc-800 my-1'>{ reason.message }</span>
                        <span className='text-size text-right text-zinc-400 mb-1.5'>
                          {reason.createdAt && format(new Date(reason.createdAt), 'dd MMMM, yyyy hh:mm aa', { locale: id })}
                        </span> 
                      </div>
                    )
                  })
                }
              </div>
            }

            { activeTab == 2 && suspendReason &&
              <div id='list-suspend-back-reasons' className='comment__list-full mt-8'>
                <div className='px-4 py-2 mt-4 flex flex-col rounded-xl drop-shadow-sm bg-blue-100'>
                  <span className='text-size text-zinc-800 my-1'>{ suspendReason }</span>
                </div>
              </div>
            }
          </div>
        )
      }
    </div>
  )
}
