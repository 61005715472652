/* 
  currently only  for Zurich Syariah and Zurich Asuransi Indonesia
*/
export const getOtherDeclarationQuestions = () => {
  return [
    {
      created_at : "2023-12-06T00:00:00",
      error_message : "",
      field_name : "renewal_document",
      field_type : "file",
      id : '0',
      is_active : true,
      is_required : true,
      multiple_question : {},
      name : "Dokumen Renewal",
      ordering : 1,
      placeholder : "",
      product_id : 254,
      uploaded_file : "",
    }
  ];
}
