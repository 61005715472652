import { gql } from "@apollo/client";
import * as api from '../../utils/apolloClient';
import { QuotesList, QuotesListParams } from "./quotesList.types";

const QUOTES_LIST_OF_QUOTES_QUERY = gql`
  query Quotes(
    $status: QuotesStatus,
    $stage: [QuotesStage!],
    $orderStatus: [OrderStatus!],
    $fromDate: String,
    $toDate: String,
    $searchByBuyer: String,
    $humanId: String,
    $isRenewal: Boolean
    $sortBy: String,
  	$sort: String,
    $qaAgent: QaAgent,
  ) {
    quotes(listOfQuotes: {
      status: $status,
      stage: $stage,
      orderStatus: $orderStatus,
      fromDate: $fromDate,
      toDate: $toDate,
      searchByBuyer: $searchByBuyer,
      humanId: $humanId,
      isRenewal: $isRenewal,
      sort: $sort,
      sortBy: $sortBy,
      qaAgent: $qaAgent,
    })
    {
      quotes {
        id
        status
        organization {
          name
        }
        category
        orderCreatedAt
        humanId
        quotedAmount
        buyer {
          fullName
          phoneNumber
          email
        }
        soldBy {
          firstName
          lastName
        }
        orderPolicyFile
        orderStatus
        spvName
        updatedAt
        createdAt
        qaAgent {
          id
          firstName
          lastName
          email
          phoneNumber
        }
      }
    }
  }
`;

export const getQuotesList = (params: QuotesListParams) => {
  return api.query<{ quotes: QuotesList }, QuotesListParams>(QUOTES_LIST_OF_QUOTES_QUERY, params, {
    fetchPolicy: 'network-only',
  });
}