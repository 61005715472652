import get from 'lodash/get';
import { differenceInYears } from 'date-fns';
import { PolicyMembersTypes } from "containers/review/types";

export const getPolicyMembers = (members: PolicyMembersTypes[], duration: string) => {
  if (!members) {
    return [{
      age: '21',
      gender: 'MALE',
      is_children: 'false',
      ...duration ? { duration } : {},
    }];
  }

  const memberList = members.map((member: PolicyMembersTypes) => {
    const dob = get(member, 'person.dob');
    const relation = get(member, 'relation', get(member, 'person.relation'));

    const memberObj = {
      age: dob
        ? differenceInYears(new Date(), new Date(dob)).toString()
        : get(member, 'person.age', '').toString(),
      gender: get(member, 'person.gender', '').toString(),
      is_children: JSON.stringify(relation === 'DP - Anak'),
      ...duration ? { duration } : {},
      ...dob ? { dob } : {},
    };

    return memberObj;
  });

  return memberList.sort((a, b) => Number(b.age) - Number(a.age));
};
