import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { useAddressInputContext } from './AddressInput';
import type { Regency } from '../../services/address';

interface RegencyOption {
  label: string;
  value: Regency
}

export const RegencyInput = () => {
  const { data, defaultValues, changeCity } = useAddressInputContext();
  const [options, setOptions] = useState<RegencyOption[]>([]);
  const [value, setValue] = useState<RegencyOption>();

  useEffect(()=>{
    const opts = data.cities.map(reg => {
      return {
        value: reg,
        label: reg.name
      }
    });

    setOptions(opts);
    const defaultValue = opts.find(city => city.label === defaultValues.city);
    setValue(defaultValue);
  }, [data.cities, defaultValues.city]);

  const onChange = (data: RegencyOption) => {
    setValue(data);
    changeCity(data.value);
  }

  return (
    <Select<RegencyOption>
      placeholder='- Pilih Kota'
      options={options}
      value={value}
      onChange={onChange}
      isSearchable
    />
  )
}