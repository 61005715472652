import React from 'react';
import './Search.scss';

interface SearchProps {
  placeholder?: string;
  onChange?: (thisValue: string) => void;
  onClick?: () => void;
}

export const SearchInput = (props: SearchProps) => {
  const { placeholder, onChange, onClick } = props;

  return (
    <div className='search-input'>
      <i onClick={() => { onClick && onClick() }} className='icon ion-ios-search p-horz-1-rem pointer' />
      <input
        className='p-vert-1'
        type='text'
        placeholder={placeholder}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { onChange && onChange(e.target.value) }}
      />
    </div>
  );
};
