import React from 'react';
import { Button } from '@lifepal/lifepal-design-system';
import { Modal } from 'components';
import { useReviewDetailContext } from 'containers/review/Detail';
import { SuspendReasonUpdateInputTypes } from 'types/quotation';
import { DeclarationTypes } from '../types';
import { SelectedSuspendReasonType } from 'types/review';

interface ModalSuspendProps {
  declaration: DeclarationTypes;
}

export const ModalSuspend = (props: ModalSuspendProps) => {
  const {
    open,
    setOpen,
    updateQuotationStatus,
    isSubmitting,
    selectedSuspendReasons,
  } = useReviewDetailContext();
  const {
    declaration
  } = props;

  const summarySelectedSuspendReason: Record<string, Record<string, string[]>> = {};

  selectedSuspendReasons.forEach((item: SelectedSuspendReasonType) => {
    const { section, suspendReasonGroup, message } = item;

    if (!summarySelectedSuspendReason[section]) {
      summarySelectedSuspendReason[section] = {};
    }
    if (!summarySelectedSuspendReason[section][suspendReasonGroup]) {
      summarySelectedSuspendReason[section][suspendReasonGroup] = [];
    }
    summarySelectedSuspendReason[section][suspendReasonGroup].push(message);
  });

  const handleClose = () => {
    setOpen(undefined);
  };

  const handleSuspend = async () => {
    const payloads: SuspendReasonUpdateInputTypes[] = selectedSuspendReasons.map((key: SelectedSuspendReasonType) => {

      return {
        suspendReasonId: key.id,
        value: key.value,
      };
    });

    updateQuotationStatus({
      statusPayload: 'SUSPEND',
      selectedSuspendReasons: payloads
    });
  };

  return (
    <Modal
      open={open === 'suspend'}
      onCloseModal={handleClose}
      title='Suspend Order Ini?'
      className={{ header: 'border-none p-none' }}
    >
      <div className='modal-content-form overflow pb-1-rem gap-5'>
        {Object.keys(summarySelectedSuspendReason).map((section) => {
          const sectionReasons = summarySelectedSuspendReason[section];

          return (
            <div key={section} className='flex flex-col gap-2'>
              <h1 className='font-bold'>{section}</h1>
              {Object.keys(sectionReasons).map((reasonGroup) => {

                return (
                  <div key={reasonGroup}>
                    <h1 className='font-semibold'>{reasonGroup}</h1>
                    {sectionReasons[reasonGroup].map((message) => (
                      <p className='text-red-400' key={message}>{message}</p>
                    ))}
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
      <div className='modal-button-action mt-5'>
        <Button disabled={isSubmitting} onClick={handleSuspend} className={`!w-full !m-0 text-white p-2 m-2 ${isSubmitting ? 'bg-blue-300' : 'bg-brand-primary-color pointer'}`}>
          Suspend
        </Button>
      </div>
    </Modal >
  );
};
