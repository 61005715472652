// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-pos {
  border-collapse: collapse;
  font-size: 14px;
  width: 100%;
  table-layout: fixed;
  overflow: auto;
}
.table-pos th,
.table-pos td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.table-pos th:hover,
.table-pos td:hover {
  white-space: normal;
  word-wrap: break-word;
  text-overflow: inherit;
}
.table-pos th.loading-shimmer,
.table-pos td.loading-shimmer {
  animation: shimmer 2s infinite linear;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 75%;
}
@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
.table-pos th {
  border-bottom: 2px solid #1d66dd;
  font-weight: 600;
  color: #333;
}
.table-pos tbody tr.border-cell-bottom {
  border-bottom: 1px solid #dbdbdb;
}
.table-pos tbody tr.border-cell-bottom.background-hover {
  background: rgb(247, 250, 255);
}
.table-pos tbody tr.border-cell-bottom:hover {
  background: rgb(247, 250, 255);
}`, "",{"version":3,"sources":["webpack://./src/components/Table/Table.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,eAAA;EACA,WAAA;EACA,mBAAA;EACA,cAAA;AACF;AACE;;EAEE,gBAAA;EACA,uBAAA;EACA,mBAAA;AACJ;AACI;;EACE,mBAAA;EACA,qBAAA;EACA,sBAAA;AAEN;AACI;;EACE,qCAAA;EACA,2EAAA;EACA,2BAAA;AAEN;AACI;EACE;IACI,8BAAA;EACR;EACI;IACI,6BAAA;EACR;AACF;AAGE;EACE,gCAAA;EACA,gBAAA;EACA,WAAA;AADJ;AAMM;EACE,gCAAA;AAJR;AAMQ;EACE,8BAAA;AAJV;AAOQ;EACE,8BAAA;AALV","sourcesContent":[".table-pos {\n  border-collapse: collapse;\n  font-size: 14px;\n  width: 100%;\n  table-layout: fixed;\n  overflow: auto;\n\n  th,\n  td {\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n\n    &:hover {\n      white-space: normal;\n      word-wrap: break-word;\n      text-overflow: inherit;\n    }\n\n    &.loading-shimmer {\n      animation: shimmer 2s infinite linear;\n      background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);\n      background-size: 1000px 75%;\n    }\n\n    @keyframes shimmer {\n      0% {\n          background-position: -1000px 0;\n      }\n      100% {\n          background-position: 1000px 0;\n      }\n  }\n  }\n\n  th {\n    border-bottom: 2px solid #1d66dd;\n    font-weight: 600;\n    color: #333;\n  }\n\n  tbody {\n    tr {\n      &.border-cell-bottom {\n        border-bottom: 1px solid #dbdbdb;\n\n        &.background-hover {\n          background: rgb(247, 250, 255);\n        }\n\n        &:hover {\n          background: rgb(247, 250, 255);\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
