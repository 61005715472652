import React, { createContext, useContext } from 'react'
import { QuotesListParams } from 'services/quotations/quotesList.types'

interface ReviewContextProps {
  changeParams: (params: Partial<QuotesListParams>) => void;
  params: QuotesListParams,
}

export const ReviewContext = createContext({} as ReviewContextProps);
export const useReviewContext = () => useContext(ReviewContext);
